import React from 'react';
import { useEffect ,useState,useMemo} from "react";
import { useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import '../../assets/css/new_style.css';
import {communityData} from './communityData'

import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Button,
    Col,Modal,ModalBody,ModalHeader,ModalFooter
  } from "reactstrap";

  import { ToastContainer } from 'react-toastify';
  import { useTable,useGlobalFilter,useFilters,usePagination } from "react-table";
import { Link } from 'react-router-dom';
import CommunityHeader from './CommunityHeader';
import {getAllCommunity, approveCommunity, deleteCommunity} from '../../redux/actions/communityActions'


const Community = () => {
  const dispatch = useDispatch();
  const community = useSelector(state=>state.community.detail);
  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const addPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/community")
  const approvePerm = permissionPath.find(perm=>perm.method=="PATCH"&&perm.path=="/api/v2.0/community/:communityId/approve")
  const viewPerm = permissionPath.find(perm=>perm.method=="GET"&&perm.path=="/api/v2.0/community/:communityId")
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/community/:communityId")


  const filterTypes = useMemo(
    () => ({
        dateBetween: dateBetweenFilterFn,
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id];
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true;
            });
        }
    }),
    []
  );

  const COLUMNS=[
    {
        Header:'Sr. No',
        accessor:'',
        Cell:(props)=>(
          <>
          {props.row.index+1}
          </>
        )
    },
    {
        Header:'Community Name',
        accessor:'title'
    },
    {
        Header:'Community Member',
        accessor:"member.length",
    },
    {
        Header:'Created on',
        accessor:"createdAt",
        filter:'dateBetween',
        Cell: (props)=> {return moment(props.row.original.createdAt).format("MMM Do YY")}
    },
      {
          Header:'Community Request',
          accessor:'approved',
          Cell: (props)=> (
            <>
            {
              adminRole=="superAdmin"?
              props.row.original.approved?(
                <Button size='sm' color="danger" 
                onClick={()=>approveComm(props.row.original._id)}
                 >Unapprove</Button>
            ):
            (
              <Button color="success" size='sm' 
              onClick={()=>approveComm(props.row.original._id)}
                >Approve</Button>
          )
            :approvePerm?props.row.original.approved?(
              <Button size='sm' color="danger" 
              onClick={()=>approveComm(props.row.original._id)}
               >Unapprove</Button>
          ):
          (
            <Button color="success" size='sm' 
            onClick={()=>approveComm(props.row.original._id)}
              >Approve</Button>
        )
          :null
            }

            {/* {props.row.original.approved?(
                            <Button size='sm' color="danger" 
                            onClick={()=>approveComm(props.row.original._id)}
                             >Unapprove</Button>
                        ):
                        (
                          <Button color="success" size='sm' 
                          onClick={()=>approveComm(props.row.original._id)}
                            >Approve</Button>
                      )
                        } */}
                  <span className="ml-3" >{props.row.original.approved?"Approved":"Unapproved"}</span>
            </>
          )
      },
    // {
    //   Header:"Approval Status",
    //   accessor:'approved',
    //   Cell: ({row})=> {return row.original.approved?"Approved":"Approval Pending"}
    // },
            {
                Header:'Actions',
                Cell: (props)=>(
                  <div >
                    {
                      adminRole=="superAdmin"?
                    <Link
                      to={{pathname:`/admin/community/${props.row.original._id}`
                      }} 
                        type="button" className="btn text-purple ">
                          <i className="fa fa-eye"></i>
                      </Link>:viewPerm?<Link
                      to={{pathname:`/admin/community/${props.row.original._id}`
                      }} 
                        type="button" className="btn text-purple ">
                          <i className="fa fa-eye"></i>
                      </Link>:null
                    }
                      {
                        adminRole=="superAdmin"?
                      <div type="button"
                        className="btn text-red"
                        onClick={()=>deletePopup(props.row.original._id)}
                        >
                        <i className="fa fa-trash"></i>
                      </div>:deletePerm?<div type="button"
                        className="btn text-red"
                        onClick={()=>deletePopup(props.row.original._id)}
                        >
                        <i className="fa fa-trash"></i>
                      </div>:null
                      }
                  </div>
                )

            },
]

const columns = useMemo(()=> COLUMNS,[]);
    const data = useMemo(()=>community,[community]);
    const tableInstance = useTable({
      columns: columns,
      data: data,
      filterTypes,
      
    },useFilters,useGlobalFilter,usePagination)

  const{getTableProps,getTableBodyProps,
    headerGroups,rows,page,prepareRow,state,setGlobalFilter,setFilter,
    nextPage,previousPage,canPreviousPage,canNextPage
  } = tableInstance;

  const {globalFilter,filters,pageIndex} = state


  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

dateBetweenFilterFn.autoRemove = val => !val;


  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }

  const approveComm=(id)=>{
    dispatch(approveCommunity(id));
  }

  const handleDelete=()=>{
    dispatch(deleteCommunity(confirmId));
    cancelDelete();
  }


  
  useEffect(()=>{

    dispatch(getAllCommunity());

  },[])
    return (
        <>
            <ToastContainer />
            <CommunityHeader setFilter={setFilter} filter={filters} />
            <AdminNavbar filter={globalFilter} setFilter={setGlobalFilter} brandText="Community management" />
            
            <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg={9} >
                        <h3 className="mb-0 test-css comunity_manage">Community Management</h3>
                    </Col>
                    <Col lg="3" >
                    {
                      adminRole=="superAdmin"?
                      <Link to="/admin/add-community" >
                        <Button color="info" className='adcomuniti'>Add Community</Button>
                      </Link>:addPerm?<Link to="/admin/add-community" >
                        <Button color="info" className='adcomuniti'>Add Community</Button>
                      </Link>:null
                    }
                    </Col>
                  </Row>
                </CardHeader>

                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Community Name</th>
                      <th scope="col">Community Member</th>
                      <th scope="col">Created on</th>
                      <th scope="col">Community Request</th>
                      <th scope="col">Approval Status</th>
                      <th scope="col" className="text-center" >Action</th>
                    </tr>
                  </thead>
                  {!community?"Loading":
                  <tbody>
                    {community.map((elem)=>(
                    <tr key={elem._id}>
                      <td>{elem.title}</td>
                      <td>
                        {elem.member.length}
                      </td>

                      <td>
                      {moment(elem.createdAt).format("MMM Do YY")}
                      </td>
                      <td>
                      {elem.approved?(
                          <Button size='sm' color="danger" onClick={()=>approveComm(elem._id)} >Unapprove</Button>
                      ):
                      (
                        <Button color="success" size='sm' onClick={()=>approveComm(elem._id)}  >Approve</Button>
                    )
                      }
                      </td>

                      <td>
                      {elem.approved?"Approved":"Approval Pending"}
                      </td>
                      <td className="d-flex justify-content-center"> 
                        <Link
                        to={{pathname:`/admin/community/${elem._id}`,
                        communityId:elem._id
                        }} 
                          type="button" className="btn text-purple ">
                            <i className="fa fa-eye"></i>
                        </Link>
                        
                        <div type="button"
                          className="btn text-red"
                          onClick={()=>deletePopup(elem._id)}
                          >
                          <i className="fa fa-trash"></i>
                        </div>
                    </td> 
                    </tr>

                    ))}
                  </tbody>
                }
                </Table> */}

                <Modal isOpen={confirm} >
                    <ModalHeader >Confirm delete community ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this community ?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Table {...getTableProps()} className="align-items-center table-flush" responsive >
                  <thead className="thead-light text-center">
                    {headerGroups.map((headerGroup)=> (
                      <tr {...headerGroup.getHeaderGroupProps()} >
                        {
                          headerGroup.headers.map(column=> (
                            <th {...column.getHeaderProps(column.getSortByToggleProps)} scope="col"  >
                              {column.render('Header')} 
                              {column.Filter ? column.render('Filter') : null}
                              <span>
                                {column.isSorted?(column.isSortedDesc?'🔽':'🔼'):null }
                              </span>     
                            </th>
                          ))
                        }
                      </tr>
                    ) )}
                  </thead>
                  {rows.length!==0?
                  <tbody {...getTableBodyProps()} >
                    {
                      page.map(row=>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <td {...cell.getCellProps()} scope="row" className="text-center" >
                                   {cell.render('Cell')}
                                                                                  
                                  </td>
                                )
                              } )}                     
                            </tr>
                        )
                      })
                    }
                  </tbody>
                  :<h2 className="text-center" >No Data Available</h2>}
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                  <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={canPreviousPage?"active":''}>
                        <PaginationLink
                          onClick={() => previousPage()}
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {pageIndex+1}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
                      <PaginationItem className={canNextPage?"active":''}>
                        <PaginationLink
                          onClick={() => nextPage()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          
        </Container>


        </>
    )
}

export default Community
