
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-center">
        <Col >
          <div className="copyright text-center text-copirits">
          Copyright
            © {new Date().getFullYear()}{" "}
            Toby All rights reserved.
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
