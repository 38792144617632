import React,{useState,useEffect} from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link,useHistory } from 'react-router-dom';
import {addMood} from '../../redux/api';
import { Form } from 'reactstrap';

// <AdminNavbar brandText="Add Role" />
const Addmood = () => {

  const history = useHistory();
  const [mood, setMood] = useState({
    name:"",
    icon:""
  })

  const handleSubmit = async(e)=>{
    e.preventDefault();
    try {
     
      if((!mood.name)||(!mood.icon)){
        throw new Error('Please add mood details')
      }
      const formData = new FormData();
      formData.append('name',mood.name);
      formData.append('icon',mood.icon)
      await addMood(formData);
      history.push('/admin/mood-management')
    } catch (error) {
     console.log("mood add error"); 
    }
  }

  return (
    <>
      <div className='second_header12' >
    <AdminNavbar  brandText="Add Mood" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="container">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>Add Mood</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 

<Form onSubmit={handleSubmit} >
                         <div className='try_whole'>
                           <div className="row try_whole">


                                    <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                        <label for="">Emoji Text</label>
                                        <input type='text' value={mood.name}
                                         className='form-control' placeholder='Enter Emoji Name'
                                         required
                                         onChange={(e)=>{
                                          setMood({
                                            ...mood,
                                            name:e.target.value
                                          })
                                         }}
                                           ></input>
                                      </div>
                                    </div>                             
                                   <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Emoji</label>
                                          <input type='file' className='form-control'
                                          required
                                          onChange={(e)=>{
                                            setMood({
                                              ...mood,
                                              icon:e.target.files[0]
                                            })
                                          }}
                                            id='' ></input>
                                      </div>
                                  </div>          
                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/mood-management" type="button" class="btn btn-info">Back</Link>
                                        <button type="submit" class="btn difrnt" 
                                        // onClick={()=>handleSubmit()}
                                         >Submit</button>
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
            </Form>
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default Addmood