import React from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link } from 'react-router-dom';
//   <AdminNavbar brandText="Add Role" />
const Editrole = () => {
  return (
    <>
 <div className='second_header12' >
    <AdminNavbar  brandText="Edit Role" />

    </div>
    <div className="mod_box moodmange">  
        <div className="">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>Edit Role</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 

                         <div className='try_whole'>
                           <div className="row try_whole">


                                    <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                        <label for="">Role Name</label>
                                        <input type='text' className='form-control' Value='Role Name 1' id='' ></input>
                                      </div>
                                    </div>                             
                                   <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Assign To</label>
                                          <select name="" className='form-control' id="">
                                            <option value="">Ram</option>
                                            <option value="">Shyam</option>
                                          </select>
                                          {/* <input type='text' className='form-control' placeholder='Enter Name' id='' ></input> */}
                                      </div>
                                  </div>   
                                  <div className='col-md-12 labl_infore'>
                              <div className="first_frm">
                                <label for="">Assign Permission</label>
                                {/* <input type='text' className='form-control' placeholder='' value="Super Admin" id='text' disabled></input> */}
                                </div>
                            </div> 
                            <div className='col-md-12 labl_infore'>
                                    <div className=''>
                                        <div className="lbl_boxcingbor seprt_boc ">                        
                                        <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email">Capsule</label>
                                        </div>
                                    </div>
                                    <div className='row'>                         
                                        <div className='inbuilt leftsift'>
                                            <div className="lbl_boxcingin">                        
                                            <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email" className='sometxt'>Add Capsule</label>
                                            </div>
                                        </div>                       
                                        <div className='inbuilt'>
                                            <div className="lbl_boxcingin">                        
                                            <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email" className='sometxt'>Edit Capsule</label>
                                            </div>
                                        </div>
                                     
                                    </div>
                            </div>   

                             <div className='col-md-12 labl_infore'>
                                    <div className='mt-2'>
                                        <div className="lbl_boxcingbor seprt_boc ">                        
                                        <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email">Content</label>
                                        </div>
                                    </div>
                                    <div className='row'>                         
                                        <div className='inbuilt leftsift'>
                                            <div className="lbl_boxcingin">                        
                                            <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email" className='sometxt'>Add Content</label>
                                            </div>
                                        </div>                         
                                        <div className='inbuilt '>
                                            <div className="lbl_boxcingin">                        
                                            <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email" className='sometxt'>Edit Content</label>
                                            </div>
                                        </div>                       
                                        <div className='inbuilt'>
                                            <div className="lbl_boxcingin">                        
                                            <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email" className='sometxt'>Delete Content</label>
                                            </div>
                                        </div>                       
                                        <div className='inbuilt leftsift'>
                                            <div className="lbl_boxcingin">                        
                                            <input type='checkbox' id='vehicle1' name='vehicle1' checked/> <label for="email" className='sometxt'>View Content</label>
                                            </div>
                                        </div> 

                                    </div>
                                </div>

                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/role-management" type="button" class="btn btn-info">Back</Link>
                                        <button type="button" class="btn difrnt">Update</button>
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default Editrole