import React, { useState, useEffect } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAssigneeList, getRoleById, updateRoleById } from "../../redux/api";
import { toast, ToastContainer } from "react-toastify";
import { fetchPermissionListAction } from "../../redux/actions/adminAction";
import { Button, Collapse,Input } from "reactstrap";
import {
  OutlinedInput,
  InputLabel,
  TextField,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const ViewRole = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
   

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [adminUser, setAdminUser] = useState([]);
  const [role, setRole] = useState({
    roleName: "",
    status: "",
    permissions: [],
    permissionPath: [],
  });
  const [originalRole, setOriginalRole] = useState({
    roleName: "",
    status: "",
    permissions: [],
    permissionPath: [],
  });

  const [edit, setEdit] = useState(false);

  const permissionList = useSelector((state) => state.adminUser.permission);

  const fetchAssigmentUser = async () => {
    try {
      const { data } = await getAssigneeList(params.roleId);
      setAdminUser(data.data);
    } catch (error) {
      console.log("assignee user list fetch error");
    }
  };

  const fetchRoleDetails = async () => {
    try {
      const { data } = await getRoleById(params.roleId);
      setRole({
        ...role,
        roleName: data.data.roleName,
        status: data.data.status,
        permissions: data.data.permissions,
        permissionPath: data.data.permissionPath,
      });
      setOriginalRole({
        ...role,
        roleName: data.data.roleName,
        status: data.data.status,
        permissions: data.data.permissions,
        permissionPath: data.data.permissionPath,
      });
      setPersonName(data.assignedUser);
    } catch (error) {
      console.log("assignee user list fetch error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!role.roleName) {
        throw new Error("Please enter role name");
      }
      const { data } = await updateRoleById(params.roleId, {
        roleName: role.roleName,
        permissions: role.permissions,
        permissionPath: role.permissionPath,
        assignedUser:personName
      });

      toast.success(data?.message || "Role Information updated successfully", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      setEdit(false);
      fetchRoleDetails();
      //   history.push('/admin/role-management')
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong in adding role",
        {
          autoClose: 1500,
          hideProgressBar: true,
        }
      );
      console.log("error add role");
    }
  };

  const handleCancel = () => {
    setEdit(false);
    setRole({
      ...role,
      roleName: originalRole.roleName,
      status: originalRole.status,
      permissions: originalRole.permissions,
      permissionPath: originalRole.permissionPath,
    });
  };

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // console.log(personName)
  // console.log(personName2)

  useEffect(() => {
    fetchAssigmentUser();
    fetchRoleDetails();
    dispatch(fetchPermissionListAction());
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="second_header12">
        <AdminNavbar brandText="View Role" />
      </div>

      <div className="mod_box moodmange">
        <div className="">
          <div className="row">
            <div className="col-md-12 addrole_outer">
              <div className="row">
                <div className="col-md-10">
                  <div className="addrole_top">
                    <h3 className="mb-0 text-fff py-3">View Role</h3>
                  </div>
                </div>
                <div className="m-auto col-md-2">
                  <Link
                    to="/admin/role-management"
                    type="button"
                    class="btn btn-info"
                  >
                    Back
                  </Link>
                </div>
              </div>

              <div className="try_whole">
                <div className="row try_whole">
                  <div className="col-md-12 labl_infore">
                    <div className="first_frm">
                      <div className="text-right mb-3">
                        {edit && (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={handleCancel}
                          >
                            <i className="fa fa-times"></i> Cancel
                          </Button>
                        )}
                        {edit ? (
                          <Button
                            color="success"
                            size="sm"
                            onClick={handleSubmit}
                          >
                            <i className="fa fa-check"></i> Done
                          </Button>
                        ) : (
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => setEdit(true)}
                          >
                            <i className="fa fa-edit"></i> Edit
                          </Button>
                        )}
                      </div>
                      <label for="">Role Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={role.roleName}
                        onChange={(e) => {
                          setRole({
                            ...role,
                            roleName: e.target.value,
                          });
                        }}
                        disabled={edit ? false : true}
                        placeholder="Enter Role Name"
                        id=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="first_frm">
                      <FormControl
                        sx={{
                          m: 1,
                          // width: 300
                        }}
                        fullWidth
                        size="small"
                      >
                        <InputLabel id="role-assign-admin-user">Assign User</InputLabel>
                        <Select
                         labelId="role-assign-admin-user"
                         id="role-assign-admin-user"
                          multiple
                          fullWidth
                          disabled={!edit}
                          value={personName}
                          onChange={handleChange}
                          input={<OutlinedInput label="Assign User" />}
                          // input={<Input label="Assign User" />}
                          renderValue={(selected) => {
                            let name = selected.map((el) => `${el.name}, `);

                            //  return selected.join(', ')
                            return name;
                          }}
                          MenuProps={MenuProps}
                        >
                          {adminUser.map((admin) => (
                            <MenuItem
                            size="small"
                             key={admin._id} value={admin}>
                              <Checkbox
                                checked={personName.find(el=>el._id==admin._id)?true:false}
                              />
                              <ListItemText primary={admin.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          multiple
          size="small"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput/>}
          renderValue={(selected) =>{
            let name=selected.map(el=>`${el.name}, `);
          
            //  return selected.join(', ')
             return name
            }}
          MenuProps={MenuProps}
        >
          {adminUser.map((admin) => (
            <MenuItem key={admin._id} value={admin}>
              <Checkbox checked={personName.indexOf(admin) > -1} />
              <ListItemText primary={admin.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="first_frm">
                      <label for="">Assign Permission</label>
                      {/* <input type='text' className='form-control' placeholder='' value="Super Admin" id='text' disabled></input> */}
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" disabled={edit ? false : true} name="vehicle1"
                        
                         /> */}{" "}
                        <label for="email">Capsule</label>
                      </div>
                    </div>
                    <div className="row">
                      {" "}
                      {permissionList
                        .filter((perm) => perm.module === "CAPSULE")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                // value={perm._id}
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );

                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" disabled={edit ? false : true} name="vehicle1" />{" "} */}
                        <label for="email">User</label>
                      </div>
                    </div>
                    <div className="row">
                      {" "}
                      {permissionList
                        .filter((perm) => perm.module === "USER")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" disabled={edit ? false : true} name="vehicle1" />{" "} */}
                        <label for="email">Community</label>
                      </div>
                    </div>
                    <div className="row">
                      {permissionList
                        .filter((perm) => perm.module === "COMMUNITY")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" disabled={edit ? false : true} name="vehicle1" />{" "} */}
                        <label for="email">Journal</label>
                      </div>
                    </div>
                    <div className="row">
                      {permissionList
                        .filter((perm) => perm.module === "JOURNAL")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" disabled={edit ? false : true} name="vehicle1" />{" "} */}
                        <label for="email">Mood</label>
                      </div>
                    </div>
                    <div className="row">
                      {permissionList
                        .filter((perm) => perm.module === "MOOD")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" name="vehicle1" disabled={edit ? false : true} />{" "} */}
                        <label for="email">Questionary</label>
                      </div>
                    </div>
                    <div className="row">
                      {permissionList
                        .filter((perm) => perm.module === "QUESTIONARY")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* ----------------------------------- */}
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" name="vehicle1" disabled={edit ? false : true} />{" "} */}
                        <label for="email">Notification</label>
                      </div>
                    </div>
                    <div className="row">
                      {permissionList
                        .filter((perm) => perm.module === "NOTIFICATION")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        {/* <input type="checkbox" id="vehicle1" disabled={edit ? false : true} name="vehicle1" />{" "} */}
                        <label for="email">Admin</label>
                      </div>
                    </div>
                    <div className="row">
                      {permissionList
                        .filter((perm) => perm.module === "ADMIN")
                        .map((perm) => (
                          <div
                            key={perm._id}
                            className="col-md-3 inbuilt leftsift"
                          >
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                disabled={edit ? false : true}
                                checked={role.permissions.includes(perm._id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setRole({
                                      ...role,
                                      permissions: [
                                        ...role.permissions,
                                        perm._id,
                                      ],
                                      permissionPath: [
                                        ...role.permissionPath,
                                        {
                                          method: perm.method,
                                          path: perm.path,
                                        },
                                      ],
                                    });
                                  } else {
                                    const filteredperm =
                                      role.permissions.filter(
                                        (elem) => elem !== perm._id
                                      );
                                    const filteredpermPath =
                                      role.permissionPath.filter(
                                        (elem) =>
                                          !(
                                            elem.path === perm.path &&
                                            elem.method === perm.method
                                          )
                                      );
                                    setRole({
                                      ...role,
                                      permissions: filteredperm,
                                      permissionPath: filteredpermPath,
                                    });
                                  }
                                }}
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="brn-topwrp"></div>
                  <div className="bdn_cover">
                    <div className="m-auto col-lg-6">
                      {/* <Link
                        to="/admin/role-management"
                        type="button"
                        class="btn btn-info"
                      >
                        Back
                      </Link> */}
                      {/* <button
                        type="button"
                        class="btn difrnt"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button> */}
                    </div>
                  </div>
                  <div className="brn-botwrp"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRole;
