import React from 'react';

import{
    CardHeader, CardBody
} from 'reactstrap'

import { Link } from 'react-router-dom';

import moment from "moment";

const UserDetails = ({detail}) => {


    return (
        <>
        { detail&& 
          <>
           
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0">User Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/customer-management" className="btn bg-gradient-danger text-white px-4">Back</Link>
                </div>
                </div>
            </CardHeader>
      
            
            <CardBody>
            <div className="text-right mb-3" >
                 
              </div>
                <div className="pl-lg-4">
                <div className="row">
                <div className="col-lg-3">
                  <img alt="" 
                  // src={`${process.env.REACT_APP_BASE_URL}${detail.picture}`} 
                  src={`http://alobhaitsolution.com:3009${detail?.picture}`} 
                  width="100%" />
                  
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label" >Username</label>
                        <input type="text"
                         name="name" 
                         className="form-control"
                          value={detail?.name}
                          disabled />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label" >Mobile Number</label>
                        <input type="text"
                        className="form-control"
                        name="number"
                        value={detail?.number}
                        disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label" for="input-username">User Created</label>
                        <input type="text" 
                        className="form-control" 
                        value={moment(detail?.createdAt).format("MMM Do YY")}
                        disabled/>
                       </div>
                      </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label className="form-control-label" >Status</label>
                            </div>
                                <input type="text" 
                                className="form-control"
                                value={detail?.isActive?"Active":"Inactive"} disabled/>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </CardBody>
                </>
            }             
        </>
    )
}

export default UserDetails
