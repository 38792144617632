import React,{useState,useEffect} from 'react';

import{
     CardHeader, CardBody,Button,
} from 'reactstrap'

import { Link } from 'react-router-dom';
import { ToastContainer,toast  } from 'react-toastify';
import { useSelector } from 'react-redux';


import moment from "moment";
import { updateCommunityById } from 'redux/api';

const CommunityDetails = ({detail,setCommunityData,communityData}) => {
 

  const [community, setcommunity] = useState({
      title:'',
      description:'',
      newImage:'',
      approved:'',
      image:''
})

const [edit, setEdit] = useState(false);
  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const editPerm = permissionPath.find(perm=>perm.method=="PATCH"&&perm.path=="/api/v2.0/community/:communityId")

const handleChange=(e)=>{
  setcommunity({...community,[e.target.name]:e.target.value});
  if(e.target.files){           
      setcommunity({...community,newImage:e.target.files[0]});
  }
  
}

const handleSubmit =async(e)=>{
  e.preventDefault();
  let formdata = new FormData();
  community.title&&formdata.append('title',community.title);
  community.description&&formdata.append('description',community.description);
  community.newImage&& formdata.append('community',community.newImage);
  community.approved&& formdata.append('approved',community.approved);
  
  try {
    const {data} = await updateCommunityById(detail._id,formdata);
    setEdit(false);
    setcommunity({...community,
        title:data.data.title,
        description:data.data.description,
        image:data.data.image,
        approved:data.data.approved});
        detail.title=data.data.title;
        detail.description=data.data.description;
        detail.approved=data.data.approved;
        detail.image=data.data.image;

        setCommunityData({
          ...communityData
        })
        toast.success(data.message,{
          autoClose: 1500,
          hideProgressBar: true,
        })
  } catch (error) {
    toast.error(error?.response?.data?.message||error?.message||"Something went wrong,please try again later",{
      autoClose: 1500,
      hideProgressBar: true,
    })
    console.log("error updating community");
  }

}
const handleCancel=()=>{
  setEdit(false);
  setcommunity({
    ...community,
    title:detail?detail.title:'',
    description:detail?detail.description:'',
    newImage:'',
    approved:detail.appvoved,
    image:detail?detail.image:''
})
}


useEffect(()=>{

  if(detail!=null){
    setcommunity({
      ...community,
      title:detail.title,
      description:detail.description,
      approved:detail.approved
    })
  }

},[detail])

    return (
        <>
         <ToastContainer />
        { detail&& 
          <>
           
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0 text-dark">Community Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/community" className="btn bg-gradient-danger text-white px-4">Back</Link>
                </div>
                </div>
            </CardHeader>
                  
            <CardBody>
              {
                adminRole=="superAdmin"?
                <div className="text-right mb-3" >
                {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                  }
                  {edit?
                    <Button color="success" size='sm' onClick={handleSubmit} >
                              <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="danger" size='sm' onClick={()=>setEdit(true)} >
                              <i className="fa fa-edit"></i> Edit
                    </Button>
                      }
                </div>:editPerm?<div className="text-right mb-3" >
                {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                  }
                  {edit?
                    <Button color="success" size='sm' onClick={handleSubmit} >
                              <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="danger" size='sm' onClick={()=>setEdit(true)} >
                              <i className="fa fa-edit"></i> Edit
                    </Button>
                      }
                </div>:null
              }
                <div className="pl-lg-4">
                <div className="row">
                <div className="col-lg-3">
                   
                  <img alt="placeholder" 
                    src={`${process.env.REACT_APP_BASE_URL}${detail.image}`}
                     width="100%"/>
                  {edit&&
                    <div className="custom-file" style={{marginTop:'5px'}} >
                              <input type="file" name="community" accept="image/*"
                              className="custom-file-input" onChange={handleChange}/>
                              <label className="custom-file-label" >{community.newImage.name?community.newImage.name:'Choose Image...'}</label>
                    </div>
                  }


                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label" >Community Name</label>
                        <input
                          type="text" 
                          className="form-control" 
                          name="title" 
                          value={community.title} 
                          onChange={handleChange}
                          disabled={!edit}/>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">Description</label>
                        <input 
                          type="text"
                          className="form-control" 
                          name="description"
                          onChange={handleChange}
                          value={community.description} 
                          disabled={!edit}/>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label" >Community Create On</label>
                        <input type="text"  className="form-control" value={moment(detail.createdAt).format("MMM Do YY")} disabled/>
                       </div>
                      </div>
                        <div className="col-lg-4">
                        <div className="form-group ">
                            <label className="control-label">Approval Status</label>
                            <select className="form-control" name="approved" value={community.approved}  onChange={handleChange} disabled={!edit}>
                            <option
                             value=""
                              >Select Status</option>
                            <option value={true}>Approved</option>
                            <option value={false} >Unapproved</option>
                            </select>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </CardBody>
                </>
            }             
        </>
    )
}

export default CommunityDetails
