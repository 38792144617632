import * as api from '../api/index';
import {GET_NOTIFICATIONS} from '../constants/notificationConstants'


export const getNotification = ()=> async(dispatch)=>{

    try {
        const {data} = await api.getAllNotification();

        dispatch({type:GET_NOTIFICATIONS,payload:data})
        
    } catch (error) {
        // dispatch({type:GET_ALL_DISCUSSIONS,payload:error})
        console.log(error);
    }
}