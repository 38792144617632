import React,{useState,useEffect} from 'react'

import { Card, CardBody, CardHeader, Container,Button } from 'reactstrap';
import { Link } from 'react-router-dom';


import UserHeader from 'components/Headers/UserHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { getPrivacy,updatePrivacy } from 'redux/api';
import { CKEditor } from 'ckeditor4-react';
import { toast, ToastContainer } from "react-toastify";


const Privacy = () => {

    const [edit, setEdit] = useState(false);

    const [privacy, setPrivacy] = useState({});

    const [update, setUpdate] = useState({
        title:'',content:'',image:''
    });


    const handleChange=(e)=>{
        setUpdate({...update,[e.target.name]:e.target.value});
        if(e.target.files){           
            setUpdate({...update,image:e.target.files[0]});
        } 
      }

      const handleSubmit=async(e)=>{
          e.preventDefault();
          
    let formdata = new FormData();
    update.title&&formdata.append('title',update.title);
    update.content&&formdata.append('content',update.content);
    update.image&&formdata.append('privacy',update.image);
  
          try {
              const {data} = await updatePrivacy(formdata);
              getPrivacyData();
              setEdit(false);
              toast.success(data?.message||"content updated successfully", {
                autoClose: 1500,
                hideProgressBar: true,
              });
              
          } catch (error) {
              console.log("error updating privacy");
              toast.error(error?.response?.data?.message||error?.message||"Something went wrong in updating content", {
                autoClose: 1500,
                hideProgressBar: true,
              });
          }
      }

    const handleCancel=()=>{
        setEdit(false);
        setUpdate({...update,title:'',content:'',image:''})
    }


    const getPrivacyData=async()=>{
        try {
            const {data} = await getPrivacy();
            setPrivacy(data.data.privacy);

            setUpdate({
              ...update,
              title:data.data.privacy.title,
              content:data.data.privacy.content,
            })
           
            
        } catch (error) {
            console.log("error getting about data");
        }
    }

    useEffect(()=>{
        getPrivacyData();
    },[])


    return (
        <>
         <ToastContainer />
        <AdminNavbar brandText="privacy" />
        <UserHeader/>

        <Container className="mt--7" fluid>
            <Card>
                <CardHeader>
                    <div className="row align-items-center">
                    <div className="col-8">
                    <h3 className="mb-0">Edit Privacy</h3>
                    </div>
                    <div className="col-4 text-right"> 
                        <Link to="/admin/static" className="btn bg-gradient-danger text-white px-4">Back</Link>
                    </div>
                    </div>
                </CardHeader>
                <CardBody>
                <div className="text-right mb-3" >
                    {edit&&< Button color="danger" size='sm' onClick={handleCancel}  >
                                <i className="fa fa-times"></i> Cancel
                        </Button>
                    }
                    {edit?
                        <Button color="success" size='sm' onClick={handleSubmit} >
                                <i className="fa fa-check"></i> Done
                        </Button>
                        :
                        <Button color="danger" size='sm' onClick={()=>setEdit(true)} >
                                <i className="fa fa-edit"></i> Edit
                        </Button>
                        }
                    </div>
        {/* {privacy&& */}
                <form>
            <div className="pl-lg-4">
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group">
                    <label className="form-control-label" >Page Title</label>
                    <input 
                    type="text" 
                    name="title" 
                    className="form-control"
                    disabled={!edit}
                    value={update.title?update.title:privacy?.title}
                    onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <img alt="placeholder" 
                  src={`${process.env.REACT_APP_BASE_URL}${privacy?.image}`} 
                  width="100%"/>
                  {edit&&
                <div className="custom-file mt-2 mb-2">
                        <input type="file" name="about"
                        className="custom-file-input" accept="image/*"
                        onChange={handleChange}
                         />
                        <label className="custom-file-label" >{update.image?update.image.name:'Choose file...'}</label>
                    </div>
                  }
                </div>
                <div className="col-lg-12">
                  {/* <div className="form-group">
                    <label className="form-control-label">Content</label>
                    <textarea 
                    name="content" cols="90" rows="10" 
                    className="rounded w-100 p-2"
                    disabled={!edit}
                    value={update.content?update.content:privacy?.content}
                    onChange={handleChange}

                    ></textarea> */}
                {
  update?.content&&
  
                    <CKEditor 
                    initData={update.content}
                    // debug={true}
                    readOnly={edit?false:true}
                    onChange={(e)=>{
                                           
                      setUpdate({
                        ...update,
                        content:e.editor.getData() 
                      })
                    }}
                   

                     />
}
                  {/* </div> */}
                </div>
                
              </div>
              
            </div>
          </form>
    {/* } */}
                    
                </CardBody>

            </Card>

        </Container>
            
        </>
    )
}

export default Privacy
