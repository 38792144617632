import React, { useState, useEffect } from "react";
import ImogiImage from "../../assets/img/brand/emoji.png";
import { Card } from "reactstrap";
import { Button, CardHeader, Row, Col,Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,Modal,ModalBody,ModalFooter,ModalHeader } from "reactstrap";

import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

import { getCapsulePost, deleteCapsulePost, getCapsuleSubcat,deleteCapsuleSubcatById } from "redux/api";
import ReactPlayer from "react-player";

const AddCapsuleSubcat = ({ id }) => {
  const [capsuleSubcat, setCapsuleSubcat] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const params = useParams();

  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const addPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/capsule/:capsuleId/sub-category");
  const viewPerm = permissionPath.find(perm=>perm.method=="GET"&&perm.path=="/api/v2.0/capsule/:capsuleId/sub-category/:capsuleSubcatId/tools");
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/capsule/:capsuleId/sub-category/:capsuleSubcatId");


  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id) => {
    setConfirm(true);
    setConfirmId(id);
  };

  const cancelDelete = () => {
    setConfirm(false);
    setConfirmId();
  };

  const fetchCapSubcat = async () => {
    const { data } = await getCapsuleSubcat(id);
    setCapsuleSubcat(data.data);
  };

  const handleDelete = async () => {
    try {
      const { data } = await deleteCapsuleSubcatById(id, confirmId);
      const updatedPost = capsuleSubcat.filter(
        (elem) => elem._id !== confirmId
      );
      setCapsuleSubcat(updatedPost);
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      cancelDelete();
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log("error deleting capsule post");
    }
  };

  useEffect(() => {
    fetchCapSubcat();
  }, []);

  return (
    <>
      <ToastContainer />
      <Card className="mt-3">
        <CardHeader className="border-0">
          <Row>
            <Col lg={9}>
              <h3 className="mb-0 comunity_manage">Tool List</h3>
            </Col>
            <Col lg="3">

                  {
                    adminRole=="superAdmin"?
              <Link
                to={`/admin/capsule-management/${params.capsuleId}/add-subcategory`}
                className="float-right"
              >
                <Button color="info">Add Tool</Button>
              </Link>:addPerm?<Link
                to={`/admin/capsule-management/${params.capsuleId}/add-subcategory`}
                className="float-right"
              >
                <Button color="info">Add Tool</Button>
              </Link>:null
                  }

            </Col>
          </Row>
        </CardHeader>
        <table class="table mood_table">
          <thead class="thead-light">
            <tr>
              <th>Sr. No</th>
              <th>Title</th>
              {/* <th>Sub Category Capsule  Name</th>
              <th>Sub Category Capsule Icon</th>                */}

              {
                adminRole=="superAdmin"?
                <th>Action</th>:viewPerm||deletePerm?<th>Action</th>:null
              }

            </tr>
          </thead>
          <tbody>

          <Modal isOpen={confirm} >
                    <ModalHeader >Confirm delete sub-category ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this sub-category, all content inside will be deleted forever?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>

          {capsuleSubcat.length > 0 && 
                          (rowsPerPage > 0
                            ? capsuleSubcat.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : capsuleSubcat
                          ).map((subcat, index) => (
              <tr key={`capsule-sub-cat-${subcat._id}`}>
                <td>{page * rowsPerPage + (index + 1)} </td>
                <td>{subcat.title} </td>

                <td>
                  <div>

                    {
                      adminRole=="superAdmin"?
                    <Link
                      to={{
                        pathname: `/admin/capsule-management/${id}/tools/${subcat._id}`,
                      }}
                      type="button"
                      className="btn text-purple "
                    >
                      <i className="fa fa-eye"></i>
                    </Link>:viewPerm?<Link
                      to={{
                        pathname: `/admin/capsule-management/${id}/tools/${subcat._id}`,
                      }}
                      type="button"
                      className="btn text-purple "
                    >
                      <i className="fa fa-eye"></i>
                    </Link>:null
                    }

                    {
                      adminRole=="superAdmin"?
                    <div type="button" class="btn text-red" onClick={()=>deletePopup(subcat._id)} >
                      <i class="fa fa-trash"></i>
                    </div>:deletePerm?<div type="button" class="btn text-red" onClick={()=>deletePopup(subcat._id)} >
                      <i class="fa fa-trash"></i>
                    </div>:null
                    }


                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem 
                      className={page!==0?"active":''}
                      >
                        <PaginationLink
                         onClick={() =>{
if(page>0){
   setPage(prevPage=>prevPage-1)
}
                         } 
                        }
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {page+1}
                          {/* {pageIndex+1} */}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem 
                      className={Math.ceil(capsuleSubcat.length/rowsPerPage)>(page+1)?"active":''}
                      >
                        <PaginationLink
                          onClick={() => {
                            if(Math.ceil(capsuleSubcat.length/rowsPerPage)>(page+1)){
                              setPage(prevPage=>prevPage+1)

                            }

                          }
                          }
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
      </Card>
    </>
  );
};

export default AddCapsuleSubcat;
