import {createStore,applyMiddleware,combineReducers} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { authReducer } from './reducers/authReducer';
import { userReducer } from './reducers/userReducer';
import { communityReducer } from './reducers/communityReducer';
import { capsuleReducer } from './reducers/capsuleReducer';
import { staticReducer } from './reducers/staticReducer';
import { notificationReducer } from './reducers/notificationReducer';
import { discussionReducer } from './reducers/discussionReducer';
import {masterReducer} from './reducers/masterReducer';
import {adminUserReducer} from './reducers/adminUserReducer';

const reducer = combineReducers({
    auth:authReducer,
    user:userReducer,
    community:communityReducer,
    capsule:capsuleReducer,
    notification: notificationReducer,
    discussion: discussionReducer,
    static: staticReducer,
    master:masterReducer,
    adminUser:adminUserReducer
})


const middleware = [thunk];

const store = createStore(reducer,composeWithDevTools(applyMiddleware(...middleware)));

export default store;