import React,{useEffect,useMemo,useState} from 'react';
import {ndata} from './notificationData';
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row
  } from "reactstrap";
  import moment from 'moment';

  import { useDispatch,useSelector } from 'react-redux';

import NotificationHeader from './NotificationHeader';
import { useTable,useSortBy,useGlobalFilter,useFilters,usePagination } from "react-table";
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { getNotification } from 'redux/actions/notificationActions';


const Notification = () => {
  const dispatch = useDispatch();

  const notification = useSelector(state=>state.notification.details);


  const filterTypes = useMemo(
    () => ({
        dateBetween: dateBetweenFilterFn,
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id];
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true;
            });
        }
    }),
    []
  );

  const COLUMNS=[
    {
        Header:'Sr. No',
        accessor:'',
        Cell:(props)=> props.row.index+1
       
    },
    {
        Header:'Title',
        accessor:'content.message',
       
    },
    {
        Header:'Notification Type',
        accessor:"type"
    },
    {
        Header:'Notification Time',
        accessor:"createdAt",
        filter:'dateBetween',
        Cell: (props)=> {return props.row.original.createdAt?moment(props.row.original.createdAt).format("MMM Do YYYY, h:mm a"):"NA"}
    },
    {
        Header:'Status',
        accessor:"read",
        Cell: (props)=> {
          return props.row.original.read?"Read":"Unread"}
    },
      
      
    
]

const columns = useMemo(()=> COLUMNS,[]);
    const data = useMemo(()=>notification,[notification]);
    const tableInstance = useTable({
      columns: columns,
      data: data,
      filterTypes
    },useFilters,useGlobalFilter,useSortBy,usePagination)

  const{getTableProps,getTableBodyProps,
    headerGroups,rows,page,prepareRow,state,setGlobalFilter,setFilter,
    nextPage,previousPage,canPreviousPage,canNextPage
  } = tableInstance;

  const {globalFilter,filters,pageIndex} = state;

  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }



  useEffect(()=>{
    dispatch(getNotification());

  },[])

    return (
        <div>
            <NotificationHeader setFilter={setFilter} filter={filters} />
            <AdminNavbar filter={globalFilter} setFilter={setGlobalFilter} brandText="Notification Management" />

            <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0 comunity_manage">Notification Management</h3>
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Notification List</th>
                      <th scope="col">Notification Type</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="text-center" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notification&&notification.map((elem)=>(
                    <tr key={elem._id}>
                      <td>{elem.content.message}</td>
                      <td>
                      {elem.type}
                      </td>
                      <td>
                        {elem.read?"Read":"Unread"}
                      </td>
                      <td className="d-flex justify-content-center"> 
                        <a href="/user-edit" type="button" className="btn text-yellow"><i className="fa fa-check"></i></a>
                        <a href="/delete-user" type="button" className="btn text-red" data-toggle="modal" data-src="#" data-target="#myModal"><i className="fa fa-trash"></i></a>
                    </td> 
                    </tr>

                    ))}
                  </tbody>
                </Table> */}

          <Table {...getTableProps()} className="align-items-center table-flush" responsive >
                  <thead className="thead-light text-center">
                    {headerGroups.map((headerGroup)=> (
                      <tr {...headerGroup.getHeaderGroupProps()} >
                        {
                          headerGroup.headers.map(column=> (
                            <th {...column.getHeaderProps(column.getSortByToggleProps)} scope="col"  >
                              {column.render('Header')} 
                              <span>
                                {column.isSorted?(column.isSortedDesc?'🔽':'🔼'):null }
                              </span>     
                            </th>
                          ))
                        }
                      </tr>
                    ) )}
                  </thead>
                  {rows.length!==0?
                  <tbody {...getTableBodyProps()} >
                    {
                      page.map(row=>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <td {...cell.getCellProps()} scope="row" className="text-center" >
                                   {cell.render('Cell')}
                                                                                  
                                  </td>
                                )
                              } )}                     
                            </tr>
                        )
                      })
                    }
                  </tbody>
                   :<h2 className="text-center" >No Data Available</h2>}
                </Table>


                <CardFooter className="py-4">
                  <nav aria-label="...">
                  <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={canPreviousPage?"active":''}>
                        <PaginationLink
                          onClick={() => previousPage()}
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {pageIndex+1}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem className={canNextPage?"active":''}>
                        <PaginationLink
                          onClick={() => nextPage()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          
        </Container>
            
        </div>
    )
}

export default Notification
