import React from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link } from 'react-router-dom';
import ImogiImage from '../../assets/img/brand/emoji.png'
// <AdminNavbar brandText="Add Role" />
const Viewcontent = () => {
  return (
    <>
      <div className='second_header12' >
    <AdminNavbar  brandText="View Details" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="container">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>View Details</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 

                            <div className='try_whole'>
                           <div className="row try_whole">


                                    <div className='col-md-2 labl_infore pt-3'>
                                      <div className="">
                                        <img src={ImogiImage} alt="" style={{width:'100%'}}/>
                                      </div>
                                    </div>                             
                                   <div className='col-md-3 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Capsule 1</label>
                                      </div>
                                  </div>          
                                   <div className='col-md-3 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Tool 1</label>
                                      </div>
                                  </div>          
                                   <div className='col-md-3 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Lorem sdsd sfs fs</label>
                                      </div>
                                  </div>          
                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/content-management" type="button" class="btn btn-info">Back</Link>
                                        {/* <button type="button" class="btn difrnt">Update</button> */}
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default Viewcontent