import * as api from '../api/index';

import{ADMIN_LOGIN, LOGOUT,ADMIN_LOGIN_ERROR} from '../constants/authConstants';

export const adminLogin = (credentials,history)=> async(dispatch)=>{

    try {
        const {data} = await api.login(credentials);
        const me = await api.getMe();

        me&&me.data&&me.data.data.details.role!=='admin'&&history.push('/auth/login')

        dispatch({type:ADMIN_LOGIN,payload:data})
        history.push('/admin')
        
    } catch (error) {
        if(error.response){
            dispatch({type:ADMIN_LOGIN_ERROR,payload:error.response.data.message});
        } else{
            dispatch({type:ADMIN_LOGIN_ERROR,payload:"Something went wrong, please try again later"});
        }
    }
}

export const logout =(history)=>async(dispatch)=>{
    try{
        await api.logout();
        dispatch({type:LOGOUT});
        history.push('/auth/login');
        
    } catch{
        console.log("logout error")
    }
}