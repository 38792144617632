import React,{useState,useEffect} from "react";
import ImogiImage from '../../assets/img/brand/emoji.png'
import {
  Card
} from "reactstrap";
import {
    Media,
    Table,Button,Modal,ModalHeader,ModalBody,ModalFooter,CardHeader,Row,Col
  } from "reactstrap";

  import { Link,useParams } from "react-router-dom";
  import { toast,ToastContainer } from "react-toastify";
  import { useSelector } from "react-redux";
  
  
  import { getCapsuleSubcatTools,deleteCapsulePost,deleteCapsuleContentById } from "redux/api";
  import ReactPlayer from "react-player";

const CapsulePost = ({subCategoryId,capsuleId}) => {

    
    const [capsulePost, setcapsulePost] = useState([])
    const params = useParams();

    const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const addPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/capsule/:capsuleId/sub-category/:capsuleSubcatId/tools");
  const viewPerm = permissionPath.find(perm=>perm.method=="GET"&&perm.path=="/api/v2.0/capsule/:capsuleId/sub-category/:capsuleSubcatId/tools/:toolId");
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/capsule/:capsuleId/sub-category/:capsuleSubcatId/tools/:toolId");

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }

  

    const capsulePostHandler=async()=>{
      const {data} = await getCapsuleSubcatTools(capsuleId,subCategoryId);

        setcapsulePost(data.data);

    }

    const handleDelete=async()=>{
      try {

        const {data} = await deleteCapsuleContentById(params.capsuleId,params.subCategoryId,confirmId);
        // const updatedPost = capsulePost.filter(elem=>elem._id!==confirmId);
        // setcapsulePost(updatedPost);
        toast.success(data.message,{
          autoClose: 1500,
          hideProgressBar: true
      })
        cancelDelete();
      } catch (error) {
        toast.error(error.response.data.message,{
          autoClose: 1500,
          hideProgressBar: true
      }) 
        console.log("error deleting capsule post")
      }
      

    }


    useEffect(()=>{
        capsulePostHandler();
    },[])


  return (
    <>        
    <ToastContainer />
    <Card className="mt-3">
      
        <CardHeader className="border-0">
          <Row>
            <Col lg={9}>
              <h3 className="mb-0 comunity_manage">Tool Content</h3>
            </Col>
            <Col lg="3">
              {
                adminRole=="superAdmin"?
              <Link
                to={`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}/add-content`}
                className="float-right"
              >
                <Button color="info">Add Content</Button>
              </Link>:addPerm? <Link
                to={`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}/add-content`}
                className="float-right"
              >
                <Button color="info">Add Content</Button>
              </Link>:null
              }
            </Col>
          </Row>
        </CardHeader>

            <table class="table mood_table">
          <thead class="thead-light">
            <tr>
              <th>Title</th>
              <th>Media</th> 
              {
                adminRole=="superAdmin"?
                <th>Action</th>:viewPerm||deletePerm? <th>Action</th>:null
              }          
            </tr>
          </thead>
          <tbody>
            {
              capsulePost.map((cont,index)=> (
            <tr key={index} >
            <td>{cont.title} </td>
              <td>
                {
                  cont.media.type=="video"?
                  <ReactPlayer controls width='200px' height='120px'
                    url={`${process.env.REACT_APP_BASE_URL}${cont.media?.content}`}
                    light={`${process.env.REACT_APP_BASE_URL}${cont.media?.thumbnail}`}
                     />
                  :
                  <img src={ImogiImage} alt="" />

                }
              </td>                    
                <td>
                    <div>
                      {
                        adminRole=="superAdmin"?
                    <Link type="button" class="btn text-purple " to={`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}/content/${cont._id}`}>
                      <i class="fa fa-eye"></i>
                    </Link>:viewPerm? <Link type="button" class="btn text-purple " to={`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}/content/${cont._id}`}>
                      <i class="fa fa-eye"></i>
                    </Link>:null
                      }

                     {
                      adminRole=="superAdmin"?
                    <div type="button" class="btn text-red" 
                    onClick={()=>{
                      deletePopup(cont._id);
                    }} 
                    ><i class="fa fa-trash"></i>
                    </div>:deletePerm?<div type="button" class="btn text-red" 
                    onClick={()=>{
                      deletePopup(cont._id);
                    }} 
                    ><i class="fa fa-trash"></i>
                    </div>:null
                     }
                    </div>
              </td>
            </tr>

              ) )
            }
            
            
          </tbody>
      </table>
    </Card>
    <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this capsule?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>
    </>
  );
};

export default CapsulePost;
