import {GET_NOTIFICATIONS} from '../constants/notificationConstants';

const initialState={
    isLoading:false,
    isError:false,
    message:"",
    details:[]
}

export const notificationReducer =(state=initialState,action)=>{
    switch (action.type) {
        case GET_NOTIFICATIONS:
            
            return {
                ...state,
                details:action.payload.data
            }

    
        default:
            return state;
    }
}