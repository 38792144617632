import React,{useState,useMemo} from 'react'
import {  Container} from "reactstrap";


const UserHeader = ({setFilter}) => {

 const [value, setValue] = useState({
   fromDate:'',toDate:'',active:'',username:'',email:''
 })
  


//  const [min, max] = useMemo(() => {
//   let min = preFilteredRows.length ? new Date(preFilteredRows[0].values['createdAt']) : new Date(0)
//   let max = preFilteredRows.length ? new Date(preFilteredRows[0].values['createdAt']) : new Date(0)

//   preFilteredRows.forEach(row => {
//     const rowDate = new Date(row.values['createdAt'])

//     min = rowDate <= min ? rowDate : min
//     max = rowDate >= max ? rowDate : max
//   })

//   return [min, max]
// }, [preFilteredRows])


//console.log(max);


  const handleChange=(e)=>{
   e.preventDefault();
   setValue({...value,[e.target.name]:e.target.value});
  }

  const handleSubmit=(e)=>{
    e.preventDefault();
   setFilter("name",value.username?value.username:undefined);
   setFilter("email",value.email?value.email:undefined);
   setFilter('isActive',value.active?value.active:undefined);
let dateFilter =[];
        
    const sd1 = new Date(value.fromDate).setHours(0,0,0);
    const sd = new Date(sd1);
    const ed1 = new Date(value.toDate).setHours(23,59,0);
    const ed = new Date(ed1);


if(value.fromDate&&value.toDate){
  dateFilter[0]=sd;
  dateFilter[1]=ed;
}

  setFilter('createdAt',dateFilter)
   
  }

  const handleCancel=(e)=>{
    e.preventDefault();
    setValue({fromDate:'',toDate:'',active:'',username:'',email:''})
    setFilter("name",undefined);
   setFilter("email",undefined);
   setFilter('isActive',undefined);
   setFilter('createdAt',undefined)
  }



    return (
        <>
        {/* <UserInput  /> */}
    <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">

            <form >
                  <div className="form-body">
                    <div className="row p-t-20 text-white" style={{alignItems:'center'}}>
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">From Date</label>
                          <input type="date"
                           name='fromDate'
                           max={value.toDate}
                           onChange={handleChange}
                           value={value.fromDate}
                           className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">To Date</label>
                          <input type="date"
                           className="form-control"
                            name="toDate"
                            min={value.fromDate}
                            value={value.toDate}
                            onChange={handleChange}
                           />
                        </div>
                      </div>
                        <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Status</label>
                            <select className="form-control" name="active" value={value.active} onChange={handleChange}>
                            <option value=''>Select Status</option>
                            <option value={true} >Active</option>
                            <option value={false}>Inactive</option>
                            </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">User Name</label>
                            <input 
                            type="text" 
                            className="form-control"
                            name='username' 
                           value={value.username}
                           onChange={handleChange}
                            placeholder="Enter User Name"/>
                        </div>
                        </div>

                        <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Email Address</label>
                            <input 
                            type="text" 
                            className="form-control"
                            name='email' 
                           value={value.email}
                           onChange={handleChange}
                            placeholder="Enter Email"/>
                        </div>
                        </div>

                        
                        
                        <div className="col-md-3">
                        <div className="form-actions filter-btn" style={{marginTop:'5px'}}>
                          <button type="button" className="btn btn-info w-100 mr-1" onClick={handleCancel} >Reset</button>
                          <button className="btn difrnt w-100 ml-1" onClick={handleSubmit} >Search</button>
                        </div>
                       </div>
                    </div>
                  </div> 
                </form>


                </div>
        </Container>
      </div>
        </>
    )
}

export default UserHeader

