import React,{useEffect,useState} from 'react';

import { Button, ButtonGroup, Container,Card } from 'reactstrap';


import { getUserById } from 'redux/api';
import { useParams } from 'react-router';
import UserHeader from 'components/Headers/UserHeader';
import UserCommunity from './UserCommunity';
import UserJournal from './UserJournal';

import UserDetails from './UserDetails';
import AdminNavbar from 'components/Navbars/AdminNavbar';

const UserView = (props) => {

    const params = useParams();

    const [user, setUser] = useState({});
    const [activeView, setActiveView] = useState(1);

const getUser = async()=>{
    const {data} = await getUserById(params.userId)
    setUser(data.data);
}

useEffect(()=>{
    getUser();

},[])
    return (
        <>
        <AdminNavbar brandText="user details" />
           <UserHeader/>
        <Container className="mt--7" fluid>
           <Card>

            <UserDetails detail={user.detail} />
           </Card>
           <ButtonGroup className="mt-3" >
                <Button color="danger" active={activeView===1?true:false} onClick={()=>setActiveView(1)} >
                    <i className="ni ni-bullet-list-67 text-white" ></i>
                    &ensp;Journal {user.journal?.length}
                </Button>
                <Button color="danger" active={activeView===2?true:false} onClick={()=>setActiveView(2)}>
                    <i className="fa fa-users  text-white"></i>
                    &ensp;Community {user.joinedCommunity?.length}
                </Button> 
            </ButtonGroup>
            {activeView===1&&<UserJournal 
                journal={user.journal}
                user={user}
                setUser={setUser}
                />}
            {activeView===2&&<UserCommunity 
                community={user.joinedCommunity}
                user={user}
                setUser={setUser}
                 />}
        </Container>
    </>
    )
}

export default UserView
