import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
  } from "reactstrap";

  import { useDispatch, useSelector } from 'react-redux';
  import { useHistory } from 'react-router-dom';
  import {adminLogin} from '../../redux/actions/authActions';

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginInfo = useSelector(state=>state.auth.errorMessage);

    const [loginData, setLoginData] = useState({email:'',password:''});
    const [showPassword, setShowPassword] = useState(false);

    const handleChange =(e)=>{
        setLoginData({...loginData,[e.target.name]:e.target.value})
        
    }

    const handleLogin=(e)=>{
        e.preventDefault();
        dispatch(adminLogin(loginData,history));

    }

    return (
        <>

    <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-3">
          <h1 class="text-center text-white mt-2">Toby SignIn</h1>
          <div className="w-50 m-auto collapse-brand">
                <img className="w-100 px-4" src={require('../../assets/img/brand/TurleLogin.png').default} alt='' />
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            
            <Form role="form" onSubmit={handleLogin}  >
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83 tobylogn" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    name="email"
                    value={loginData.email}
                    onChange={(e)=>handleChange(e)}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open tobylogn" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={!showPassword?"password":"text"}
                    autoComplete="new-password"
                    name="password"
                    value={loginData.password}
                    required
                    onChange={(e)=>handleChange(e)}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText onClick={()=>setShowPassword(!showPassword)} >
                      {/* <i className="ni ni-lock-circle-open tobylogn" /> */}
                      {
                        showPassword?
                        <i className="fa fa-eye-slash tobylogn" aria-hidden="true"></i>:
                        <i className="fa fa-eye tobylogn" aria-hidden="true"></i>
                      }

                      
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {loginInfo&&<div className='text-red text-center mt-2 p-2' >{loginInfo}</div>}
              </FormGroup>
              <div className="row">
                        {/* <Col md="6">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                </label>
                            <span className="text-muted text-left">Remember me</span>
                        </Col> */}
                        {/* <div className='col-12 text-right' >
                          <Link to="/auth/forgot-password">Forgot password?
                          </Link>
                        </div> */}
                  
              </div>
              <div className="text-center">
                <Button to="" className="mt-3 btn btn-info px-5" color="info" type="submit"
                //  onClick={handleLogin}
                 >
                  Login
                </Button>
              </div>
              {/* <div className="row">
                        <div className='col-12 text-center' >If you don't have Account? 
                          <Link to="/auth/signup"> SignUp here
                          </Link>
                        </div>
                  
              </div> */}
            </Form>
          </CardBody>
        </Card>
        
      </Col>

        </>
    )
}

export default Login
