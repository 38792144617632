import React, { useState, useEffect } from "react";
import ImogiImage from "../../assets/img/brand/emoji.png";
import { Card } from "reactstrap";
import {
  Media,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";

import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminNavbar from "components/Navbars/AdminNavbar";
import UserHeader from "components/Headers/UserHeader";
import { getCapsulePost, deleteCapsulePost, getCapsuleSubcat,getCapsuleSubcatById } from "redux/api";
import ReactPlayer from "react-player";
import CapsuleSubcatDetail from "./CapsuleSubcatDetail";
import CapsulePost from "./CapsulePost";
import { useSelector } from "react-redux";

const CapsuleSubcatView = ({ id }) => {
  const [capsuleSubcat, setCapsuleSubcat] = useState([]);
  const [capsuleSubcatDetail, setCapsuleSubcatDetail] = useState({});

  const params = useParams();

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id) => {
    setConfirm(true);
    setConfirmId(id);
  };

  const cancelDelete = () => {
    setConfirm(false);
    setConfirmId();
  };

  const fetchCapSubcat = async () => {
    const { data } = await getCapsuleSubcat(params.capsuleId);
    setCapsuleSubcat(data.data);
  };
  const getchSubcatDetail= async()=>{
    try {
      const { data } = await getCapsuleSubcatById(params.capsuleId,params.subCategoryId);
      setCapsuleSubcatDetail(data.data);
      
    } catch (error) {
      console.log('subcat fetch failed')
    }
  }

  const handleDelete = async () => {
    try {
      const { data } = await deleteCapsulePost(id, confirmId);
      const updatedPost = capsuleSubcat.filter(
        (elem) => elem._id !== confirmId
      );
      setCapsuleSubcat(updatedPost);
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      cancelDelete();
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log("error deleting capsule post");
    }
  };

  useEffect(() => {
    fetchCapSubcat();
    getchSubcatDetail();
  }, []);

  return (
    <>
    <AdminNavbar brandText="Tool Detail" />
    <UserHeader/>
          
      <ToastContainer />
      <Container className="mt--7" fluid>

      
      <Card className="mt-3">
        <CapsuleSubcatDetail detail={capsuleSubcatDetail} setCapsuleSubcatDetail={setCapsuleSubcatDetail}   />
        <CapsulePost capsuleId={params.capsuleId} subCategoryId={params.subCategoryId}  />
        
      </Card>
      </Container>
    </>
  );
};

export default CapsuleSubcatView;
