import React,{useState} from "react";

import {
  Media,
  Table,
  Button,Modal,ModalBody,ModalHeader,ModalFooter
} from "reactstrap";
  import { toast,ToastContainer } from "react-toastify";
  import {useParams} from 'react-router-dom'

  
  import { deleteInvitation } from "redux/api";
  
  const CommunityInvitation = ({invitation,community,setCommunity}) => {
    const params = useParams();

    const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }

    const handleDelete=async()=>{
      try {
       const {data} = await deleteInvitation(params.communityId,confirmId);
        const filterInvite = invitation.filter(elem=>elem._id!==confirmId);
        setCommunity({...community,community:{...community.community,invitation:filterInvite} });
        toast.success(data.message,{
          autoClose: 1500,
          hideProgressBar: true
      })
      setConfirm(false);
      } catch (error) {
        toast.error(error.response.data.message,{
          autoClose: 1500,
          hideProgressBar: true
      })  
      setConfirm(false);  
      }
    }
    
  return (
    <>
    <ToastContainer />
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Invitor</th>
            <th scope="col">Invited</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {invitation&&invitation.map((elem) => (
            <tr key={elem._id}>
              <td>{elem.invitor?.name}</td>
              <td>{elem.invited?.name}</td>
              <th scope="row">
                {elem.status}
              </th>
              <td className="d-flex justify-content-center">                
                <div
                  type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(elem._id)}                
                >
                  <i className="fa fa-trash"></i>
                </div>
              </td>
            </tr>
          ))} 
        </tbody>
      </Table>
      <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to remove this user from community ?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
            </Modal>
    </>
  );
};

export default CommunityInvitation;
