
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import {Provider} from 'react-redux';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import store from './redux/store';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Auth from './Auth';


Sentry.init({
  dsn: "https://0d9634cf67b34b03a1fe8f911b9b9238@o1388950.ingest.sentry.io/6711704",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path='/admin' >
            <Auth Component={AdminLayout} />
        </Route>

        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
