import React,{useEffect,useState} from 'react';

import { Container,Card } from 'reactstrap';
import { useParams } from 'react-router';
import { ToastContainer } from 'react-toastify';

import UserHeader from 'components/Headers/UserHeader';
import CapsuleDetail from './CapsuleDetail';
import { getOneCapsule } from 'redux/api';
// import CapsulePost from './CapsulePost';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import CapsuleSubcat from './CapsuleSubcat';


const CapsuleView = () => {
    const params = useParams();

    const [capsule, setCapsule] = useState({});
       
const getCapsule = async()=>{
    const {data} = await getOneCapsule(params.capsuleId);
    setCapsule(data.data);
    
}

useEffect(()=>{
    getCapsule()

},[])
    return (
        <>
        <AdminNavbar brandText="capsule detail" />
           <UserHeader/>
           <ToastContainer />

           {/* { */}
            {/* //    capsule&& */}
        <Container className="mt--7" fluid>
           <Card>

            <CapsuleDetail detail={capsule} />

           </Card>
           
           {/* <CapsulePost id={params.capsuleId} /> */}
           <CapsuleSubcat id={params.capsuleId} />
                     
        </Container>
         {/* } */}
    </>
    )
}

export default CapsuleView
