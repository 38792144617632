import React, { useState, useEffect } from "react";
import gallery_icon from "../../assets/img/brand/gallery_icon.png";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Button,
  CardFooter,
  Card,
  CardBody,
} from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar";
import { ToastContainer, toast } from "react-toastify";
import {
  getJournalTemplates,
  addJournalTemplate,
  deleteJournalTemplateById,
  updateJournalTemplateStatusById,
  updateJournalTemplateById
} from "../../redux/api";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Journaltemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [deletePopup, setDeletePopup] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [editPopup , seteditPopup ] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();
  const [addTemplate, setAddTemplate] = useState({
    title: "",
    story: "",
    image: "",
  });
  const [editTemplate, setEditTemplate] = useState({
    title: "",
    story: "",
    image: "",
    _id:""
  });

  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const addPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/journal/templates");
  const statusPerm = permissionPath.find(perm=>perm.method=="PUT"&&perm.path=="/api/v2.0/journal/templates/:templateId");
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/journal/templates/:templateId");
  const editPerm = permissionPath.find(perm=>perm.method=="PATCH"&&perm.path=="/api/v2.0/journal/templates/:templateId");

  const fetchTemplates = async () => {
    try {
      const { data } = await getJournalTemplates();
      setTemplates(data.data);
    } catch (error) {
      console.log("template fetch error");
    }
  };

  const handleAddTemplate = async () => {
    try {
      if (!addTemplate.title || !addTemplate.story || !addTemplate.image) {
        throw new Error("Please add details of journal template");
      }
      const formData = new FormData();
      formData.append("title", addTemplate.title);
      formData.append("story", addTemplate.story);
      formData.append("image", addTemplate.image);

      const { data } = await addJournalTemplate(formData);
      toast.success(data?.message || "Journal template added successfully", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      fetchTemplates();
      handleCancelAdd();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while adding journal template",
        {
          autoClose: 1500,
          hideProgressBar: true,
        }
      );
      console.log("add template error");
    }
  };

  const handleEditTemplate = async () => {
    try {
      if (!editTemplate.title || !editTemplate.story || !editTemplate.image) {
        throw new Error("Please add details of journal template");
      }
      const formData = new FormData();
      formData.append("title", editTemplate.title);
      formData.append("story", editTemplate.story);
      formData.append("image", editTemplate.image);

      const { data } = await updateJournalTemplateById(editTemplate._id,formData);
      fetchTemplates();
      toast.success(data?.message || "Journal template updated successfully", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      handleCancelEdit();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while adding journal template",
        {
          autoClose: 1500,
          hideProgressBar: true,
        }
      );
      console.log("add template error");
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await deleteJournalTemplateById(confirmId);
      fetchTemplates();
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      cancelDelete();
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log("error deleting capsule post");
    }
  };

  const handleUpdateStatus = async (id) => {
    try {
      const { data } = await updateJournalTemplateStatusById(id);
      fetchTemplates();
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      fetchTemplates();
      handleCancelEdit();
      
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log("error updating status");
    }
  };

  const handleDeletePopup = (id) => {
    setConfirm(true);
    setConfirmId(id);
  };

  const cancelDelete = () => {
    setConfirm(false);
    setConfirmId();
  };

  const handleCancelAdd = () => {
    setAddPopup(false);
    setAddTemplate({
      ...addTemplate,
      title: "",
      story: "",
      image: "",
    });
  };

  const handleCancelEdit = () => {
    seteditPopup(false);
    setEditTemplate({
      ...editTemplate,
      title: "",
      story: "",
      image: "",
      _id:""
    });
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <>
      <ToastContainer />

      <div className="second_header12">
        <AdminNavbar brandText="Journal Templates" />
      </div>
      <div className="jounl-main mt--1">
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div className="">
          <div className="container">
            <Row>
              <Col lg={10}></Col>
              <Col lg={2}>
                {
                  adminRole=="superAdmin"?
                <Button color="info" onClick={() => setAddPopup(true)}>
                  Add Template
                </Button>:addPerm? <Button color="info" onClick={() => setAddPopup(true)}>
                  Add Template
                </Button>:null
                }
              </Col>
            </Row>
          </div>
          {/* <Card> */}
          {/* <div className="container"> */}
          <CardBody>
            <div className="row mt-2">
              {templates.length > 0 &&
                (rowsPerPage > 0
                  ? templates.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : templates
                ).map((template, index) => (
                  <>
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-6">
                      <div
                        className="inner_boxfst"
                        style={{ marginBottom: "30px" }}
                      >
                        <div className="row">
                          <div className="col-md-3">
                            <div className="inner_fstleft">
                              <img
                                style={{ width: "90px" }}
                                src={`${process.env.REACT_APP_BASE_URL}${template.journal.image}`}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="inner_fstright">
                              <div className="text-right">
                                {
                                  adminRole=="superAdmin"?
                                <div type="button" className="btn text-red"
                                onClick={()=>{
                                  seteditPopup(true);
                                  setEditTemplate({
                                    ...editTemplate,
                                    title:template.journal.title,
                                    story:template.journal.story,
                                    image:template.journal.story,
                                    _id:template._id
                                  })
                                }}
                                >
                                  <i className="fa fa-pencil-square-o"></i>
                                </div>:editPerm?<div type="button" className="btn text-red"
                                onClick={()=>{
                                  seteditPopup(true);
                                  setEditTemplate({
                                    ...editTemplate,
                                    title:template.journal.title,
                                    story:template.journal.story,
                                    image:template.journal.story,
                                    _id:template._id
                                  })
                                }}
                                >
                                  <i className="fa fa-pencil-square-o"></i>
                                </div>:null
                                }
                                {
                                  adminRole=="superAdmin"?
                                <div
                                  type="button"
                                  className={`btn ${
                                    template.isActive
                                      ? "text-red"
                                      : "text-green"
                                  }`}
                                  onClick={() =>
                                    handleUpdateStatus(template._id)
                                  }
                                >
                                  <i className="fa fa-lock"></i>
                                </div>:statusPerm?<div
                                  type="button"
                                  className={`btn ${
                                    template.isActive
                                      ? "text-red"
                                      : "text-green"
                                  }`}
                                  onClick={() =>
                                    handleUpdateStatus(template._id)
                                  }
                                >
                                  <i className="fa fa-lock"></i>
                                </div>:null
                                }

                                {
                                  adminRole=="superAdmin"?
                                <div
                                  type="button"
                                  className="btn text-red"
                                  onClick={() =>
                                    handleDeletePopup(template._id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </div>:deletePerm?<div
                                  type="button"
                                  className="btn text-red"
                                  onClick={() =>
                                    handleDeletePopup(template._id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </div>:null
                                }

                              </div>
                              <div className="top_fst">
                                <label>Title</label>
                                <br />

                                {/* <input
                            type="text"
                            placeholder="Add a Tile to this Entry"
                          /> */}
                                <p
                                  className="title"
                                  style={{ color: "inherit" }}
                                >
                                  {template.journal.title}
                                </p>
                              </div>

                              <div className="top_secon">
                                <label>Story</label>
                                <br />
                                {/* <input type="text" placeholder="write something" /> */}
                                <p
                                  className="story"
                                  style={{ color: "inherit" }}
                                >
                                  {template.journal.story}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-1"></div> */}
                  </>
                ))}
            </div>
          </CardBody>
          <CardFooter>
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem className={page !== 0 ? "active" : ""}>
                <PaginationLink
                  onClick={() => {
                    if (page > 0) {
                      setPage((prevPage) => prevPage - 1);
                    }
                  }}
                >
                  <i className="fas fa-angle-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>
                  {page + 1}
                  {/* {pageIndex+1} */}
                  <span className="sr-only">(current)</span>
                </PaginationLink>
              </PaginationItem>

              <PaginationItem
                className={
                  Math.ceil(templates.length / rowsPerPage) > page + 1
                    ? "active"
                    : ""
                }
              >
                <PaginationLink
                  onClick={() => {
                    if (Math.ceil(templates.length / rowsPerPage) > page + 1) {
                      setPage((prevPage) => prevPage + 1);
                    }
                  }}
                >
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </CardFooter>

          {/* </div> */}
          {/* </Card> */}
        </div>
      </div>
      <Modal isOpen={confirm}>
        <ModalHeader>Confirm delete Journal Template ?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this Journal Template?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleDelete()}>
            Confirm
          </Button>{" "}
          <Button color="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={addPopup}>
        <ModalHeader>New Journal Template</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="inner_fstright">
                <div className="top_fst">
                  <label>Title</label>
                  <br />
                  <textarea
                    type="text"
                    placeholder="Add a Tile to this Entry"
                    value={addTemplate.title}
                    onChange={(e) => {
                      setAddTemplate({
                        ...addTemplate,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="top_secon">
                  <label>Story</label>
                  <br />
                  <textarea
                    value={addTemplate.story}
                    type="text"
                    placeholder="write something"
                    onChange={(e) => {
                      setAddTemplate({
                        ...addTemplate,
                        story: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="inner_fstleft" style={{ marginTop: "15px" }}>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  id=""
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setAddTemplate({
                      ...addTemplate,
                      image: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleAddTemplate()}>
            Confirm
          </Button>{" "}
          <Button color="secondary" onClick={handleCancelAdd}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>



      <Modal isOpen={editPopup}>
        <ModalHeader>Update Journal Template</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="inner_fstright">
                <div className="top_fst">
                  <label>Title</label>
                  <br />
                  <textarea
                    type="text"
                    placeholder="Add a Tile to this Entry"
                    value={editTemplate.title}
                    onChange={(e) => {
                      setEditTemplate({
                        ...editTemplate,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="top_secon">
                  <label>Story</label>
                  <br />
                  <textarea
                    value={editTemplate.story}
                    type="text"
                    placeholder="write something"
                    onChange={(e) => {
                      setEditTemplate({
                        ...editTemplate,
                        story: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="inner_fstleft" style={{ marginTop: "15px" }}>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  id=""
                  onChange={(e) => {
                    setEditTemplate({
                      ...editTemplate,
                      image: e.target.files[0],
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleEditTemplate()}>
            Confirm
          </Button>{" "}
          <Button color="secondary" onClick={handleCancelEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>



    </>
  );
};

export default Journaltemplate;
