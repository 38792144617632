import React,{useState,useEffect} from 'react';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import {getMasterMood} from '../../redux/actions/masterAction';
import { Modal,ModalHeader,ModalBody,ModalFooter,Button,Pagination,PaginationItem,
  PaginationLink,CardFooter,Card } from 'reactstrap';
import {deleteMoodById} from '../../redux/api';
import {updateMoodStatusById} from '../../redux/api';
import { toast, ToastContainer } from "react-toastify";


const Moodmanagement = () => {

  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const moodList = useSelector(state=>state.master.mood.data);
  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const addPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/master/mood")
  const viewPerm = permissionPath.find(perm=>perm.method=="GET"&&perm.path=="/api/v2.0/master/mood/:moodId")
  const updatePerm = permissionPath.find(perm=>perm.method=="PATCH"&&perm.path=="/api/v2.0/master/mood/:moodId")
  const statusPerm = permissionPath.find(perm=>perm.method=="PUT"&&perm.path=="/api/v2.0/master/mood/:moodId")
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/master/mood/:moodId")

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId("");
  }


  const handleDelete=async()=>{
   try {
     await deleteMoodById(confirmId);
     dispatch(getMasterMood())
     cancelDelete();
    
   } catch (error) {
    console.log("mood delete error")
   }
  }

  const handleupdateMoodStatus =async(moodId)=>{
    try {
      const {data} = await updateMoodStatusById(moodId);
      dispatch(getMasterMood());
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
    }
  }


  useEffect(()=>{
    dispatch(getMasterMood())
  },[])

  return (
    <>
    <ToastContainer />

<div className='second_header12' >
    <AdminNavbar  brandText="Mood Management" />

    </div>
    <div className="mod_box moodmange">
      <Card>
    <div class="container">
      <div class="row">
      
              <div class="col-md-6">
            <div class=""> <h2 className="text-left mod_testi comunity_manage">Mood List</h2>
            </div>
          </div>
              <div class="col-md-6">
            <div class="form-actions filter-btn mangrol">
              {
                adminRole=="superAdmin"?
              <Link to="/admin/mood-management/addmood">
              <button type="button" class="btn btn-info mr-1 adrol">Add Mood</button>
              </Link>:addPerm?
              <Link to="/admin/mood-management/addmood">
              <button type="button" class="btn btn-info mr-1 adrol">Add Mood</button>
              </Link>	:null		   
              }
            </div>
          </div>
          
        </div>
      </div>

       
        <table class="table mood_table">
              <thead class="thead-light">
                <tr>
                  <th>Sr No.</th>
                  <th>Emoji Text</th>
                  <th>Emoji</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  moodList.length > 0 && 
                  (rowsPerPage > 0
                    ? moodList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : moodList
                  ).map((mood,index)=> (
                <tr key={`mmod-listing-${mood._id}`} >
                   <td> {page * rowsPerPage + (index + 1)}</td>
                  <td> {mood.name} </td>
                  <td>
                    <img 
                    width="80px"
                    src={`http://alobhaitsolution.com:3009${mood.icon}`} alt="" />
                  </td>
                  <td>
                      <div>
                        {
                          adminRole=="superAdmin"?
                       <Link to={`/admin/mood-management/${mood._id}`}>
                         <button type="button" className="btn text-info " >
                          <i className="fa fa-eye"></i>
                          </button>
                        </Link>:viewPerm?
                        <Link to={`/admin/mood-management/${mood._id}`}>
                        <button type="button" className="btn text-info " >
                         <i className="fa fa-eye"></i>
                         </button>
                       </Link>:null
                        }
                        {/* <Link to="/admin/editmood">
                          <button type="button" class="btn text-dark"><i class="fa fa-edit"></i>
                        </button>
                        </Link> */}
                        {
                          adminRole=="superAdmin"?
                          <div type="button" className={`btn ${mood.isActive?"text-red":"text-green"}`}
                         onClick={()=>handleupdateMoodStatus(mood._id)}
                          >
                            <i className="fa fa-lock"></i>
                        </div>:statusPerm?<div type="button" className={`btn ${mood.isActive?"text-red":"text-green"}`}
                         onClick={()=>handleupdateMoodStatus(mood._id)}
                          >
                            <i className="fa fa-lock"></i>
                        </div>:null
                        }
                        {
                          adminRole=="superAdmin"?
                        <div type="button" className="btn text-red"><i className="fa fa-trash" onClick={()=>{
                          deletePopup(mood._id)
                        }} ></i>
                        </div>:deletePerm?<div type="button" className="btn text-red"><i className="fa fa-trash" onClick={()=>{
                          deletePopup(mood._id)
                        }} ></i>
                        </div>:null
                        }
                      </div>
                  </td>
                </tr>

                  ))
                }
                
              </tbody>
         </table>
         <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem 
                      className={page!==0?"active":''}
                      >
                        <PaginationLink
                         onClick={() =>{
if(page>0){
   setPage(prevPage=>prevPage-1)
}
                         } 
                        }
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {page+1}
                          {/* {pageIndex+1} */}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem 
                      className={Math.ceil(moodList.length/rowsPerPage)>(page+1)?"active":''}
                      >
                        <PaginationLink
                          onClick={() => {
                            if(Math.ceil(moodList.length/rowsPerPage)>(page+1)){
                              setPage(prevPage=>prevPage+1)

                            }

                          }
                          }
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
      </Card>
      </div>
      <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this Mood?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>
      
     
      </>
      
  )
}

export default Moodmanagement