import React from "react";
import { useLocation, Route, Switch,Redirect } from "react-router-dom";

import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminFooter from '../components/Footers/AdminFooter';
import { ToastContainer } from "react-toastify";


import {adminRoutes,pages} from '../routes';

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
     
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
   
    const name = adminRoutes.find(val=>val.layout+val.path===path)||pages.find(val=>val.layout+val.path===path)
    if(name){
        return name.name;
    }else{
      return "Admin Panel"
    }
  };

  
  return (
    <>
    <ToastContainer />
    
      <Sidebar
        {...props}
        routes={adminRoutes}
        logo={{
          innerLink: "/admin/",
          imgSrc: require("../assets/img/brand/chatbot-logo.png").default,
          imgAlt: "logo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        {/* <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        /> */}
        <Switch>
         {getRoutes(adminRoutes)}
         {getRoutes(pages)}
         <Redirect from="/admin" to="/admin/dashboard" />
          
        </Switch>
        <AdminFooter/>
      </div>
    </>
  );
};

export default Admin;
