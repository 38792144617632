import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import ImogiImage from '../../assets/img/brand/emoji.png'
import sad from '../../assets/img/brand/sad.png'
import smile from '../../assets/img/brand/smile.png';
import { useSelector,useDispatch } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";

import {
  Pagination,PaginationItem,
  PaginationLink,
  CardFooter,Modal,ModalBody,ModalFooter,ModalHeader,Button} from 'reactstrap'


const Reports = () => {

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const deletePopup = (id) => {
    setConfirm(true);
    setConfirmId(id);
  };

  const cancelDelete = () => {
    setConfirm(false);
    setConfirmId();
  };

  const dispatch = useDispatch();
  const userList = useSelector(state=>state.user.users);


 

  


  useEffect(()=>{
   
  },[])

  return (
    <>
    <ToastContainer />
  <div className='second_header12' >
       

    </div>

    <div 
    className="mod_box moodmange no_margin_mood_box"
    >

      <div className='container fluid'>
         <div className='row'>


              <div className='col-md-6'>
                  <div className=''> <h3 className="text-left mod_testi comunity_manage">Report</h3></div>
              </div>
              

         </div>
      </div>
  
       
        <table class="table mood_table">
              <thead class="thead-light">
                <tr>
                  <th>S. No</th>
                  <th>User Name</th>
                  <th>Assessment Score</th>
                  <th>Mental Health</th>
                  
                </tr>
              </thead>
              <tbody>

              {
  userList.length > 0 && 
  (rowsPerPage > 0
    ? userList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : userList
  ).map((user,index)=> (
                <tr key={user._id} >
                  <td> {page * rowsPerPage + (index + 1)}</td>
                  <td>{user.name} </td>
                  <td 
                //   style={{textAlign:'left'}}
                  >
                      {
                        user.questionary.score
                      }
                    </td> 
                  <td> 
                    {
                      user?.mood?.moodInfo?.name?user.mood.moodInfo.name:"-"     
                    }
                  </td>
                  
                </tr>
    
  ) )
}
              
             
              </tbody>
         </table>
                       <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem 
                      className={page!==0?"active":''}
                      >
                        <PaginationLink
                         onClick={() =>{
if(page>0){
   setPage(prevPage=>prevPage-1)
}
                         } 
                        }
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {page+1}
                          {/* {pageIndex+1} */}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem 
                      className={Math.ceil(userList.length/rowsPerPage)>(page+1)?"active":''}
                      >
                        <PaginationLink
                          onClick={() => {
                            if(Math.ceil(userList.length/rowsPerPage)>(page+1)){
                              setPage(prevPage=>prevPage+1)

                            }

                          }
                          }
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>

      </div>
      
     
      </>
      
  )
}

export default Reports