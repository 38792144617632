import { useState } from "react";
import { NavLink as NavLinkRRD, Link,useRouteMatch  } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import {useSelector,useDispatch} from 'react-redux';


// reactstrap components
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";


const Sidebar = (props) => {

  
  const [collapseOpen, setCollapseOpen] = useState();
  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  // verifies if routeName is the one active (in browser input)
  // const activeRoute = (routeName) => {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  // toggles collapse between opened and closed (true/false)

  

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    let filteredRoutes=routes;
   let userModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/user");
   let communityModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/community");
   let capsuleModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/capsule");
   let questModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/static/questionary");
   let notifyModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/notify");
   let moodModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/master/mood");
   let journalTemplate = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/journal/templates");
   let roleModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/admin/role");
   let adminUserModule = permissionPath.find(permis=>permis.method=="GET"&&permis.path=="/api/v2.0/admin/user/allusers");


   if(adminRole!=="superAdmin"){

if(!userModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Manage Users");
}
if(!communityModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Community Management");
}
if(!capsuleModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Capsule Management");
}
if(!questModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Questionary Management");
}
if(!notifyModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Notification Management");
}
if(!moodModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Mood Management");
}
if(!journalTemplate){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Journal Template");
}
if(!roleModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="Role Management");
}
if(!adminUserModule){
  filteredRoutes=filteredRoutes.filter(rout=>rout.name!="System Users Management");
}


   }

    return filteredRoutes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD }
            // tag={Link }
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
         </NavItem>
      );
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
                
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
              style={{maxHeight:'inherit'}}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
                   
          
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
