import React, { useState, useEffect } from "react";

import { CardHeader, CardBody, Button, Card, Container } from "reactstrap";

import moment from "moment";
import { Link, useParams } from "react-router-dom";
import {
  getCapsulePostById,
  updateCapsuleContentById,
  getCapsuleContentById,
} from "../../redux/api/index";
import { ToastContainer, toast } from "react-toastify";
import ReactPlayer from "react-player";

import UserHeader from "components/Headers/UserHeader";
import AdminNavbar from "components/Navbars/AdminNavbar";

import {
  OutlinedInput,
  InputLabel,
  TextField,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const ViewCapsulePost = () => {
  const params = useParams();


  const [contentRecomm, setContentRecomm] = useState([]);
  const [moodList, setMoodList] = useState([]);
  
  const handleChangeRecom = (event) => {
    const {
      target: { value },
    } = event;

    setContentRecomm(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [edit, setEdit] = useState(false);

  const [post, setpost] = useState({
    title: "",
    description: "",
    media: "",
    newImage: "",
    thumbnail: "",
  });

  const [updatePost, setUpdatePost] = useState({
    title: "",
    description: "",
    media: "",
    thumbnail: "",
  });

  const handleChange = (e) => {
    // console.log(e.target.name,e.target.files[0]);
    setUpdatePost({ ...updatePost, [e.target.name]: e.target.value });
    if (e.target.files && e.target.name === "media") {
      setUpdatePost({ ...updatePost, media: e.target.files[0] });
    }

    if (e.target.files && e.target.name === "thumbnail") {
      setUpdatePost({ ...updatePost, thumbnail: e.target.files[0] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(contentRecomm)

    let formdata = new FormData();
    updatePost.title && formdata.append("title", updatePost.title);
    updatePost.description &&
      formdata.append("description", updatePost.description);
    updatePost.media && formdata.append("tool", updatePost.media);
    updatePost.thumbnail && formdata.append("thumbnail", updatePost.thumbnail);
    formdata.append("recommendation", JSON.stringify(contentRecomm));

    try {
      const { data } = await updateCapsuleContentById(
        params.capsuleId,
        params.subCategoryId,
        params.contentId,
        formdata
      );
      setpost({
        ...post,
        title: data.data.title,
        description: data.data.description,
        media: data.data.media,
        thumbnail: data.data.thumbnail,
      });
      setUpdatePost({
        ...updatePost,
        title: data.data.title,
        description: data.data.description,
        // media:data.data.media,
        // thumbnail:data.data.thumbnail
      });
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      setEdit(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
    }
  };

  const handleCancel = () => {
    setEdit(false);
    setUpdatePost({
      ...updatePost,
      title: post.title,
      description: post.description,
      media: post.media,
      thumbnail: post.thumbnail,
    });
  };

  const getCapsulePost = async () => {
    try {
      const { data } = await getCapsuleContentById(
        params.capsuleId,
        params.subCategoryId,
        params.contentId
      );

      // setpost({...post,title:data.data.title, description:data.data.description,media:data.data.media})
      setpost({
        ...post,
        title: data.data.title,
        description: data.data.description,
        media: data.data.media,
        thumbnail: data.data.thumbnail,
      });
      setUpdatePost({
        ...updatePost,
        title: data.data.title,
        description: data.data.description,
        // media:data.data.media,
        // thumbnail:data.data.thumbnail
      });

      setContentRecomm(data.data.recommendations);
      setMoodList(data.moodList);
    } catch (error) {
      console.log("error getting capsule post");
    }
  };

  useEffect(() => {
    getCapsulePost();
  }, []);

  return (
    <>
      <AdminNavbar brandText="Capsule Content" />
      <ToastContainer />
      <UserHeader />
      <Container className="mt--7" fluid>
        <Card>
          {/* <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0"> Capsule post Details </h3>
                <div>
                  <ul class="breadcrumb">
                    <li>Category Name</li>
                    <li>Sub Category</li>
                   
                  </ul>
                </div>
                </div>
                <div className="col-4 text-right"> 
                    <Link to={`/admin/capsule/${params.capsuleId}`} className="btn bg-gradient-danger btn-info usrdtls px-4">Back</Link>
                </div>
                </div>
            </CardHeader> */}

          <CardHeader>
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0 capdtl">Content Detail </h3>
              </div>
              <div className="col-4 text-right">
                <Link
                  to={`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}`}
                  className="btn btn-info  px-4 capwhite"
                >
                  Back
                </Link>
              </div>
            </div>
          </CardHeader>

          <CardBody className="edback">
            <div className="text-right mb-3">
              {edit && (
                <Button color="danger" size="sm" onClick={handleCancel}>
                  <i className="fa fa-times"></i> Cancel
                </Button>
              )}
              {edit ? (
                <Button color="success" size="sm" onClick={handleSubmit}>
                  <i className="fa fa-check"></i> Done
                </Button>
              ) : (
                <Button color="info" size="sm" onClick={() => setEdit(true)}>
                  <i className="fa fa-edit"></i> Edit
                </Button>
              )}
            </div>
            <div className="pl-lg-4">
              <div className="row">
                <div className="col-lg-4">
                  <label className="form-control-label">Media</label>
                  {post.media.type === "image" && (
                    <img
                      alt="placeholder"
                      src={`${process.env.REACT_APP_BASE_URL}${post.media?.content}`}
                      // src={`http://3.109.240.139:3001/media/post/thumbnail__1651063439285.png}`}
                      // style={{
                      //   height: '100%',
                      //   objectFit:'contain'
                      // }}
                      width="100%"
                    />
                  )}
                  {
                    // post.media.type==='video'&&
                    <ReactPlayer
                      controls
                      width="100%"
                      height="220px"
                      url={`${process.env.REACT_APP_BASE_URL}${post.media?.content}`}
                      // url={`http://3.109.240.139:3001/media/post/post_capsule_1651063428162.mp4`}
                    />
                  }
                  {post.media.type === "audio" && (
                    <ReactPlayer
                      controls
                      width="100%"
                      height="120px"
                      url={`${process.env.REACT_APP_BASE_URL}${post.media?.content}`}
                    />
                  )}
                  {edit && (
                    <div className="custom-file mt-2">
                      <input
                        type="file"
                        name="media"
                        className="custom-file-input"
                        onChange={handleChange}
                      />
                      <label className="custom-file-label">
                        {updatePost?.media?.name
                          ? updatePost.media.name
                          : "Choose file..."}
                      </label>
                    </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <label className="form-control-label text-center">
                    Thumbnail
                  </label>
                  <img
                    alt="placeholder"
                    style={{
                      maxHeight: "220px",
                      objectFit: "contain",
                    }}
                    src={`${process.env.REACT_APP_BASE_URL}${post.media?.thumbnail}`}
                    // src={`http://3.109.240.139:3001/media/post/thumbnail__1651063439285.png`}

                    width="100%"
                  />

                  {edit && (
                    <div className="custom-file mt-2">
                      <input
                        type="file"
                        name="thumbnail"
                        className="custom-file-input"
                        onChange={handleChange}
                      />
                      <label className="custom-file-label">
                        {updatePost?.thumbnail?.name
                          ? updatePost.thumbnail.name
                          : "Choose file..."}
                      </label>
                    </div>
                  )}
                </div>
                <div className="col-lg-12">
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">
                          Content Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={updatePost.title}
                          onChange={handleChange}
                          disabled={!edit}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">
                          Content Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="description"
                          value={updatePost.description}
                          onChange={handleChange}
                          disabled={!edit}
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">Content Create on</label>
                        <input type="text" className="form-control" value={moment(post.createdAt).format("MMM Do YY")} disabled/>
                       </div>
                      </div> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <FormControl sx={{ m: 1, }} 
                  fullWidth
                  size="small"
                  disabled={!edit}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Recommendation
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      
                      multiple
                      value={contentRecomm}
                      onChange={handleChangeRecom}
                      input={<OutlinedInput label="Recommendation" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      
                      {moodList.map((name) => (
                        <MenuItem
                        size="small"
                         key={name} value={name}>
                          <Checkbox
                          size="small"
                           checked={contentRecomm.indexOf(name) > -1} />
                          <ListItemText 
                          size="small"
                          primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ViewCapsulePost;
