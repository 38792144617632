
import Index from "views/Index.js";
//import Register from "views/examples/Register.js";
import User from 'views/user/User';
import Community from 'views/community/Community';
import Capsule from 'views/capsules/Capsule';
import Notification from "views/notification/Notification";
import Discussion from "views/discussion/Discussion";
import Static from "views/static/Static";
import Profile from "views/profile/Profile";
import Login from "views/login/Login";
import Resetpassword from "views/login/Resetpassword"
import Signup from "views/login/Signup"
import ForgotPassword from "views/forgot-password/ForgotPassword";
import Otp from "views/otp/Otp";

import UserView from "views/user/UserView";
import CommunityView from "views/community/CommunityView"
import CapsuleView from "views/capsules/CapsuleView";
import AddCommunity from "views/community/AddCommunity";
import AddCapsule from "views/capsules/AddCapsule";
import EditCapsule from "views/capsules/EditCapsule";
import AddCapsulePost from "views/capsules/AddCapsulePost";
import AddCapsuleSubcat from "views/capsules/AddCapsuleSubcat";
import ViewCapsulePost from "./views/capsules/ViewCapsulePost";
import CapsuleSubcatView from "views/capsules/CapsuleSubcatView";
import ViewDiscussion from "./views/discussion/ViewDiscussion";
import About from './views/static/About';
import Privacy from './views/static/Privacy';
import Terms from './views/static/Terms';
import WelcomeSlider from './views/static/WelcomeSlider';
import ViewUserJournal from "./views/user/ViewUserJournal";
import Moodmanagement from "views/moodmanage/Moodmanagement";
import Journaltemplate from "views/journaltemplate/Journaltemplate";
// import Questionnaire from "views/questionnaire/Questionnaire";
import Questionarymanagement from "views/questionnaire/questionaryList";
import AddQuestionary from "views/questionnaire/AddQuestionary"
import EditQuestionary from "views/questionnaire/edit-questionary"
import ViewQuestionary from "views/questionnaire/view-questionary"
import Toolmanagement from "views/ToolManagement/Toolmanagement";
import Addrole from "views/rolmanage/Addrole";
import Editrole from "views/rolmanage/Editrole";
import Rolemanagement from "views/rolmanage/Rolemanagement";
 import Analyticsreports from "views/analyticsreports/Analyticsreports"
 import Adminmanagement from "views/adminmanagement/Adminmanagement";
 import Wiewrole from "views/rolmanage/Wiewrole";
import AddTool from "views/ToolManagement/AddTool";
import EditTool from "views/ToolManagement/EditTool";
import AddSystemUser from "views/adminmanagement/AddSystemUser";
import ViewSystemUser from "views/adminmanagement/ViewSystemUser";

import Addmood from "views/moodmanage/addMood";
import Editmood from "views/moodmanage/editMood";
import ViewMood from "views/moodmanage/viewMood";

import Contentmanagement from "views/content/contentManagement";
import Addcontent from "views/content/add-content";
import Editcontent from "views/content/edit-content";
import Viewcontent from "views/content/view-content";



export const adminRoutes = [
  // {
  //   path: "/admin",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    // icon: "ni ni-tv-2 text-primary",
    icon: "fa fa-television text-purple",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/customer-management",
    name: "Manage Users",
    icon: "fa fa-user text-purple",
    component: User,
    layout: "/admin",
  }, 
  {
    path: "/capsule-management",
    name: "Capsule Management",
    icon: "fa fa-solid fa-capsules",
    component: Capsule,
    layout: "/admin",
  }, 
  {
    path: "/community",
    name: "Community Management",
    icon: "fa fa-users  text-success",
    component: Community,
    layout: "/admin",
  },
  {
    path: "/discussion",
    name: "Post Management",
    icon: "fa fa-picture-o aria-hidden=true",
    component: Discussion,
    layout: "/admin",
  },
  {
    path: "/questionary-management",
    name: "Questionary Management",
    icon: "fa fa-question-circle",
    component: Questionarymanagement,
    layout: "/admin",
  },
  {
    path: "/mood-management",
    name: "Mood Management",
    icon: "fa fa-smile-o",
    component: Moodmanagement,
    layout: "/admin",
  },
  {
    path: "/journal-template-managemet",
    name: "Journal Template",
    icon: "far fa-edit",
    component: Journaltemplate,
    layout: "/admin",
  },
  {
    path: "/analyticsreports",
    name: "Analytics & Report",
    icon: "fa fa-bar-chart",
    component: Analyticsreports,
    layout: "/admin",
  },
  {
    path: "/admin-management",
    name: "System Users Management",
    icon: "fa fa-user text-purple",
    component: Adminmanagement,
    layout: "/admin",
  }, 
  {
    path: "/role-management",
    name: "Role Management",
    icon: "fas fa-user-cog",
    component: Rolemanagement,
    layout: "/admin",
  },
  
  // {
  //   path: "/tool-management",
  //   name: "Tool Management",
  //   icon: "fas fa-tools",
  //   component: Toolmanagement,
  //   layout: "/admin",
  // },
  // {
  //   path: "/content-management",
  //   name: "Content Management",
  //   icon: "fa fa-question-circle",
  //   component: Contentmanagement,
  //   layout: "/admin",
  // },
   
 

  // {
  //   path: "/User-management",
  //   name: "User Management",
  //   icon: "far fa-user-circle",
  //   component: Notification,
  //   layout: "/admin",
  // },
  
 
  
 
 

  
  // {
  //   path: "/user-management",
  //   name: "Role Management",
  //   icon: "fa fa-user text-purple",
  //   component: User,
  //   layout: "/admin",
  // },
  /*
  {
     path: "/user-management",
     name: "Notification Management",
     icon: "fa fa-user text-purple",
     component: User,
   layout: "/admin",
   },*/

 
  {
    path: "/notification-management",
    name: "Notification Management",
    icon: "fa fa-bell text-blue",
    component: Notification,
    layout: "/admin",
  },
  {
    path: "/static",
    name: "Content Management",
    icon: "fa fa-bell text-blue",
    component: Static,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile Settings",
    icon: "fa fa-cog text-default",
    component: Profile,
    layout: "/admin",
  }
  
 
]

export const pages=[
  {
    path: "/add-tool",
    component: AddTool,
    layout: "/admin",
  },
  {
    path: "/add-content",
    component: Addcontent,
    layout: "/admin",
  },
  {
    path: "/edit-content",
    component: Editcontent,
    layout: "/admin",
  },
  {
    path: "/view-content",
    component: Viewcontent,
    layout: "/admin",
  },
  {
    path: "/mood-management/addmood",
    component: Addmood,
    layout: "/admin",
  },
  {
    path: "/admin-management/add-user",
    component: AddSystemUser,
    layout: "/admin",
  },
  {
    path: "/admin-management/:adminUserId",
    component: ViewSystemUser,
    layout: "/admin",
  },
  {
    path: "/mood-management/editmood",
    component: Editmood,
    layout: "/admin",
  },
  {
    path: "/mood-management/:moodId",
    component: ViewMood,
    layout: "/admin",
  },
  {
    path: "/questionary-management/add-questionary",
    name: "Tool Management",
    icon: "fas fa-tools",
    component: AddQuestionary,
    layout: "/admin",
  },
  {
    path: "/questionary-management/edit-questionary/:questionaryId",
    component: EditQuestionary,
    layout: "/admin",
  },
  {
    path: "/questionary-management/view-questionary/:questionaryId",
    component: ViewQuestionary,
    layout: "/admin",
  },
  {
    path: "/edit-tool",
    name: "Tool Management",
    icon: "fas fa-tools",
    component: EditTool,
    layout: "/admin",
  },
    {
      path :"/customer-management/:userId",
      name: "View User",
      icon: "fa fa-sign-out-alt text-danger",
      component: UserView,
      layout: "/admin",
    },
    {
      path :"/edit-capsule",
      name: "Edit Capsule",
      icon: "fa fa-sign-out-alt text-danger",
      component: EditCapsule,
      layout: "/admin",
    },
     
    {
      path :"/role-management/role/:roleId",
      name: "Virw Role",
      icon: "fa fa-sign-out-alt text-danger",
      component: Wiewrole,
      layout: "/admin",
    },
    {
      path :"/community/:communityId",
      name: "View Community",
      icon: "fa fa-sign-out-alt text-danger",
      component: CommunityView,
      layout: "/admin",
    },
    {
      path: "/role-management/addrole",
      name: "Add Role",
      icon: "fa fa-arrow-circle-right  text-orange",
      component: Addrole,
      layout: "/admin",
    },
    {
      path :"/capsule-management/:capsuleId",
      name: "Capsule Detail",
      icon: "fa fa-sign-out-alt text-danger",
      component: CapsuleView,
      layout: "/admin",
    },
    {
      path :"/add-community",
      name: "Add Community",
      icon: "fa fa-sign-out-alt text-danger",
      component: AddCommunity,
      layout: "/admin",
    },
    {
      path :"/add-capsule",
      name: "Add Capsule",
      icon: "fa fa-sign-out-alt text-danger",
      component: AddCapsule,
      layout: "/admin",
    },
   
    {
      path :"/capsule-management/:capsuleId/add-post",
      name: "Add Capsule Post",
      icon: "fa fa-sign-out-alt text-danger",
      component: AddCapsulePost,
      layout: "/admin",
    },
    {
      path :"/capsule-management/:capsuleId/add-subcategory",
      name: "Add Subcategory",
      icon: "fa fa-sign-out-alt text-danger",
      component: AddCapsuleSubcat,
      layout: "/admin",
    },
    {
      path :"/capsule-management/:capsuleId/tools/:subCategoryId",
      name: "Add Capsule Post",
      icon: "fa fa-sign-out-alt text-danger",
      component: CapsuleSubcatView,
      layout: "/admin",
    },
    {
      path :"/capsule-management/:capsuleId/tools/:subCategoryId/add-content",
      name: "Add Capsule Content",
      icon: "fa fa-sign-out-alt text-danger",
      component: AddCapsulePost,
      layout: "/admin",
    },
    {
      path :"/capsule-management/:capsuleId/tools/:subCategoryId/content/:contentId",
      name: "Add Capsule Content",
      icon: "fa fa-sign-out-alt text-danger",
      component: ViewCapsulePost,
      layout: "/admin",
    },
    {
      path :"/discussion/:communityId/:postId",
      name: "View Discussion",
      icon: "fa fa-sign-out-alt text-danger",
      component: ViewDiscussion,
      layout: "/admin",
    },
    {
      path :"/static/about",
      name: "About",
      icon: "fa fa-sign-out-alt text-danger",
      component: About,
      layout: "/admin",
    },
    {
      path :"/static/privacy",
      name: "Privacy",
      icon: "fa fa-sign-out-alt text-danger",
      component: Privacy,
      layout: "/admin",
    },
    {
      path :"/static/terms",
      name: "Terms",
      icon: "fa fa-sign-out-alt text-danger",
      component: Terms,
      layout: "/admin",
    },
    {
      path :"/static/welcome",
      name: "Welcome Slider",
      icon: "fa fa-sign-out-alt text-danger",
      component: WelcomeSlider,
      layout: "/admin",
    },
    {
      path :"/journal/:journalId",
      name: "User Journal",
      icon: "fa fa-sign-out-alt text-danger",
      component: ViewUserJournal,
      layout: "/admin",
    },
    {
      path :"/role-management/editrole",
      name: "User Journal",
      icon: "fa fa-sign-out-alt text-danger",
      component: Editrole,
      layout: "/admin",
    },
    
  {
    path: "/role-management/Editrole",
    name: "Edit Role",
    icon: "fa fa-arrow-circle-right  text-orange",
    component: Editrole,
    layout: "/admin",
  }
]

export const authRoutes=[
    {
      path :"/login",
      name: "Login",
      icon: "fa fa-sign-out-alt text-danger",
      component: Login,
      layout: "/auth",
    },
    {
      path :"/signup",
      name: "Sign up",
      icon: "fa fa-sign-out-alt text-danger",
      component: Signup,
      layout: "/auth",
    },
    // {
    //   path :"/signup",
    //   name: "Sign up",
    //   icon: "fa fa-sign-out-alt text-danger",
    //   component: Signup,
    //   layout: "/auth",
    // },
    {
      path :"/forgot-password",
      name: "Forgot Password",
      icon: "fa fa-sign-out-alt text-danger",
      component: ForgotPassword,
      layout: "/auth",
    },
    {
      path :"/verify-otp",
      name: "Verify OTP",
      icon: "fa fa-sign-out-alt text-danger",
      component: Otp,
      layout: "/auth",
    },
    {
      path :"/reset-password",
      name: "Reset Password",
      icon: "fa fa-sign-out-alt text-danger",
      component: Resetpassword,
      layout: "/auth",
    },
    {
      path :"/logout",
      name: "Logout",
      icon: "fa fa-sign-out-alt text-danger",
      component: Login,
      layout: "/auth",
    }
]
