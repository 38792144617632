import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import ImogiImage from '../../assets/img/brand/emoji.png'
import sad from '../../assets/img/brand/sad.png'
import smile from '../../assets/img/brand/smile.png'
import {fetchAdminRoleList} from '../../redux/actions/adminAction';
import { useDispatch,useSelector } from 'react-redux';


const Rolemanagement = () => {
  const dispatch = useDispatch();
  const roleList = useSelector(state=>state.adminUser.role);


  useEffect(()=>{
    dispatch(fetchAdminRoleList())
  },[])

  return (
    <>
  <div className='second_header12' >
    <AdminNavbar  brandText="Role Management" />
    

    </div>

    <div className="mod_box moodmange">

      <div className='container'>
         <div className='row'>

              <div className='col-md-6'>
                  <div className=''> <h3 className="text-left mod_testi comunity_manage">Role List</h3></div>
              </div>
              <div className='col-md-6'>        
                    <div class="form-actions filter-btn mangrol">
                    <Link to="role-management/addrole">
                      <button type="button" class="btn btn-info mr-1 adrol">
                            Add Role</button></Link> 
                    </div> 
                            
              </div>

         </div>
      </div>
  
       
        <table class="table mood_table">
              <thead class="thead-light">
                <tr>
                  <th>S. No</th>
                  <th>Role Name</th>
                  {/* <th>Assign to</th> */}
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  roleList.map((role,index)=> (
                <tr key={role._id} >
                <td>{index+1}</td>
                  <td>{role.roleName} </td>
                  {/* <td>Ram</td>  */}
                  <td> <label className='text-green'>
                    {role.roleName?"Active":"InActive"}
                    </label></td>               
                    <td>
                       <div>
                        <Link type="button" class="btn text-purple " to={`/admin/role-management/role/${role._id}`}>
                          <i class="fa fa-eye"></i></Link>
                        {/* <div type="button" class="btn text-red"><i class="fa fa-lock"></i>
                        </div> */}
                        {/* <div type="button" class="btn text-red"> <a href="/admin/role-management/Editrole"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a></div> */}
                        {/* <div type="button" class="btn text-red"><i class="fa fa-trash"></i></div> */}
                        </div>
                  </td>
                </tr>

                  ) )
                }
                
               
             
              </tbody>
         </table>
      </div>
      
     
      </>
      
  )
}

export default Rolemanagement