import React,{useState} from 'react'
import {  Container} from "reactstrap";


const CommunityHeader = ({setFilter}) => {

  const [value, setValue] = useState({
    fromDate:'',toDate:'',active:'',username:''
  })

  const handleChange=(e)=>{
    e.preventDefault();
    setValue({...value,[e.target.name]:e.target.value});
   }

const handleSubmit=(e)=>{
    e.preventDefault();
   setFilter("title",value.username);
   setFilter('approved',value.active)
   let dateFilter=[];
   const sd1 = new Date(value.fromDate).setHours(0,0,0);
    const sd = new Date(sd1);
    const ed1 = new Date(value.toDate).setHours(23,59,0);
    const ed = new Date(ed1);
   if(value.fromDate&&value.toDate){
    dateFilter[0]=sd;
    dateFilter[1]=ed;

   }
   setFilter('createdAt',dateFilter)
  }

  const handleCancel=(e)=>{
    e.preventDefault();

    setValue({fromDate:'',toDate:'',active:'',username:''})
    setFilter("title",'');
   setFilter('createdAt','')
   setFilter('approved','') 
  }


    return (
        <>

    <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">


            <form action="#">
                  <div className="form-body">
                    <div className="row p-t-20 text-white">
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">From Date</label>
                          <input type="date"
                          className="form-control"
                            name='fromDate'
                            max={value.toDate}
                           onChange={handleChange}
                           value={value.fromDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">To Date</label>
                          <input type="date"
                            name="toDate" 
                            min={value.fromDate}
                            value={value.toDate}
                            onChange={handleChange} 
                            className="form-control  "/>
                        </div>
                      </div>
                        <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Approval Status</label>
                            <select className="form-control" name="active" value={value.active} onChange={handleChange}>
                            <option value="">Select Status</option>
                            <option value={true} >Approved</option>
                            <option value={false}>Unapproved</option>
                            </select>
                            </div>
                        </div>

                        <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Community Name</label>
                            <input type="text"
                            name='username' 
                            value={value.username}
                            onChange={handleChange}
                            className="form-control  " placeholder="Enter community Name"/>
                        </div>
                        </div>

                       
                        
                        
                        <div className="col-md-3">
                        <div className="form-actions filter-btn">
                          <button type="button" className="btn btn-info w-100 mr-1" onClick={handleCancel}>Reset</button>
                          <button type="submit" className="btn difrnt w-100 ml-1" onClick={handleSubmit} >Search</button>
                        </div>
                       </div>
                    </div>
                  </div> 
                </form>


                </div>
        </Container>
      </div>
        </>
    )
}

export default CommunityHeader
