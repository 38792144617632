import React,{useState} from "react";

import {
  Table,
  Modal,ModalBody,ModalFooter,ModalHeader,Button,Media
} from "reactstrap";

  import moment from "moment";
  import {toast, ToastContainer} from 'react-toastify';
  import { Link,useParams } from "react-router-dom";
  import { removeUserFromCommunity } from "redux/api";

const UserCommunity = ({community,user,setUser}) => {
  const params = useParams();

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();
  
  
  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }

  const handleDelete=async()=>{
    try {
      const {data} = await removeUserFromCommunity(confirmId,params.userId);
      const newComm = community.filter(elem=>elem._id!=confirmId);
      setUser({...user,joinedCommunity:newComm})
      toast.success(data.message,{
        autoClose: 1500,
        hideProgressBar: true
    })
    setConfirm(false);
    } catch (error) {
      toast.error(error.response.data.message,{
        autoClose: 1500,
        hideProgressBar: true
    }) 
    setConfirm(false);
    }

  }

  return (
    <>
    <ToastContainer/>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Community Name</th>
            <th scope="col">Created on</th>
            <th scope="col" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {community&&community.map((elem) => (
            <tr key={elem._id}>
              <th scope="row">
                <Media
                  className="align-items-center"
                  style={{ height: "80px", width: "80px" }}
                >
                  <img
                    alt="..."
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    className="rounded-circle fluid"
                   src={`${process.env.REACT_APP_BASE_URL}${elem.image}`}
                  />
                </Media>
              </th>
              <td>{elem.title}</td>
              <td>{moment(elem.createdAt).format("MMM Do YY")}</td>
              <td className="d-flex justify-content-center">                
              <Link 
                  to={{pathname:`/admin/community/${elem._id}`,
                      userid:elem._id
                      }} 
                  type="button" 
                  className="btn text-purple ">
                  <i className="fa fa-eye"></i>
                </Link>
                <div
                 
                  className="btn text-red"
                 onClick={()=>deletePopup(elem._id)}
                >
                  <i className="fa fa-trash"></i>
                </div>
              </td>
            </tr>
          ))} 
        </tbody>
        <Modal isOpen={confirm} >
              <ModalHeader >Confirm remove from community ?</ModalHeader>
              <ModalBody>
                Are you sure you want to remove user from community ?
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
              </ModalFooter>
          </Modal>
      
      </Table>
    </>
  );
};

export default UserCommunity;
