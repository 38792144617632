import React,{useState,useEffect} from 'react';

import { Card, CardBody, CardHeader, Container,Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import UserHeader from 'components/Headers/UserHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { getWelcomeSlider, updateWelcomeSlider } from '../../redux/api/index';

const WelcomeSlider = () => {
    const [edit1, setEdit1] = useState(false);
    const [edit2, setEdit2] = useState(false);
    const [edit3, setEdit3] = useState(false);
    
const [welcome, setWelcome] = useState([]);

const [update1, setUpdate1] = useState({
    title:'',content:'',image:'',active:''
});
const [update2, setUpdate2] = useState({
    title:'',content:'',image:'',active:''
});
const [update3, setUpdate3] = useState({
    title:'',content:'',image:'',active:''
});


const handleChange1=(e)=>{
    setUpdate1({...update1,[e.target.name]:e.target.value});
    if(e.target.files){           
        setUpdate1({...update1,image:e.target.files[0]});
    } 
  }
const handleChange2=(e)=>{
    setUpdate2({...update2,[e.target.name]:e.target.value});
    if(e.target.files){           
        setUpdate2({...update2,image:e.target.files[0]});
    } 
  }
const handleChange3=(e)=>{
    setUpdate3({...update3,[e.target.name]:e.target.value});
    if(e.target.files){           
        setUpdate3({...update3,image:e.target.files[0]});
    } 
  }


  const handleSubmit1=async(e)=>{
      e.preventDefault();  
  let formdata1 = new FormData();
  update1.title&&formdata1.append('title',update1.title);
  update1.content&&formdata1.append('content',update1.content);
  update1.image&&formdata1.append('slider',update1.image);
  update1.active&&formdata1.append('status',update1.active);

      try {
          const {data} = await updateWelcomeSlider(formdata1,welcome[0]._id);
          getWelcomeData();
      } catch (error) {
          console.log("error updating about")
      }
       setEdit1(false);

  }
const handleCancel1=()=>{
    setEdit1(false);
    setUpdate1({...update1,title:'',content:'',image:'',active:''})
}
  const handleSubmit2=async(e)=>{
      e.preventDefault();  
  let formdata2 = new FormData();
  update2.title&&formdata2.append('title',update2.title);
  update2.content&&formdata2.append('content',update2.content);
  update2.image&&formdata2.append('slider',update2.image);
  update2.active&&formdata2.append('status',update2.active);

      try {
          const {data} = await updateWelcomeSlider(formdata2,welcome[1]._id);
          getWelcomeData();
      } catch (error) {
          console.log("error updating about")
      }
       setEdit2(false);

  }
  const handleSubmit3=async(e)=>{
      e.preventDefault(); 
  let formdata3 = new FormData();
  update3.title&&formdata3.append('title',update3.title);
  update3.content&&formdata3.append('content',update3.content);
  update3.image&&formdata3.append('slider',update3.image);
  update3.active&&formdata3.append('status',update3.active);

      try {
          await updateWelcomeSlider(formdata3,welcome[2]._id);
          getWelcomeData();
      } catch (error) {
          console.log("error updating about")
      }
       setEdit3(false);

  }
const handleCancel2=()=>{
    setEdit2(false);
    setUpdate2({...update2,title:'',content:'',image:'',active:''})
}
const handleCancel3=()=>{
    setEdit3(false);
    setUpdate3({...update3,title:'',content:'',image:'',active:''})
}


const getWelcomeData=async()=>{
    try {
        const {data} = await getWelcomeSlider();
        setWelcome(data.data);
          
    } catch (error) {
        console.log("error getting about data");
    }
}

useEffect(()=>{
    getWelcomeData();
},[])


return (
    <>
    <AdminNavbar brandText="welcome slider" />
    <UserHeader/>

    <Container className="mt--7" fluid>
        <Card>
            <CardHeader>
                <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0">Edit Welcome Slider</h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/static" className="btn bg-gradient-danger text-white px-4">Back</Link>
                </div>
                </div>
            </CardHeader>
            <CardBody>
            {/* <div className="text-right mb-3" >
                {edit&&< Button color="danger" size='sm' onClick={handleCancel}  >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                }
                {edit?
                    <Button color="success" size='sm' onClick={handleSubmit} >
                            <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="danger" size='sm' onClick={()=>setEdit(true)} >
                            <i className="fa fa-edit"></i> Edit
                    </Button>
                    }
                </div> */}
    {/* {welcome&&  */}
    <>
          <form>
            <div className="pl-lg-4">
                <div className="text-right mb-3" >
                      {edit1&&< Button color="danger" size='sm' onClick={handleCancel1}  >
                                  <i className="fa fa-times"></i> Cancel
                          </Button>
                      }
                      {edit1?
                          <Button color="success" size='sm' onClick={handleSubmit1} >
                                  <i className="fa fa-check"></i> Done
                          </Button>
                          :
                          <Button color="danger" size='sm' onClick={()=>setEdit1(true)} >
                                  <i className="fa fa-edit"></i> Edit
                          </Button>
                          }
                      </div>
              <div className="row">

            <div className="col-lg-8">
              <div className="form-group">
                <label className="form-control-label" >Welcome Slider 1</label>
                <input 
                type="text" 
                name="title" 
                className="form-control"
                disabled={!edit1}
                value={update1.title?update1.title:welcome[0]?.title}
                onChange={handleChange1}
                />
              </div>
                    <label className="control-label">Status</label>
                    <select className="form-control" name="active" value={update1.active?update1.active:welcome[0]?.active}  onChange={handleChange1} disabled={!edit1}>
                    <option
                      value=""
                      >Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false} >In-Active</option>
                    </select>
              
            </div>
            <div className="col-lg-4">
              <img alt="placeholder" 
              src={`${process.env.REACT_APP_BASE_URL}${welcome[0]?.image}`} 
              width="100%"/>
              {edit1&&
            <div className="custom-file mt-2">
                    <input type="file" name="about"
                    className="custom-file-input" accept="image/*"
                    onChange={handleChange1}
                     />
                    <label className="custom-file-label" >{update1.image?update1.image.name:'Choose file...'}</label>
                </div>
              }
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-control-label">Content</label>
                <textarea 
                name="content" cols="90" rows="10" 
                className="rounded w-100 p-2"
                disabled={!edit1}
                value={update1.content?update1.content:welcome[0]?.content}
                onChange={handleChange1}
                ></textarea>
              </div>
            </div>
          </div>
          
        </div>
      </form>
        <form>
            <div className="pl-lg-4">
                <div className="text-right mb-3" >
                      {edit2&&< Button color="danger" size='sm' onClick={handleCancel2}  >
                                  <i className="fa fa-times"></i> Cancel
                          </Button>
                      }
                      {edit2?
                          <Button color="success" size='sm' onClick={handleSubmit2} >
                                  <i className="fa fa-check"></i> Done
                          </Button>
                          :
                          <Button color="danger" size='sm' onClick={()=>setEdit2(true)} >
                                  <i className="fa fa-edit"></i> Edit
                          </Button>
                          }
                      </div>
              <div className="row">

            <div className="col-lg-8">
              <div className="form-group">
                <label className="form-control-label" >Welcome Slider 2</label>
                <input 
                type="text" 
                name="title" 
                className="form-control"
                disabled={!edit2}
                value={update2.title?update2.title:welcome[1]?.title}
                onChange={handleChange2}
                />
              </div>
                  <label className="control-label">Status</label>
                    <select className="form-control" name="active" value={update2.active?update2?.active:welcome[1]?.active}  onChange={handleChange2} disabled={!edit2}>
                    <option
                      value=""
                      >Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false} >In-Active</option>
                    </select>
            </div>
            <div className="col-lg-4">
              <img alt="placeholder" 
              src={`${process.env.REACT_APP_BASE_URL}${welcome[1]?.image}`} 
              width="100%"/>
              {edit2&&
            <div className="custom-file mt-2">
                    <input type="file" name="about"
                    className="custom-file-input" accept="image/*"
                    onChange={handleChange2}
                     />
                    <label className="custom-file-label" >{update2.image?update2.image.name:'Choose file...'}</label>
                </div>
              }
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-control-label">Content</label>
                <textarea 
                name="content" cols="90" rows="10" 
                className="rounded w-100 p-2"
                disabled={!edit2}
                value={update2.content?update2.content:welcome[1]?.content}
                onChange={handleChange2}
                ></textarea>
              </div>
            </div>
          </div>
          
        </div>
      </form>
        <form>
            <div className="pl-lg-4">
                <div className="text-right mb-3" >
                      {edit3&&< Button color="danger" size='sm' onClick={handleCancel3}  >
                                  <i className="fa fa-times"></i> Cancel
                          </Button>
                      }
                      {edit3?
                          <Button color="success" size='sm' onClick={handleSubmit3} >
                                  <i className="fa fa-check"></i> Done
                          </Button>
                          :
                          <Button color="danger" size='sm' onClick={()=>setEdit3(true)} >
                                  <i className="fa fa-edit"></i> Edit
                          </Button>
                          }
                      </div>
              <div className="row">

            <div className="col-lg-8">
              <div className="form-group">
                <label className="form-control-label" >Welcome Slider 3</label>
                <input 
                type="text" 
                name="title" 
                className="form-control"
                disabled={!edit3}
                value={update3.title?update3.title:welcome[2]?.title}
                onChange={handleChange3}
                />
              </div>
              <label className="control-label">Status</label>
                    <select className="form-control" name="active" value={update3.active?update3.active:welcome[2]?.active}  onChange={handleChange3} disabled={!edit3}>
                    <option
                      value=""
                      >Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false} >In-Active</option>
                    </select>
            </div>
            <div className="col-lg-4">
              <img alt="placeholder" 
              src={`${process.env.REACT_APP_BASE_URL}${welcome[2]?.image}`} 
              width="100%"/>
              {edit3&&
            <div className="custom-file mt-2">
                    <input type="file" name="about"
                    className="custom-file-input" accept="image/*"
                    onChange={handleChange3}
                     />
                    <label className="custom-file-label" >{update3.image?update3.image.name:'Choose file...'}</label>
                </div>
              }
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-control-label">Content</label>
                <textarea 
                name="content" cols="90" rows="10" 
                className="rounded w-100 p-2"
                disabled={!edit3}
                value={update3.content?update3.content:welcome[2]?.content}
                onChange={handleChange3}
                ></textarea>
              </div>
            </div>
          </div>
          
        </div>
      </form>
      </>

{/* } */}
                
            </CardBody>

        </Card>

    </Container>
                    
        </>
    )
}

export default WelcomeSlider
