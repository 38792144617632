import React,{useState,useEffect} from 'react';
import { Redirect, Route,useHistory} from 'react-router-dom';

import AdminLayout from 'layouts/Admin';
import {getMe} from './redux/api/index';
import { useDispatch,useSelector } from 'react-redux';
import { GET_ME } from 'redux/constants/userConstants';

const Auth = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isAuth, setisAuth] = useState(true);
    const loggedUser = useSelector(state=>state.auth.info.email);

    const checkUser=async()=>{
       
        try {
            const {data} = await getMe();
                setisAuth(true);
                dispatch({type:GET_ME,payload:data});            
        } catch (error) {
            console.log(error.response.status)
            setisAuth(false);
            history.push('/auth/login');
        }
    }


    useEffect(()=>{
        if(!loggedUser){
            checkUser();
        }
    },[])


    if(isAuth){
        return (
            <>
                <Route  render={(props)=> isAuth?<AdminLayout {...props} />:<Redirect to="/auth/login" />} />
            </>
        )

    } else{
        return (

            <Redirect to="/auth/login" />
        )
    }

}

export default Auth
