import React,{useState,useEffect} from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link,useParams } from 'react-router-dom';
import ImogiImage from '../../assets/img/brand/emoji.png'
import {getMoodById,updateMoodById} from '../../redux/api';
import { Button } from 'reactstrap';
import { toast,ToastContainer } from 'react-toastify';
import {Form } from 'reactstrap';
import { useSelector } from 'react-redux';


// <AdminNavbar brandText="Add Role" />
const Viewmood = () => {

  const params = useParams();
  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const updatePerm = permissionPath.find(perm=>perm.method=="PATCH"&&perm.path=="/api/v2.0/master/mood/:moodId")

  const [edit, setEdit] = useState(false);
  const [moodDetail, setMoodDetail] = useState({
    name:"",icon:""
  })
  const [updatedMood, setUpdatedMood] = useState({
    name:"",icon:""
  })

  const fetchMoodDetail = async()=>{
    try {
      const {data} = await getMoodById(params.moodId);
      setMoodDetail({
        ...moodDetail,
        name:data.data.name,
        icon:data.data.icon
      })
      setUpdatedMood({
        ...moodDetail,
        name:data.data.name,
        icon:data.data.icon
      })
    } catch (error) {
      console.log("mood detail fetch error")
    }
  }

  const handleSubmit =async(e)=>{
    
    e.preventDefault();

    let formdata = new FormData();
    updatedMood.name&&formdata.append('name',updatedMood.name);
    updatedMood.icon&&formdata.append('icon',updatedMood.icon);
   
    
    try {
      const {data} = await updateMoodById(params.moodId,formdata);
      
          toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        setUpdatedMood({
          ...updatedMood,
          name:data.data.name,
          icon:data.data.icon
        })
        setMoodDetail({
          ...updatedMood,
          name:data.data.name,
          icon:data.data.icon
        })

      setEdit(false);
    } catch (error) {
      console.log("error updatig capsule")
    }
  
  }

  const handleCancel=()=>{
    setEdit(false);
    setUpdatedMood({
      ...updatedMood,
      name:moodDetail.name,
      icon:""
    })
   
  }

  useEffect(()=>{
    fetchMoodDetail();
  },[])

  return (
    <>
      <ToastContainer />
      <div className='second_header12' >
    <AdminNavbar  brandText="View Mood" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="container">

<Form onSubmit={handleSubmit} >

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-10'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>Mood Details</h3>
                                      </div> 
                                </div>
                                <div className="m-auto col-md-2">
                                        <Link to="/admin/mood-management" type="button" class="btn btn-info">Back</Link>
                                        {/* <button type="button" class="btn difrnt">Update</button> */}
                                      </div>
                                {/* <div className='col-md-6'></div>                     */}
                            </div> 

                         <div className='try_whole'>


                           <div className="row try_whole">
                            {
                              adminRole=="superAdmin"?
                              <div className="col-md-12">
          
                              <div className="text-right mt-3" >
                       {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                                 <i className="fa fa-times"></i> Cancel
                         </Button>
                       }
                       {edit?
                         <Button color="success" size='sm'
                         type={edit?"submit":"button"}
                         // onClick={handleSubmit} 
                         >
                                   <i className="fa fa-check"></i> Done
                         </Button>
                         :
                         <Button color="info" size='sm' 
                         onClick={(e)=>{
                           e.preventDefault();
                           e.stopPropagation();
                           setEdit(true)}
                         } >
                                   <i className="fa fa-edit"></i> Edit
                         </Button>
                           }
                   </div>
               </div>:updatePerm?  <div className="col-md-12">
          
          <div className="text-right mt-3" >
   {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
             <i className="fa fa-times"></i> Cancel
     </Button>
   }
   {edit?
     <Button color="success" size='sm'
     type={edit?"submit":"button"}
     // onClick={handleSubmit} 
     >
               <i className="fa fa-check"></i> Done
     </Button>
     :
     <Button color="info" size='sm' 
     onClick={(e)=>{
       e.preventDefault();
       e.stopPropagation();
       setEdit(true)}
     } >
               <i className="fa fa-edit"></i> Edit
     </Button>
       }
</div>
</div>:null
                            }
          {/* <div className="col-md-12">
          
                         <div className="text-right mt-3" >
                  {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                  }
                  {edit?
                    <Button color="success" size='sm'
                    type={edit?"submit":"button"}
                    // onClick={handleSubmit} 
                    >
                              <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="info" size='sm' 
                    onClick={(e)=>{
                      e.preventDefault();
                      e.stopPropagation();
                      setEdit(true)}
                    } >
                              <i className="fa fa-edit"></i> Edit
                    </Button>
                      }
              </div>
          </div> */}

                                    <div className='col-md-2 labl_infore pt-3'>
                                      <div className="">
                                        <img src={`http://alobhaitsolution.com:3009${moodDetail.icon}`} alt="" 
                                        style={{
                                          width:'100%',
                                          maxWidth:'300px'
                                          }}/>
                                        {/* {edit&&
                                         <div className="custom-file">
                                         
                                         <input type="file" name="mood" accept="image/*"
                                        className="custom-file-input"  onChange={(e)=>{
                                       setUpdatedMood({
                                         ...updatedMood,
                                         icon:e.target.files[0]
                                       })
                                     }}/>
                                         <label className="custom-file-label"
                                         style={{
                                          fontSize:'15px'
                                         }}
                                          >{updatedMood?.icon?.name?updatedMood?.icon?.name:'Choose image...'}</label>
                                         </div>
                                        } */}
                                      </div>
                                    </div>                             
                                   <div className='col-md-10 labl_infore'>
                                      <div className="first_frm">
                                        {
                                          edit?
                                          <input type="text"
                                          required
                                          className="form-control" value={updatedMood.name}
                                          onChange={(e)=>{
                                            setUpdatedMood({
                                              ...updatedMood,
                                              name:e.target.value
                                            })
                                          }}
                                           />
                                          :
                                          <label for="">{updatedMood.name} </label>

                                        }
                                      </div>
                                  </div>          
                                   <div className='col-md-3 labl_infore'>
                                   {edit&&
                                         <div className="custom-file">
                                         
                                         <input type="file" name="mood" accept="image/*"
                                        className="custom-file-input"  onChange={(e)=>{
                                       setUpdatedMood({
                                         ...updatedMood,
                                         icon:e.target.files[0]
                                       })
                                     }}/>
                                         <label className="custom-file-label"
                                         style={{
                                          fontSize:'15px'
                                         }}
                                          >{updatedMood?.icon?.name?updatedMood?.icon?.name:'Choose image...'}</label>
                                         </div>
                                        }
                                  </div>          
                                <div className='brn-topwrp'></div>
                                    {/* <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/mood-management" type="button" class="btn btn-info">Back</Link>
                                      </div>
                                    </div> */}
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                     </div> 
                     

            </div>

            </Form>

        </div>        
   </div>
    </>
  )
}

export default Viewmood