import * as api from '../api/index';
import {GET_MOOD_LIST,GET_QUESTIONARY_LIST} from '../constants/masterConstants';

export const getMasterMood =()=> async(dispatch)=>{
    try {
        const {data} = await api.getMoodList();
        dispatch({type:GET_MOOD_LIST,payload:data.data});

        
    } catch (error) {
        console.log("get mood list error");
    }
}

export const getQuestionaryList=()=> async(dispatch)=>{
    try {
        const {data} = await api.getQuestionary();
        dispatch({type:GET_QUESTIONARY_LIST,payload:data.data});
    } catch (error) {
        console.log("questionary fetch error");
        
    }
}