import React,{useEffect,useState} from 'react';

import { Button, ButtonGroup, Container,Card } from 'reactstrap';
import {viewComData} from './viewCommunityData'


import { getCommunityById } from 'redux/api';
import { useParams } from 'react-router';

import UserHeader from 'components/Headers/UserHeader';
import CommunityDetails from './CommunityDetails';
import CommunityMember from './CommunityMember';
import CommunityInvitation from './CommunityInvitation';
import AdminNavbar from 'components/Navbars/AdminNavbar';

const CommunityView = () => {

    const params = useParams();

    const [community, setCommunity] = useState([]);
    const [activeView, setActiveView] = useState(1);
   
const getCommunity = async()=>{
    const {data} = await getCommunityById(params.communityId);
    setCommunity(data.data); 
}

useEffect(()=>{
    getCommunity();

},[])
    return (
        <>
            <AdminNavbar brandText="Community management" />
           <UserHeader/>

           {
               community&&
          
        <Container className="mt--7" fluid>
           <Card>

            <CommunityDetails detail={community.community} communityData={community} setCommunityData={setCommunity} />

           </Card>
           <ButtonGroup className="mt-3" >
                <Button color="danger" active={activeView===1?true:false} onClick={()=>setActiveView(1)} >
                    <i className="ni ni-bullet-list-67 text-white tobylogn" ></i>
                    &ensp;Member {community.community?.member?.length}
                </Button>
                <Button color="danger" active={activeView===2?true:false} onClick={()=>setActiveView(2)}>
                    <i className="fa fa-users  text-white tobylogn"></i>
                    &ensp;Invitation {community.community?.invitation?.length}
                </Button> 
            </ButtonGroup>
            {activeView===1&&<CommunityMember 
                    member={community.community?.member} 
                    community={community}
                    setCommunity={setCommunity}/>}
            {activeView===2&&<CommunityInvitation 
                invitation={community.community?.invitation} 
                community={community}
                setCommunity={setCommunity}/>}
           
        </Container>
         }
    </>
    )
}

export default CommunityView
