import React,{useEffect,useState} from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Button,
  } from "reactstrap";


  import { useDispatch,useSelector } from 'react-redux';
  import {getMe, updateProfile, updatePicture} from '../../redux/actions/userActions';
  import{updateMe,updateMePic } from '../../redux/api';
  import PasswordChange from './PasswordChange';
  import { toast , ToastContainer } from 'react-toastify';


import ProfileHeader from './ProfileHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';

const Profile = () => {
  const dispatch = useDispatch();
  const info = useSelector(state=>state.auth.info);

  const [active, setActive] = useState(true);
  const [editImg, setEditImg] = useState(false);

  const [user, setUser] = useState({
    name:'',email:'',
  })

  const [img, setImg] = useState();

  const handleChange=(e)=>{
    setUser({...user,[e.target.name]:e.target.value})
  }

  const handleImage=(e)=>{
    // console.log(e.target.files)
    if(e.target.files){
      setImg(e.target.files[0]);
    }
  }

  const updateDetails=async(e)=>{
    e.preventDefault();
    const payload={
      ...user
    }
    
    try {
      const {data} = await updateMe(payload);
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      dispatch(getMe());
    } catch (error) {
      toast.error(error?.response?.data?.message||error?.message||"Something went wrong in updating profile", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      
    }
    // dispatch(updateProfile(user));
  }

  const handleCancel =(e)=>{
    e.preventDefault();
    setUser({ name:'',email:'',number:'' })
  }

  const handleImageCancel=()=>{
    setImg();
    setEditImg(false);

  }
const handleImageUpdate=async(e)=>{
  e.preventDefault();

  if(!img){
    toast.error("Please select image to update", {
      autoClose: 1500,
      hideProgressBar: true,
    });
    return
  }
  let formdata = new FormData();
  img&&formdata.append('picture',img);
  try {
    const {data} = await updateMePic(formdata);
    toast.success(data?.message||"Profile image updated successfully", {
      autoClose: 1500,
      hideProgressBar: true,
    });
    setEditImg(false);
  } catch (error) {
    toast.error(error?.response?.data?.message||error?.message||"Something went wrong in updating profile", {
      autoClose: 1500,
      hideProgressBar: true,
    });
    setEditImg(false);
  }
  // dispatch(updatePicture(formdata));
}

  useEffect(()=>{
    dispatch(getMe());
    if(info._id){
      setUser({
        ...user,
        name:info.name,
        email:info.email,
        number:info.number
      })
    }
  },[])

    return (
        <>
        <ToastContainer />
        <AdminNavbar brandText="Profile" />
            <ProfileHeader/>
            { !info.details&&

            <Container fluid>
        <Row>
          <Col className="order-xl-1 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow pt-6">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <div onClick={(e) => e.preventDefault()}>
                      <img
                        alt="admin user"
                        className="rounded-circle"
                        // src={`${process.env.REACT_APP_BASE_URL}${info?.details?.picture}`}
                        src={`http://alobhaitsolution.com:3009${info?.picture}`}
                      />
                      
                    
                    </div>
                    
                  </div>
                </Col>
                    
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" style={{borderRadius:'0.375rem'}}>
                <div className="d-flex justify-content-between">
                  
                  {editImg&&< Button color="danger" size='sm' onClick={handleImageCancel} >
                                <i className="fa fa-times"></i> Cancel
                        </Button>
                      }
                      {editImg?
                        <Button color="success" size='sm' onClick={handleImageUpdate} >
                                  <i className="fa fa-check"></i> Done
                        </Button>
                        :
                        <Button color="info" size='sm' onClick={()=>setEditImg(true)} >
                                  <i className="fa fa-edit"></i> Edit
                        </Button>
                      }
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="text-center w-100">
                  {editImg&&
                            <div className="custom-file">
                                <input type="file" name="picture"
                                className="custom-file-input" onChange={handleImage} accept="image/*" />
                                <label className="custom-file-label"
                                style={{textAlign:'left'}}
                                 >Choose Image...</label>
                            </div>
                          }
                     <div className='profile_name text-center'>
                           <h2 className='mt-4'>{info.name}</h2>
                           <h4>{info.email}</h4>
                           <h4>{info.number}</h4>
                      </div>
                    {/* <div className="card-profile-stats d-flex justify-content-center mt-md-5"> */}
                        {/* {editImg&&
                            <div className="custom-file">
                                <input type="file" name="picture"
                                className="custom-file-input" onChange={handleImage} accept="image/*" />
                                <label className="custom-file-label" >Choose Image...</label>
                            </div>
                          } */}
                    {/* </div> */}
                  </div>
                </Row>
                {/* <div className="text-center">
                  <h3>
                    {info?.details?.name}
                  </h3>
                  
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {info?.details?.email}
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    {info?.details?.number}
                  </div>
                         
                </div> */}
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-2" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8" lg="8">
                    <h3 className="mb-0">{active===true?"Edit Profile":"Update Password"}</h3>
                  </Col>
                  <Col lg="4" >
                    
                      <Button color="info" onClick={()=>setActive(!active)}> {active?"Update Password":"Update Profile"}</Button>
                   
                  </Col>
                  
                </Row>
              </CardHeader>
              {active===true&&
              <CardBody>
                <Form onSubmit={updateDetails} >
                  
                  <div className="pl-lg-4 pb-4">
                    
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Your Name
                          </label>
                          <Input
                          required
                            className="form-control-alternative"
                            defaultValue={user.name}
                            name="name"
                            placeholder="Enter your Name"
                            type="text"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                          required
                            className="form-control-alternative"
                            name="email"
                            defaultValue={user.email}
                            placeholder="jesse@example.com"
                            type="email"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-number"
                          >
                            Contact Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.number}
                            name="number"
                            placeholder="contact number"
                            type="number"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                  
                  <div className="pl-lg-4">
                    <Col lg={6} className="m-auto text-center" >
                      <Button color="info" onClick={handleCancel} >Reset</Button>
                      <Button type="submit" className='difrnt' 
                      // onClick={updateDetails}
                       >Update</Button>
                    </Col>
                    
                  </div>
                                                     
                </Form>
              </CardBody>
          }
          { active===false&&<PasswordChange setActive={setActive} />}
            </Card>
          </Col>
        </Row>
      </Container>
}
            
        </>
    )
}

export default Profile
