import axios from 'axios';
import {toast} from 'react-toastify'

        // const API = axios.create({baseURL:"http://3.109.240.139:3001/api/v1",withCredentials:true });

        // const API = axios.create({baseURL:"http://65.1.93.80:3001/api/v1",withCredentials:true });
    const API = axios.create({baseURL:"http://alobhaitsolution.com:3009/api/v2.0",withCredentials:true });

    // const API = axios.create({baseURL:"http://localhost:3009/api/v2.0",withCredentials:true});
            axios.defaults.credentials=true;
        
        API.interceptors.request.use((req)=>{
            let token;
            //  = document.cookie.split('=')[1];
        if(token) {
        
            req.headers.authorization = `Bearer ${token}`
        }
        return req;
});

API.interceptors.response.use((res)=>{
    return res;
},
(error)=>{

    if(error.response.status===401||error.response.status===403){
        //  window.location.href='/auth/login'
        toast.error(error?.response?.data?.message||error?.message||"Something went wrong", {
            autoClose: 1500,
            hideProgressBar: true,
          });
         
    }
    return Promise.reject(error);
}
)


export const login =(credentials)=> API.post('/admin/user/login', credentials);
export const logout =()=> API.delete('/user/logout');
export const signup=(userDetails)=> API.post('/user/signup', userDetails);
export const forgotPassword=(email)=> API.post('/user/forgot-password', {email});
export const verifyOTP=(otp,email)=> API.post('/user/forgot-password', {otp,email});
export const resetPassword=(otp,email)=> API.post('/user/reset-password', {otp,email});
export const updatePassword=(data)=> API.post('/user/update-password', data);


// User API
// export const getMe =()=> API.get('user/me');
export const getMe =()=> API.get('admin/user/me');
export const updateMe =(data)=> API.patch('admin/user/me',data);
export const updateMePic =(data)=> API.put('admin/user/me',data);


export const updateProfileDetails=(data)=> API.post('/user/update-profile',data);
export const updateProfilePicture=(data)=> API.post('user/update-picture',data);
export const userStatsWeekly=(sd,ed)=> API.post('/user/stats-weekly',{startDate:sd,endDate:ed});
export const userStatsMonthly=(sd,ed)=> API.post('/user/stats-monthly',{startDate:sd,endDate:ed});

export const getAllUsers=()=> API.get('/user/');
export const getUserById=(id)=> API.get(`/user/${id}`);
export const deleteUserById =(id)=> API.delete(`/user/${id}`);
export const activateUser=(userId)=> API.patch(`/user/active/${userId}`);
export const inactivateUser=(userId)=> API.post(`/user/active/${userId}`);

// Journal API
export const getAllJournal=()=> API.get('/journal');
export const getJournalById=(id)=> API.get(`/journal/${id}`);
export const deleteJournalById=(id)=> API.delete(`/journal/${id}`);
export const getJournalTemplates = ()=> API.get('/journal/templates');
export const deleteJournalTemplateById = (id)=> API.delete(`/journal/templates/${id}`);
export const updateJournalTemplateStatusById = (id)=> API.put(`/journal/templates/${id}`);
export const updateJournalTemplateById =(id,data)=> API.patch(`/journal/templates/${id}`,data);
export const addJournalTemplate =(data)=> API.post('/journal/templates',data);

// Community API

export const getAllCommunity =()=> API.get('/community/');
export const getCommunityById =(id)=> API.get(`/community/${id}`);
export const approveCommunity =(id)=> API.patch(`/community/${id}/approve`);
export const updateCommunityById =(id,data)=> API.patch(`/community/${id}`,data);
export const addCommunity =(data)=> API.post('/community/',data);
export const deleteCommunity=(id)=> API.delete(`/community/${id}`);
export const removeUserFromCommunity=(communityId,userId)=> 
                              API.post(`/community/${communityId}/remove/${userId}`);
export const approveUser=(communityId,userId)=>API.post(`/community/${communityId}/approveUser/${userId}`);
export const unApproveUser=(communityId,userId)=>API.patch(`/community/${communityId}/approveUser/${userId}`);
export const deleteInvitation=(communityId,inviteId)=> API.delete(`/community/${communityId}/${inviteId}`);

// Capsules API
export const getAllCapsules =()=> API.get('/capsule');
export const getOneCapsule =(id)=> API.get(`/capsule/${id}`);
export const getCapsuleSubcat=(capsuleId)=> API.get(`/capsule/${capsuleId}/sub-category`);
export const addCapsuleSubcat=(capsuleId,data)=> API.post(`/capsule/${capsuleId}/sub-category`,data);
export const getCapsulePost =(capsuleId)=> API.get(`/capsule/${capsuleId}/post`);
export const updateCapsuleById =(id,data)=> API.patch(`/capsule/${id}`, data);
export const deleteCapsuleSubcatById = (capsuleId,subcategoryId)=> API.delete(`/capsule/${capsuleId}/sub-category/${subcategoryId}`)
export const getCapsuleSubcatById = (capsuleId,subcategoryId)=> API.get(`/capsule/${capsuleId}/sub-category/${subcategoryId}`)
export const updateCapsuleSubcatById = (capsuleId,subcategoryId,data)=> API.patch(`/capsule/${capsuleId}/sub-category/${subcategoryId}`,data)
export const getCapsuleSubcatTools =(capsuleId,subcategoryId)=> API.get(`/capsule/${capsuleId}/sub-category/${subcategoryId}/tools`)
export const getCapsuleContentById =(capsuleId,subcategoryId,contentId)=> API.get(`/capsule/${capsuleId}/sub-category/${subcategoryId}/tools/${contentId}`)
export const updateCapsuleContentById =(capsuleId,subcategoryId,contentId,data)=> API.patch(`/capsule/${capsuleId}/sub-category/${subcategoryId}/tools/${contentId}`,data)
export const deleteCapsuleContentById =(capsuleId,subcategoryId,contentId)=> API.delete(`/capsule/${capsuleId}/sub-category/${subcategoryId}/tools/${contentId}`)
export const addCapsuleContent =(capsuleId,subcategoryId,data)=> API.post(`/capsule/${capsuleId}/sub-category/${subcategoryId}/tools`,data);
export const addCapsule = (data)=> API.post('/capsule', data);
export const addCapsulePost =(id,data)=> API.post(`/capsule/${id}/post`,data);
export const deleteCapsulePost=(capsuleId,postId)=> API.delete(`/capsule/${capsuleId}/post/${postId}`);
export const getCapsulePostById=(capsuleId,postId)=> API.get(`/capsule/${capsuleId}/post/${postId}`);
export const updateCapsulePostById=(capsuleId,postId,data)=> 
                          API.patch(`/capsule/${capsuleId}/post/${postId}`,data);
export const deleteCapsule =(capsuleId)=>  API.delete(`/capsule/${capsuleId}`);


// Discussion API
export const getAllDiscussions=()=> API.get('/community/discussions');
export const getDiscussionById=(communityId,postId)=> API.get(`/community/${communityId}/post/${postId}`);
export const updateDiscussionById=(communityId,postId,data)=> 
                            API.patch(`/community/${communityId}/post/${postId}`,data);
export const deleteDiscussionById=(communityId,postId)=> API.delete(`/community/${communityId}/post/${postId}`);
export const deleteDiscussionReply=(communityId,postId,replyId)=>
                            API.delete(`/community/${communityId}/post/${postId}/reply/${replyId}`);
export const discussionStats=(sd,ed)=> API.post('/community/discussions/stats',{startDate:sd,endDate:ed});


// Notification API
export const getAllNotification=()=>API.get('/notify/');


// Static API
export const getStaticData =()=> API.get('/static');
export const getWelcomeSlider=()=> API.get('/static/welcome');
export const updateWelcomeSlider=(data,sliderId)=> API.patch(`/static/welcome/${sliderId}`,data);
export const getAbout=()=> API.get('/static/about');
export const updateAbout=(data)=> API.post('/static/about',data);
export const getPrivacy=()=> API.get('/static/privacy');
export const updatePrivacy=(data)=> API.post('/static/privacy',data);
export const updateTerms=(data)=> API.post('/static/terms', data);
export const getTerms=()=> API.get('/static/terms');

//  QUESTIONARY API

export const getQuestionary =()=> API.get('/static/questionary');
export const addQuestionary =(data)=> API.post('/static/questionary',data);
export const getQuestionaryById =(questId)=> API.get(`/static/questionary/${questId}`);
export const deleteQuestionaryById =(questId)=> API.delete(`/static/questionary/${questId}`);
export const updateQuestionaryById =(questId,data)=> API.patch(`/static/questionary/${questId}`,data);
export const updateQuestionaryStatusById =(questId)=> API.put(`/static/questionary/${questId}`);

// MASTER LIST

export const getMoodList = ()=> API.get('/master/mood');
export const addMood = (data)=> API.post('/master/mood',data);
export const getMoodById = (moodId)=> API.get(`/master/mood/${moodId}`);
export const updateMoodStatusById = (moodId)=> API.put(`/master/mood/${moodId}`);
export const updateMoodById = (moodId,data)=> API.patch(`/master/mood/${moodId}`,data);
export const deleteMoodById = (moodId)=> API.delete(`/master/mood/${moodId}`);

//  ADMIN USERS
export const getAllAdminUsers=()=> API.get(`/admin/user/allusers`);
export const addAdminUser =(data)=> API.post("/admin/user/add-admin",data);
export const getAdminUserById =(id)=> API.get(`/admin/user/${id}`);
export const getAssigneeList =()=> API.get(`/admin/user/allusers`);
export const updateOneAdminuser =(userId,data)=> API.patch(`/admin/user/${userId}`,data);


//  ADMIN ROLE

export const getARoleList =()=> API.get(`/admin/role`);
export const addRole =(data)=> API.post(`/admin/role`,data);
export const getRoleById =(roleId)=> API.get(`/admin/role/${roleId}`);
export const updateRoleById =(roleId,data)=> API.patch(`/admin/role/${roleId}`,data);

export const getPermissionList =()=> API.get(`/admin/permission`);