import {GET_MOOD_LIST,GET_QUESTIONARY_LIST} from '../constants/masterConstants';

const initialState = {
    mood:{
        isLoading:false,
        isError:false,
        errorMessage:"",
        data:[]
    },
    questionary:{
        isLoading:false,
        isError:false,
        errorMessage:"",
        data:[]
    }
}

export const masterReducer = (state=initialState,action)=>{
    switch (action.type) {
        case GET_MOOD_LIST:
            return {
                ...state,
                mood:{
                    ...state.mood,
                    data:action.payload}
            }
        case GET_QUESTIONARY_LIST:
            return {
                ...state,
                questionary:{
                    ...state.questionary,
                    data:action.payload
                }
            }
           
        default:
            return state;
    }
}