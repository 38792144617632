

// reactstrap components
import {
  
} from "reactstrap";

const AdminNavbar = () => {
  return (
    <>
      {/* <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
          <img src="assets/img/brand/blue.png"/>
            
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          
          
        </Container>
      </Navbar> */}

          <div className="header bg-gradient-danger py-7 py-lg-15 pt-lg-15">
              <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
              </div>
            </div>

    </>
  );
};

export default AdminNavbar;
