import React, { useState, useEffect } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { toast, ToastContainer } from "react-toastify";

import { Form, FormGroup, Label, Input } from "reactstrap";
import { Link,useHistory } from "react-router-dom";
// <AdminNavbar brandText="Add Role" />

import {addQuestionary} from '../../redux/api/index';

const AddQuestionary = () => {
  const history = useHistory();

  const [newQuestion, setNewQuestion] = useState({
    question: "",
    options: {
      option1: "Never",
      option2: "Rarely",
      option3: "Often",
      option4: "Sometimes",
      option5: "Always",
    },
  });
  const handleSubmit = async(e)=>{
    e.preventDefault();


    const outputData = {
      question:newQuestion.question,
      options:[
        {
          option:newQuestion.options.option1,
          mark:0
        },
        {
          option:newQuestion.options.option2,
          mark:1
        },
        {
          option:newQuestion.options.option3,
          mark:2
        },
        {
          option:newQuestion.options.option4,
          mark:3
        },
        {
          option:newQuestion.options.option5,
          mark:4
        },
      ]
    }

    try {
      const {data} = await addQuestionary(outputData);
      toast.success(data?.message||"New Question added successfully", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      history.push(`/admin/view-questionary/${data.data._id}`)
    } catch (error) {
      toast.error(error?.response?.data?.message||error?.message||"Something went wrong in adding question", {
        autoClose: 1500,
        hideProgressBar: true,
      });
    }
  }

  return (
    <>
    <ToastContainer />
      <div className="second_header12">
        <AdminNavbar brandText="Add Question" />
      </div>

      <div className="mod_box moodmange">
        <div className="container">
          <div className="row">
            <div className="col-md-12 addrole_outer">
              <div className="row">
                <div className="col-md-6">
                  <div className="addrole_top">
                    <h3 className="mb-0 text-fff py-3">Add Question</h3>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
              <Form onSubmit={handleSubmit} >
                <div className="try_whole">
                  <div className="row try_whole">
                    <div className="col-md-12 labl_infore">
                      {/* <div className="first_frm">
                                        <label for="">Enter Question</label>
                                        <input type='text' className='form-control' placeholder='Enter Question' id='' ></input>
                                      </div> */}
                      <FormGroup className="first_frm">
                        <Label for="question">Enter Question</Label>
                        <Input
                          required
                          type="text"
                          name="question"
                          id="question"
                          value={newQuestion.question}
                          onChange={(e) => {
                            setNewQuestion({
                              ...newQuestion,
                              question: e.target.value,
                            });
                          }}
                          // disabled={!isEdit}
                          placeholder="Enter Question"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-12 labl_infore">
                      <div className="first_frm">
                        <label for="">Enter Options</label>
                        <input
                          type="text"
                          required
                          className="form-control mb-2"
                          placeholder="Enter Option 1"
                          value={newQuestion.options.option1}
                          onChange={(e) => {
                            setNewQuestion({
                              ...newQuestion,
                              options: {
                                ...newQuestion.options,
                                option1: e.target.value,
                              },
                            });
                          }}
                        ></input>
                        <input
                          required
                          type="text"
                          className="form-control mb-2"
                          placeholder="Enter Option 2"
                          value={newQuestion.options.option2}
                                          onChange={(e)=>{
                                            setNewQuestion({
                                              ...newQuestion,
                                              options:{
                                                ...newQuestion.options,
                                                option2:e.target.value
                                              }
                                            })
                                          }}
                        ></input>
                        <input
                          required
                          type="text"
                          className="form-control mb-2"
                          placeholder="Enter Option 3"
                          value={newQuestion.options.option3}
                                          onChange={(e)=>{
                                            setNewQuestion({
                                              ...newQuestion,
                                              options:{
                                                ...newQuestion.options,
                                                option3:e.target.value
                                              }
                                            })
                                          }}
                        ></input>
                        <input
                          required
                          type="text"
                          className="form-control mb-2"
                          placeholder="Enter Option 4"
                          value={newQuestion.options.option4}
                          onChange={(e)=>{
                            setNewQuestion({
                              ...newQuestion,
                              options:{
                                ...newQuestion.options,
                                option4:e.target.value
                              }
                            })
                          }}
                        ></input>
                        <input
                          required
                          type="text"
                          className="form-control mb-2"
                          placeholder="Enter Option 5"
                          value={newQuestion.options.option5}
                          onChange={(e)=>{
                            setNewQuestion({
                              ...newQuestion,
                              options:{
                                ...newQuestion.options,
                                option5:e.target.value
                              }
                            })
                          }}
                        ></input>
                      </div>
                    </div>
                    <div className="brn-topwrp"></div>
                    <div className="bdn_cover">
                      <div className="m-auto col-lg-6">
                        <Link
                          to="/admin/questionary-management"
                          type="button"
                          class="btn btn-info"
                        >
                          Back
                        </Link>
                        <button type="submit" className="btn difrnt">
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="brn-botwrp"></div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuestionary;
