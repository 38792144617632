import React,{useState} from 'react';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";

  import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    return (
        <>

<Col lg="5" md="7">
        <Card className="bg-secondary border-0">
          <CardHeader className="bg-transparent pb-3">
          <h1 class="text-center text-white mt-2">Forgot Password</h1>
          <div className="col-6 collapse-brand colpsebrnd">
                <img className="w-100 ml-6 px-4 " src="https://alobhaitsolution.com/chatbotadmin/assets/img/TurleLogin.png"/>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83 tobylogn" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    name="email"
                    
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4 tobyragistr px-5" color="primary" type="button" >
                  Send OTP
                </Button>
              </div>
                    <Row> 
                        <Col lg="12" className="text-center txtoby">
                            <Link to="/auth/login">
                             <medium color="primary" size="sm" >Back to SignIn</medium>
                            </Link>
                        </Col>
                    </Row>
            </Form>
          </CardBody>
        </Card>
        
      </Col>
            
            
        </>
    )
}

export default ForgotPassword
