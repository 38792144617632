import React from 'react';

import {  Container} from "reactstrap";

const ProfileHeader = () => {
    return (
        <>
<div className="header bg-gradient-danger pb-6 pt-7">
        <Container fluid>
          
        </Container>
      </div>
            
        </>
    )
}

export default ProfileHeader
