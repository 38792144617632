import React, { useState,useEffect } from "react";

import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, Nav, NavLink, NavItem } from "reactstrap";
import { userStatsWeekly,discussionStats,userStatsMonthly} from "../../redux/api/index";


import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts2.js";


const Analytics = () => {
  var chartEl = document.getElementById("myChart");
  console.log(chartEl)
  const [activeNav, setActiveNav] = useState(1);
  const [weeklyStats, setWeeklyStats] = useState([0,0,0,0,0,0,0]);
  const [monthlyStats, setMonthlyStats] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
  const [isWeeklyStat, setIsWeeklyStat] = useState(true);
  const [isMonthlyStat, setIsMonthlyStat] = useState(true);
  const [isDiscussionStat, setIsDiscussionStat] = useState(true);
  const [lastWeekUser, setLastWeekUser] = useState([]);
  const [lastMonthDiscussion, setlastMonthDiscussion] = useState(0);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };


  const getUserWeekly=async()=>{
      
    try {
      const {data} = await userStatsWeekly();    
      let sortedData = data.data.sort((a,b)=>a._id-b._id);
      let result=[];
      result.length=7;

      let day=[1,2,3,4,5,6,7];

      day.forEach((elem,i)=>{
        
        let sortInd = sortedData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          result[sortedData[sortInd]._id-1]= sortedData[sortInd].count
        }else{
          result[i]=0
        }

      })
      if(Math.min(...result)===0&&Math.max(...result)===0){
        setIsWeeklyStat(false);
      } else setIsWeeklyStat(true);

      console.log(result)
      setWeeklyStats(result);
      setLastWeekUser(result);
      
    } catch (error) {
      console.log('error getting weekly user');
    }
  }

  const getMonthlyStats=async()=>{
   
    try {
      const {data} = await userStatsMonthly();
      let sortedMonthlyData = data.data.sort((a,b)=>a._id-b._id);
      let monthlyResult=[];
      monthlyResult.length=12;
      let month=[1,2,3,4,5,6,7,8,9,10,11,12];

      month.forEach((elem,i)=>{
        let sortInd = sortedMonthlyData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          monthlyResult[sortedMonthlyData[sortInd]._id-1]= sortedMonthlyData[sortInd].count
        }else{
          monthlyResult[i]=0
        }

      })
      setMonthlyStats(monthlyResult);
      if(Math.min(...monthlyResult)===0&&Math.max(...monthlyResult)===0){
        setIsMonthlyStat(false);
      } else setIsMonthlyStat(true);
      
    } catch (error) {
      console.log('error getting monthly user');
    }
  }




  const weeklyDataUser = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Users",
        // data: [4,20,65,6,13,6,20],
        data: weeklyStats,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(75 192 192 / 65%)',
        pointHoverBackgroundColor:'rgb(75, 192, 192)',
      },
      {
        label: "Mental Health",
        // data: [300,600,500,6,150,6,20],
        data: [300,600,500,6,150,6,20],
        fill:true,
        borderColor:'rgb(255, 205, 86)',
        backgroundColor: 'rgb(255 205 86 / 82%)',
        pointHoverBackgroundColor:'rgb(255, 205, 86)'
      },
    ],
  };

  const monthlyDataUser = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Users",
        // data: [4,20,65,6,13,6,20,34,43,433,434,43],
        data: monthlyStats,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(75 192 192 / 65%)',
        pointHoverBackgroundColor:'rgb(75, 192, 192)'
      },
      {
        label: "Mental Health",
        data: [300,600,500,6,150,6,20,46,34,466,43,200],
        fill:true,
        borderColor:'rgb(255, 205, 86)',
        backgroundColor: 'rgb(255 205 86 / 82%)',
        pointHoverBackgroundColor:'rgb(255, 205, 86)'
      },
    ],
  };

  useEffect(()=>{
    getMonthlyStats();
    getUserWeekly();

  },[])

  return (
    <Card>
      <CardHeader>
        <Nav className="justify-content-end" pills>
          <NavItem>
            <NavLink
              className={classnames("py-2 px-3", {
                active: activeNav === 1,
              })}
              href=""
              onClick={(e) => toggleNavs(e, 1)}
            >
              <span className="d-none d-md-block">Month</span>
              <span className="d-md-none">M</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-2 px-3", {
                active: activeNav === 2,
              })}
              data-toggle="tab"
              href=""
              onClick={(e) => toggleNavs(e, 2)}
            >
              <span className="d-none d-md-block">Week</span>
              <span className="d-md-none">W</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-2 px-3", {
                active: activeNav === 3,
              })}
              data-toggle="tab"
              href=""
              onClick={(e) => toggleNavs(e, 3)}
            >
              <span className="d-none d-md-block">Day</span>
              <span className="d-md-none">D</span>
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>
      <CardBody
      style={{height:"400px"}}
      >
      {activeNav === 1 && (
        <Line
          data={monthlyDataUser}
          // options={chartExample1.options}
          getDatasetAtEvent={(e) => console.log(e)}
        />
      )}
      {activeNav === 2 && (
        <Line
          data={weeklyDataUser}
          // options={chartExample1.options}
          getDatasetAtEvent={(e) => console.log(e)}
        />
      )}
      {activeNav === 3 && (
        <Line
        height={400}
          data={weeklyDataUser}
          // options={chartExample1.options}
          getDatasetAtEvent={(e) => console.log(e)}
        />
      )}

      </CardBody>
    </Card>
  );
};

export default Analytics;
