import React,{useState} from 'react';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Row,
    Col,
  } from "reactstrap";

  import OtpInput from 'react-otp-input'; 

  import { Link } from 'react-router-dom';

const Otp = () => {

  const [otpValue, setOtpValue] = useState('');

  const handleOtp=(e)=>{
    console.log(e);
    
  }

    return (
        <>

<Col lg="5" md="7">
          <h1 class="text-center text-white mt-2">Enter OTP</h1>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
          <div className="col-6 collapse-brand">
                <img className="w-100 ml-6" src="https://alobhaitsolution.com/chatbotadmin/assets/img/TurleLogin.png"/>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            
            <Form role="form">
              
              <OtpInput
                  className="m-auto p-2"
                  inputStyle={"w-100 p-2 display-4 rounded"}
                  value={otpValue}
                  onChange={(val)=>setOtpValue(val)}
                  numInputs={4}
                  isInputNum={true}
                  separator={<span></span>}
                  
                />
              
                    <Row> 
                        <Col lg="12" className="text-right">
                            <Link to="/auth/login">
                             <p color="primary" size="sm" >Login</p>
                            </Link>
                        </Col>
                    </Row>
              <div className="text-center">
                <Button className="my-4 tobyragistr" color="primary" type="button" >
                  Verify OTP
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        
      </Col>
            
            
        </>
    )
}

export default Otp
