
import {GET_ALL_COMMUNITY,APPROVE_COMMUNITY, ADD_COMMUNITY, DELETE_COMMUNITY, APPROVE_USER_COMMUNITY} from '../constants/communityConstants'


const initialState={
    isLoading:false,
    isError:false,
    detail:[]

}


export const communityReducer =(state=initialState,action)=>{
    switch (action.type) {
        case GET_ALL_COMMUNITY:
            return {
                ...state,
                detail:action.payload.data
            }

        case APPROVE_COMMUNITY:
        
            let comDetail= state.detail.map(elem=>{
                if(elem._id===action.payload){
                    elem.approved=elem.approved?false:true;
                }
                
                return elem;
            })

            return {
                ...state,
                detail:comDetail
            }

        case ADD_COMMUNITY:
             return {
                 ...state,
                 detail:[...state.detail,action.payload]
             }
        case DELETE_COMMUNITY:
            const updatedCommunity = state.detail.filter(elem=>elem._id!==action.payload);
            return {
                ...state,
                detail:updatedCommunity
            }
        case APPROVE_USER_COMMUNITY:
            let comIndex = state.detail.indexOf(com=>com._id===action.payload.communityId);
            console.log(comIndex);


            return state;

        default:
            return state;
    }
}