
import {ADD_CAPSULE, GET_ALL_CAPSULES,DELETE_CAPSULE} from '../constants/capsulesConstants'

const initialState={
    isLoading:false,
    isError:false,
    detail:[]

}

export const capsuleReducer =(state=initialState,action)=>{
    switch (action.type) {
        case GET_ALL_CAPSULES:
            return {
                ...state,
                detail:action.payload.data
            }
        case ADD_CAPSULE:
            return {
                ...state,
                detail:[...state.detail,action.payload.data]
            }
        case DELETE_CAPSULE:
            const updatedCap = state.detail.filter(elem=>elem._id!==action.payload)
            return {
                ...state,
                detail:updatedCap
            }
    
        default:
            return state;
    }
}