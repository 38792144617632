import React from 'react'

import {  Container} from "reactstrap";


const StaticHeader = () => {
    return (
        <>

<div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            

                </div>
        </Container>
      </div>
            
        </>
    )
}

export default StaticHeader
