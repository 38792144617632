import React,{useEffect,useState} from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link,useParams,useHistory } from 'react-router-dom';
import {getQuestionaryById,updateQuestionaryById} from '../../redux/api/index';
import { toast, ToastContainer } from "react-toastify";
import { Form } from 'reactstrap'; 

// <AdminNavbar brandText="Add Role" />
const EditQuestionary = () => {

  const params = useParams();
  const history = useHistory();
  const [questionary, setQuestionary] = useState({
    question:"",
    options:[
      {
        option:"",
        _id:""
      }
    ]
  })

  const fetchQuestion=async()=>{
    try {
      const {data} = await getQuestionaryById(params.questionaryId);
      setQuestionary(data.data);

    } catch (error) {
      console.log("questionary fetch error")
    }
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();


    // const outputData = {
    //   question:newQuestion.question,
    //   options:[
    //     {
    //       option:newQuestion.options.option1,
    //       mark:0
    //     },
    //     {
    //       option:newQuestion.options.option2,
    //       mark:1
    //     },
    //     {
    //       option:newQuestion.options.option3,
    //       mark:2
    //     },
    //     {
    //       option:newQuestion.options.option4,
    //       mark:3
    //     },
    //     {
    //       option:newQuestion.options.option5,
    //       mark:4
    //     },
    //   ]
    // }

    try {
      const {data} = await updateQuestionaryById(params.questionaryId,questionary);
      toast.success(data?.message||"questionary updated successfully", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      fetchQuestion()
      // history.push(`/admin/view-questionary/${data.data._id}`)
    } catch (error) {
      toast.error(error?.response?.data?.message||error?.message||"Something went wrong in updating question", {
        autoClose: 1500,
        hideProgressBar: true,
      });
    }
  }


  useEffect(()=>{
    fetchQuestion();
  },[])

  return (
    <>
     <ToastContainer />
      <div className='second_header12' >
    <AdminNavbar  brandText="Edit Question" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="container">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>Edit Question</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 
                            <Form onSubmit={handleSubmit} >
                         <div className='try_whole'>
                           <div className="row try_whole">
                                    <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                        <label for="">Update Question</label>
                                        <input type='text' className='form-control'
                                         value={questionary.question}
                                         onChange={(e)=>{
                                          setQuestionary({
                                            ...questionary,
                                            question:e.target.value
                                          })
                                         }}
                                          id='' ></input>
                                      </div>
                                    </div>                             
                                   <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Update Options</label>
                                          {
                                            questionary.options.map((ques,index)=> (
                                              <input
                                              key={ques._id}
                                              required
                                              type="text"
                                              className={`form-control mb-${index+1}`}
                                              placeholder={`Enter Option ${index+1}`}
                                              value={ques.option}
                                              onChange={(e)=>{
                                                ques.option=e.target.value;
                                                setQuestionary({
                                                                  ...questionary,
                                                      
                                                                })
                                                              }}
                                            ></input>
                                            ) )
                                          }
                                          {/* <input type='text' className='form-control mb-2' value='Enter Option 2' id='' ></input>
                                          <input type='text' className='form-control mb-2' value='Enter Option 3' id='' ></input>
                                          <input type='text' className='form-control mb-2' value='Enter Option 4' id='' ></input> */}
                                      </div>
                                  </div>          
                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/questionary-management" type="button" class="btn btn-info">Back</Link>
                                        <button type="submit" class="btn difrnt">Update</button>
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                       </Form>
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default EditQuestionary