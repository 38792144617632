import React,{useState,useEffect} from 'react';

import{
     CardHeader, CardBody,Card,Container
} from 'reactstrap'

import moment from "moment";
import ReactPlayer from 'react-player';
import { getJournalById } from '../../redux/api/index';
import AdminNavbar from 'components/Navbars/AdminNavbar';

import { useParams,Link } from 'react-router-dom';
import UserHeader from 'components/Headers/UserHeader';


const ViewUserJournal = () => {

    const params = useParams();

  
  const [journal, setJournal] = useState();


const getJournal=async()=>{
  try{
    const {data} = await getJournalById(params.journalId);
    console.log(data);

    setJournal(data.data);

  } catch(error){
    console.log("error getting journal post")
  }

}

useEffect(()=>{
    getJournal();

},[])

    return (
        <>
        <AdminNavbar brandText="Journal Detail" />
        <UserHeader/>
        { journal&& 
          <>
          <Container className="mt--7" fluid>
            <Card >
            
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0">Journal Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to={`/admin/user/${journal.user._id}`} className="btn bg-gradient-danger text-white px-4">Back</Link>
                </div>
                </div>
            </CardHeader>
      
            
            <CardBody>

            <div className="text-right mb-3 p-2" >
            Created On {moment(journal.createdAt).format("MMM Do YY")}
              </div>
              
                <div className="pl-lg-4">
                <div className="row">
                <div className="col-lg-6">
                    {
                        journal.journal.type==='image'&&
                  <img alt="Image placeholder" 
                  src={`http://alobhaitsolution.com:3001/${journal.journal.content}`} 
                  width="100%"/>
                    }
                    {
                      journal.journal.type==='video'&&
                      <ReactPlayer controls url={`http://alobhaitsolution.com:3001${journal.journal.content}`} />
                    }
                    {
                      journal.journal.type==='text'&&
                      <p>{journal.journal.content}</p>
                    }
                  
                </div>
                
                    </div>
                    </div>
                    </CardBody>
                    </Card>
                    </Container>
                </>
            }     
        </>
    )
}

export default ViewUserJournal
