import * as api from '../api/index';
import {GET_ALL_ADMINS,GET_ALL_ADMINS_ERROR,GET_PERMISSION_LIST,GET_ROLE_LIST,GET_ROLE_LIST_ERROR,GET_PERMISSION_LIST_ERROR} from '../constants/adminContants';

export const getAdminUserAction = ()=> async(dispatch)=>{

    try {
        const {data} = await api.getAllAdminUsers();
        dispatch({type:GET_ALL_ADMINS,payload:data.data})
               
    } catch (error) {
            dispatch({
                type:GET_ALL_ADMINS_ERROR,
                payload:error?.response?.data?.message||error?.message||"Something went wrong, please try agian later"
            });
    }
}

export const fetchAdminRoleList = ()=> async(dispatch)=>{
    try {
        const {data} = await api.getARoleList();
        dispatch({type:GET_ROLE_LIST,payload:data.data})
    } catch (error) {
        dispatch({
            type:GET_ROLE_LIST_ERROR,
            payload:error?.response?.data?.message||error?.message||"Something went wrong, please try agian later"
        });
    }
}

export const fetchPermissionListAction =()=> async(dispatch)=>{
    try {
        const {data} = await api.getPermissionList();
        dispatch({type:GET_PERMISSION_LIST,payload:data.data})
        
    } catch (error) {
        dispatch({
            type:GET_PERMISSION_LIST_ERROR,
            payload:error?.response?.data?.message||error?.message||"Something went wrong, please try agian later"
        });
    }
}