import React,{useState,useEffect} from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link,useParams } from 'react-router-dom';
import {getQuestionaryById} from '../../redux/api/index';
// <AdminNavbar brandText="Add Role" />
const EditQuestionary = () => {

  const params = useParams();
  const [questionary, setQuestionary] = useState({
    question:"",
    options:[
      {
        option:""
      }
    ]
  })

  const fetchQuestion=async()=>{
    try {
      const {data} = await getQuestionaryById(params.questionaryId);
      setQuestionary(data.data);

    } catch (error) {
      console.log("questionary fetch error")
    }
  }

  useEffect(()=>{
    fetchQuestion();
  },[])

  return (
    <>
      <div className='second_header12' >
    <AdminNavbar  brandText="View Question" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="container">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>View Question</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 

                         <div className='try_whole'>
                           <div className="row try_whole">


                                    <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                        <label for="">Question</label>
                                        <input type='text' className='form-control' value={questionary.question} id='' disabled ></input>
                                      </div>
                                    </div>                             
                                   <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                          <label for="">Options</label>
                                          {
                                            questionary.options.map((opt,index)=> (
                                              <input type='text' className='form-control mb-2' value={opt.option} id='' disabled ></input>

                                            ) )
                                          }
                                          {/* <input type='text' className='form-control mb-2' value='Option 2' id='' disabled ></input>
                                          <input type='text' className='form-control mb-2' value='Option 3' id='' disabled ></input>
                                          <input type='text' className='form-control mb-2' value='Option 4' id='' disabled ></input> */}
                                      </div>
                                  </div>          
                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/questionary-management" type="button" class="btn btn-info">Back</Link>
                                        {/* <button type="button" class="btn difrnt">Update</button> */}
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default EditQuestionary