import React, { useState, useEffect, useMemo } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import moment from "moment";
import UserHeader from "./UserHeader";
import { matchSorter } from "match-sorter";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal, ModalHeader, ModalBody, ModalFooter,Button, Container, Card, CardHeader, CardBody,Row,Col,Table
} from 'reactstrap'

import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";

import {
  getAllUser,
  getMe,
  deleteUserbyId,
  activeUser,
  in_activeUser,
} from "../../redux/actions/userActions";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const UserManagement = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const viewPerm = permissionPath.find(perm=>perm.method=="GET"&&perm.path=="/api/v2.0/user/:userId")
  const blockPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/user/active/:userId")
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/user/:userId")

  const COLUMNS = [
    {
      Header: "Sr No.",
      accessor: "_id",
      Cell:(props)=>(
        <>
          {props.row.index+1}
        </>
      )
    },
    {
      Header: "Profile Pic",
      accessor: "picture",
      Cell: (props) => (
        <div style={{ height: "80px", width: "80px" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            className="rounded-circle fluid"
            src={`${process.env.REACT_APP_BASE_URL}${props.value}`}
            alt=""
          />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: "User Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Score",
      accessor: "questionary.score",
    },
    {
      Header: "Attempted Question",
      accessor: "questionary.questionary",
      Cell: (props) => <>{props.value.length}</>,
    },
    {
      Header: "Created On",
      accessor: "createdAt",
      Cell: (props) => (
        <>{props.value ? moment(props.value).format("MMM DD YYYY") : "-"}</>
      ),
    },
    {
      Header: "Actions",
      accessor: "isActive",
      disableSortBy: true,
      Cell: (props) => (
        <div>
          {
            adminRole=="superAdmin"?
          <Link
            to={{
              pathname: `/admin/customer-management/${props.row.original._id}`,
            }}
            type="button"
            className="btn text-purple "
          >
            <i className="fa fa-eye"></i>
          </Link>:viewPerm?<Link
            to={{
              pathname: `/admin/customer-management/${props.row.original._id}`,
            }}
            type="button"
            className="btn text-purple "
          >
            <i className="fa fa-eye"></i>
          </Link>:null
          }

          {
            adminRole=="superAdmin"?props.row.original.isActive?<div type="button" className="btn text-red" onClick={()=>{
              inActivateUser(props.row.original._id)
             
              }} >
              <i className="fa fa-lock"></i>
              {props.row.original.isActive }
            </div>:
            <div type="button" className="btn text-green" onClick={()=>activateUser(props.row.original._id)} >
              <i className="fa fa-lock"></i>
            </div>:blockPerm?props.row.original.isActive?<div type="button" className="btn text-red" onClick={()=>{
              inActivateUser(props.row.original._id)
             
              }} >
              <i className="fa fa-lock"></i>
              {props.row.original.isActive }
            </div>:
            <div type="button" className="btn text-green" onClick={()=>activateUser(props.row.original._id)} >
              <i className="fa fa-lock"></i>
            </div>:null


          }


          {/* {props.row.original.isActive?
          <div type="button" className="btn text-red" onClick={()=>{
            inActivateUser(props.row.original._id)
           
            }} >
            <i className="fa fa-lock"></i>
            {props.row.original.isActive }
          </div>:
          <div type="button" className="btn text-green" onClick={()=>activateUser(props.row.original._id)} >
            <i className="fa fa-lock"></i>
          </div>
          } */}

{
  adminRole=="superAdmin"?
          <div type="button" className="btn text-red" onClick={()=>deleteUser(props.row.original._id)} >
            <i className="fa fa-trash"></i>
          </div>:deletePerm?<div type="button" className="btn text-red" onClick={()=>deleteUser(props.row.original._id)} >
            <i className="fa fa-trash"></i>
          </div>:null
}
        </div>
      ),
    },
  ];

  const filterTypes = useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => users, [users]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      filterTypes,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    gotoPage,
    prepareRow,
    state,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    canPreviousPage,
    canNextPage,
    preFilteredRows,
  } = tableInstance;

  const { globalFilter, filters, pageIndex } = state;

  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    return rows.filter((r) => {
      var time = new Date(r.values[id]);

      if (filterValues.length === 0) return rows;
      return time >= sd && time <= ed;
    });
  }

  dateBetweenFilterFn.autoRemove = (val) => !val;

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  fuzzyTextFilterFn.autoRemove = (val) => !val;

  const [confirm, setConfirm] = useState(false);
  const [userId, setUserId] = useState();

  const deleteUser = (id) => {
    setConfirm(true);
    setUserId(id);
  };
  const confirmDelete = () => {
    setConfirm(false);
    dispatch(deleteUserbyId(userId));
  };
  const cancelDelete = () => {
    setConfirm(false);
    setUserId();
  };

  const activateUser = (userId) => {
    dispatch(activeUser(userId,gotoPage,pageIndex));
  };

  const inActivateUser = (userId) => {
    dispatch(in_activeUser(userId,gotoPage,pageIndex));
  };

  useEffect(() => {
    dispatch(getAllUser());
    // dispatch(getMe());
  }, [dispatch]);

  return (
    <>
    <ToastContainer />
      <AdminNavbar brandText="Manage Users" />
      <UserHeader setFilter={setFilter} filter={filters} />
      {/* <div className='second_header12' >
  

    </div> */}
      {/* <div className="mod_box mt--2 moodmange fluid"> */}
        <Container className="mt--7" fluid >
        <Row>
        <div className="col">
              <Card className="shadow">
          <CardHeader>
          {/* <div className="row "> */}
          <Row>
          <Col lg={9} >
                        <h3 className="mb-0 comunity_manage">Users List</h3>
                    </Col>
            <div className="col-md-4"></div>
            {/* <div className="col-md-4">
              <div class="form-actions filter-btn mangrol">
                <a href="#">
                  <button type="button" class="btn btn-info mr-1 adrol">
                    Add User
                  </button>
                </a>
              </div>
            </div> */}
          {/* </div> */}
          </Row>
          </CardHeader>
        {/* </div> */}

        <Table className="align-items-center table-flush" responsive >
          <thead className="thead-light text-center">
            <tr>
              <th scope="col">Sr. No</th>
              <th scope="col">Picture</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Score</th>
              <th scope="col">Attempted Question</th>
              <th scope="col">Created on</th>
              {
                adminRole=="superAdmin"?
                <th>Action</th>:deletePerm||blockPerm||viewPerm?<th>Action</th>:null
              }
            </tr>
          </thead>
          
          <tbody {...getTableBodyProps()}>
            {page.map((row,index) => {
              prepareRow(row);
              return (
                <tr key={`user-list-page-${index}`} {...row.getRowProps()}>
                  {row.cells.map((cell,index) => {
                    return (
                      <td
                      key={`user-list-cell-${index}`}
                        {...cell.getCellProps()}
                        // scope="row"
                        className="text-center"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {/* {
                  users.map((usr,index)=> (
                    <tr>
                <td>
                    <img style={{width:'45px',borderRadius:'4px'}} src={`http://localhost:3009/${usr.picture}`} alt="" />
                  </td>
                  <td>{usr.name}</td>  
                  <td>{usr.email} </td>
                  <td>{usr.questionary.score} </td>     
                  <td>{usr?.questionary?.questionary.length} </td>       
                  <td>{usr.createdAt?moment(usr.createdAt).format('DD MMM YYYY'):'-'} </td>     
                    <td>
                       <div>
                        <Link type="button" class="btn text-purple " to={`/admin/customer-management/${usr._id}`}>
                          <i class="fa fa-eye"></i></Link>
                        <div type="button" class="btn text-red"><i class="fa fa-lock"></i>
                        </div>
                        <div type="button" class="btn text-red"><i class="fa fa-trash"></i></div>
                        </div>
                  </td>
                </tr>
                  ) )
                } */}
          </tbody>
        </Table>
        <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={canPreviousPage?"active":''}>
                        <PaginationLink
                          onClick={() => previousPage()}
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {pageIndex+1}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem className={canNextPage?"active":''}>
                        <PaginationLink
                          onClick={() => nextPage()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
                <Modal isOpen={confirm} >
                    <ModalHeader >Confirm delete user ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this user ?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>confirmDelete()} >Confirm Delete</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>
      {/* </div> */}
      </Card>
      </div>
      </Row>
      </Container>
    </>
  );
};

export default UserManagement;
