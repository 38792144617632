import React,{useState,useEffect} from 'react'

import{
    CardHeader, CardBody,Button
} from 'reactstrap'

import moment from "moment";
import { Link,useParams } from 'react-router-dom';
import {toast, ToastContainer } from 'react-toastify';

import { updateDiscussionById } from 'redux/api';
import {disData} from './discussionDetailData'


const DiscussionDetail = ({detail,setpostData}) => {
    const params = useParams();

    const [edit, setEdit] = useState(false);

    const [post, setpost] = useState({
        title:"",description:'',media:'',newImage:''
    })
    
       
    const handleChange=(e)=>{
        setpost({...post,[e.target.name]:e.target.value});
        if(e.target.files){           
            setpost({...post,media:e.target.files[0]});
        }
      }
    
    const handleSubmit =async(e)=>{
    e.preventDefault();
    let formdata = new FormData();
    post.title&&formdata.append('title',post.title);
    post.description&&formdata.append('description',post.description);
    post.media&& formdata.append('post',post.media);
    
    try {
        const {data} = await updateDiscussionById(params.communityId,params.postId,formdata);
        setpost({...post,title:data.data.title,description:data.data.description,media:data.data.media.content});
        detail.title=data.data.title;
        detail.description=data.data.description;
        detail.media=data.data.media.content;
        setpostData({...detail})
        toast.success(data.message,{
          autoClose: 1500,
          hideProgressBar: true
      })
        setEdit(false);
        
    } catch (error) {
      toast.error(error.response.data.message,{
        autoClose: 1500,
        hideProgressBar: true
    }) 
        console.log("error discussion post")
    }
    
    }
    
    const handleCancel=()=>{
      setEdit(false);
      setpost({
        ...post,
        title:detail.title,
        description:detail.description,
      })
    }

    useEffect(()=>{
      setpost({
        ...post,
        title:detail.title,
        description:detail.description,
      })

    },[detail])

    return (
        <>
        <ToastContainer />
        { detail&& 
          <>
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0 text-dark">Post Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/discussion" className="btn bg-gradient-danger d-btn text-white px-4">Back</Link>
                </div>
                </div>
            </CardHeader>            
            <CardBody>

            <div className="text-right mb-3" >
                  {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                  }
                  {edit?
                    <Button color="success" size='sm' onClick={handleSubmit} >
                              <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="danger" size='sm' onClick={()=>setEdit(true)} >
                              <i className="fa fa-edit"></i> Edit
                    </Button>
                      }
              </div>
                <div className="pl-lg-4">
                <div className="row">
                {/* <div className="col-lg-3">
                  <img alt="Image placeholder" 
                  src={`http://alobhaitsolution.com:3001/${post.media?post.media.content:detail.media?.content}`} 
                  width="100%"/>
                  {edit&&
                    <div className="custom-file">
                        <input type="file" name="media"
                        className="custom-file-input" onChange={handleChange}/>
                        <label className="custom-file-label" >
                            {post.media?post.media.name:'Choose file...'}
                          </label>
                    </div>
                  }
                </div> */}
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Post Title</label>
                        <input type="text" 
                        className="form-control" 
                        name="title"
                        value={post.title}
                        onChange={handleChange}
                         disabled={!edit}/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Post Description</label>
                        <input type="text" 
                         className="form-control"
                         name="description"
                         value={post.description}
                         onChange={handleChange}
                         disabled={!edit}/>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Post Create On</label>
                        <input type="text" className="form-control" value={moment(detail.createdAt).format("MMM Do YY")} disabled/>
                       </div>
                      </div>
                        
                        </div>
                        </div>
                    </div>
                    </div>
                    </CardBody>
                </>
            }                             
            
        </>
    )
}

export default DiscussionDetail
