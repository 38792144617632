import {GET_ALL_DISCUSSIONS,DELETE_DISCUSSION} from '../constants/discussionConstants';

const initialState={
    isLoading:false,
    isError:false,
    message:"",
    details:[]
}

export const discussionReducer =(state=initialState,action)=>{
    switch (action.type) {
        case GET_ALL_DISCUSSIONS:
            
            return {
                ...state,
                details:action.payload.data
            }
        case DELETE_DISCUSSION:

        const newDiss = state.details.filter(elem=>{
            return elem._id!==action.payload.postId
        })

        return {
            ...state,
            details:newDiss
        };

    
        default:
            return state;
    }
}