import React,{useState} from 'react';

import {  Container, Form,Card, CardHeader, CardBody, Button} from "reactstrap";

import { useDispatch } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';

import { toast,ToastContainer} from 'react-toastify'
import { addCommunity } from 'redux/actions/communityActions';
import UserHeader from 'components/Headers/UserHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';

const AddCommunity = () => {
    const dispatch = useDispatch();
    const history = useHistory();


    const [community, setcommunity] = useState({
        title:"",description:"",image:''
    })

    const handleChange=(e)=>{
        setcommunity({...community,[e.target.name]:e.target.value});
        if(e.target.files){           
            setcommunity({...community,image:e.target.files[0]});
        }
        
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        
        if(!community.title||!community.description||!community.image){
            toast.error('Please enter community details',{
                autoClose: 1500,
                hideProgressBar: true
            })  
            return
        }

        let formdata = new FormData();
        formdata.append('title',community.title);
        formdata.append('description',community.description);
        formdata.append('community',community.image);
        dispatch(addCommunity(formdata,history));
        clearFields();
        
    }

    const clearFields=()=>{
        setcommunity({title:"",description:"",image:''});
    }

    return (
        <>
        <AdminNavbar brandText="Add community" />
        <UserHeader/>
        <ToastContainer/>

            <Container className="mt--7" fluid>
                
            <Card>
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0">Community Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/community-management" className="btn btn-info px-4">Back</Link>
                </div>
                </div>
            </CardHeader>

           <CardBody>

            <Form >
                  <div className="form-body">
                    <div className="row p-t-20 text-white" style={{alignItems:'center'}} >
                        <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Community Name</label>
                            <input 
                                type="text"
                                name="title"
                                value={community.title}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter community Name"/>
                        </div>
                        </div>

                        <div className="col-md-3" style={{marginTop:'-23px'}}>
                            <label>Community Image</label>
                        <div className="custom-file" >
                            <input type="file" name="community" accept="image/*"
                            className="custom-file-input" onChange={handleChange}/>
                            <label className="custom-file-label" >{community.image.name?community.image.name:'Choose Image...'}</label>
                        </div>
                        </div>
                        
                        <div className="col-md-3" style={{marginTop:'8px'}} >
                        <div className="form-group ">
                            <label className="control-label">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                name="description"
                                onChange={handleChange}
                                value={community.description}
                                placeholder="Community Description"/>
                        </div>
                        </div>
                        {/* <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Approval Status</label>
                            <select className="form-control" name="status" onChange={handleChange}>
                            <option value="">Select Status</option>
                            <option>Approved</option>
                            <option>Unapproved</option>
                            </select>
                            </div>
                        </div> */}

                        <div className="col-md-3">
                        <div className="form-actions filter-btn" style={{marginTop:0}}>
                          <Button color="info" className="w-100 mr-1" onClick={clearFields} >Reset</Button>
                          <Button color="info" className="w-100 ml-1 difrnt" onClick={handleSubmit}>Add</Button>
                        </div>
                       
                       </div>
                    </div>
                  </div> 
                </Form>

            </CardBody> 
        </Card>
                
        </Container>
        
        </>
    )
}

export default AddCommunity
