import * as api from '../api/index';
import { toast } from 'react-toastify';
import {GET_ALL_DISCUSSIONS,DELETE_DISCUSSION} from '../constants/discussionConstants'


export const getAllDiscussions = ()=> async(dispatch)=>{

    try {
        const {data} = await api.getAllDiscussions();

        dispatch({type:GET_ALL_DISCUSSIONS,payload:data})
        
    } catch (error) {
        // dispatch({type:GET_ALL_DISCUSSIONS,payload:error})
        console.log(error);
    }
}

export const deleteDiscussion=(communityId,postId)=>async(dispatch)=>{

    try {
      const {data}=  await api.deleteDiscussionById(communityId,postId);

        dispatch({type:DELETE_DISCUSSION, payload:{communityId,postId}});

        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })        
    }
}