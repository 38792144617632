import {ACTIVATE_USER, DELETE_USER, FILTER_USER, GET_ALL_USER,
    GET_ALL_USER_ERROR,IN_ACTIVATE_USER

} from '../constants/userConstants';

const initialState={
    isLoading:false,
    users:[],
    isError:false,
    message:"",
    tempUser:[]

}

export const userReducer =(state=initialState,action)=>{
    
    switch (action.type) {
        case GET_ALL_USER:
            
            return {
                ...state,
                users:action.payload.data,
            }

        case GET_ALL_USER_ERROR:
        return {
            ...state,
            isError:true,
            message:action.payload.message
        }

        case DELETE_USER:
        const filterUser = state.users.filter(elem=>elem._id!==action.payload);
        return {
            ...state,
            users:filterUser
        }
        case ACTIVATE_USER:
            const activateUser = state.users.map(elem=>{
                if(elem._id===action.payload){
                    elem.isActive=true;
                    return elem
                }
                return elem
            })

            return {
                ...state,
                users:activateUser
            }


        case IN_ACTIVATE_USER:
            const updatedUser = state.users.map(elem=>{
                if(elem._id===action.payload){
                    elem.isActive=false;
                    return elem
                }
                return elem
            })
     
            return {
                ...state,
                users:updatedUser
            }
        case FILTER_USER:
            let dateFrom = new Date(action.payload.fromDate).getTime();
            let dateTo= new Date(action.payload.dateTo).getTime();
            
            const fillUser = state.users.filter(elem=>{
                let userDate = new Date(elem.createdAt).getTime();
                return userDate>dateFrom && userDate<dateTo
            })
            console.log(fillUser);
            return {
                ...state,
                tempUser:fillUser
            }

        
        default:
            return state;
    }

}