import { GET_STATIC_DATA } from "redux/constants/staticConstants"

const initialState={
    isLoading:false,
    detail:{
        about:{
            title:'',
            content:'',
            image:'',
            updatedOn:''
        },
        privacy:{
            title:'',
            content:'',
            image:'',
            updatedOn:''
        },
        terms:{
            title:'',
            content:'',
            image:'',
            updatedOn:''
        },
        welcomeSlider:[],
        updatedAt:'',
        status:false
    },
    isError:false,
    message:""

}

export const staticReducer =(state=initialState,action)=>{
     
    switch (action.type) {
      
        case GET_STATIC_DATA:

        console.log(action.payload);
        return {
            ...state,
            detail:action.payload
        }
    
        default:
            return state;
    }
}