import {GET_ALL_ADMINS,GET_ROLE_LIST,GET_PERMISSION_LIST} from '../constants/adminContants';

const initialState={
    isLoading:false,
    isError:false,
    message:"",
    login:false,
    errorMessage:'',
    data:[],
    role:[],
    permission:[]

}

export const adminUserReducer =(state=initialState,action)=>{
    
    switch (action.type) {

        case GET_ALL_ADMINS:
            return {
                ...state,
                data:action.payload
            }
        case GET_ROLE_LIST:
            return {
                ...state,
                role:action.payload
            }
        case GET_PERMISSION_LIST:
            return {
                ...state,
                permission:action.payload
            }
      
        default:
            return state;
    }
}