import React,{useEffect,useState} from 'react';

import {
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Button,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Label
  } from "reactstrap";

import { useHistory } from 'react-router-dom';
import {updatePassword} from '../../redux/api/index';
import {toast, ToastContainer } from 'react-toastify';


const PasswordChange = ({setActive}) => {

    const history = useHistory();
    const [user, setUser] = useState({
        password:'',newPassword:'',confirmPassword:""
    })
    const [message, setMessage] = useState('');
    const [showCurPass, setShowCurPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    const handleChange=(e)=>{
        setUser({...user,[e.target.name]:e.target.value})
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();

        try {
            const {data} = await updatePassword(user);
            toast.success(data.message,{
              autoClose: 1500,
              hideProgressBar: true,
            })
            // setActive(true)
            // history.push('/admin/profile');
            setMessage('');
            console.log(data);
        } catch (error) {    
          //  setMessage(error.response.data.message);
           toast.error(error?.response?.data?.message,{
            autoClose: 1500,
            hideProgressBar: true,
          })
            console.log("update password error")
        }
    }

    const handleCancel=(e)=>{
      e.preventDefault();
      setUser({password:'',newPassword:'',confPassword:"" })

    }

    return (
        <Form>
           <ToastContainer/>
            <div className="pl-lg-4">
                    <Row className="m-auto" >
                      <Col lg="6" >
                        <FormGroup>
                          <Label
                            className="form-control-label propass_update"
                            htmlFor="input-first-name"
                          >
                            Current Password
                          </Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="password"
                            placeholder="Enter your current password"
                            type={showCurPass?"text":"password"}
                            value={user.password}
                            onChange={handleChange}
                          />
                          <InputGroupAddon addonType="prepend">
                    <InputGroupText
                    onClick={()=>setShowCurPass(!showCurPass)}
                    >
                    {
                      showCurPass?
                      <i className="fa fa-eye-slash text-secondary" />:
                      <i className="fa fa-eye text-secondary" />
                    }
                    </InputGroupText>
                  </InputGroupAddon>
                  </InputGroup>
                        </FormGroup>
                      </Col>
                   
                      <Col lg="6">
                        <FormGroup>
                          <Label
                            className="form-control-label propass_update"
                            htmlFor="input-email"
                          >
                            New Password
                          </Label>
                          <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="newPassword"
                            placeholder="Enter your New password"
                            type={showNewPass?"text":"password"}
                            value={user.newPassword}
                            onChange={handleChange}
                            
                          />
                          <InputGroupAddon addonType="prepend">
                    <InputGroupText
                     onClick={()=>setShowNewPass(!showNewPass)}
                    >
                    {
                      showNewPass?
                      <i className="fa fa-eye-slash text-secondary" />:
                      <i className="fa fa-eye text-secondary" />
                    }
                    </InputGroupText>
                  </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Confirm Password
                          </label>
                          <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            name="confirmPassword"
                            placeholder="Re-Enter New password"
                            type={showConfPass?"text":"password"}
                            value={user.confirmPassword}
                            onChange={handleChange}
                            
                          />
                          <InputGroupAddon addonType="prepend">
                    <InputGroupText
                     onClick={()=>setShowConfPass(!showConfPass)}
                    >
                    {
                      showConfPass?
                      <i className="fa fa-eye-slash text-secondary" />:
                      <i className="fa fa-eye text-secondary" />
                    }
                    </InputGroupText>
                  </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-center text-danger">
                    {message&&message}

                    </div>
                    <div className="pl-lg-4 pb-4">
                    <Col lg={6} className="m-auto text-center" >
                      <Button color="danger" onClick={handleCancel} > Cancel</Button>
                      <Button className='btn difrnt' onClick={handleSubmit} >Update</Button>
                    </Col>
                  </div>
                    </div>
        </Form>
    )
}

export default PasswordChange
