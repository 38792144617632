import React,{ useEffect,useState,useMemo } from "react";

import {
    Card,
    CardHeader,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Button,
    Row,Modal, ModalHeader, ModalBody, ModalFooter,Col
  } from "reactstrap";
  // core components
import moment from "moment";
import {matchSorter} from 'match-sorter'

  import { ToastContainer } from "react-toastify";
  import { useDispatch, useSelector} from "react-redux";
  import { Link } from "react-router-dom";
  import { useTable,useSortBy,useGlobalFilter,useFilters,usePagination } from "react-table";
    
  import {getAllUser, getMe,deleteUserbyId,activeUser,in_activeUser} from '../../redux/actions/userActions';
  import {getAdminUserAction} from '../../redux/actions/adminAction';
  import AdminNavbar from "components/Navbars/AdminNavbar";
 

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const User = () => {

    const dispatch = useDispatch();
    const users = useSelector(state=>state.adminUser.data);
    


    const filterTypes = useMemo(
      () => ({
          dateBetween: dateBetweenFilterFn,
          fuzzyText: fuzzyTextFilterFn,
          text: (rows, id, filterValue) => {
              return rows.filter(row => {
                  const rowValue = row.values[id];
                  return rowValue !== undefined
                      ? String(rowValue)
                          .toLowerCase()
                          .startsWith(String(filterValue).toLowerCase())
                      : true;
              });
          }
      }),
      []
    );

    const defaultColumn = useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    
    const COLUMNS=[
      {
          Header:'Sr. No',
          accessor:'',
          Cell:(props)=> props.row.index+1
      },
      {
          Header:'User Name',
          accessor:'name', 
      },
      {
          Header:'Role',
          accessor:'roleId.roleName', 
      },
      // {
      //     Header:'Email',
      //     accessor:'email',
      // },
      {
          Header:'Actions',
          accessor:'isActive',
          disableSortBy:true,
          Cell: (props)=>(
             <div>
                  <Link 
                      to={{pathname:`/admin/admin-management/${props.row.original._id}`
                          }} 
                      type="button" 
                      className="btn text-purple ">
                      <i className="fa fa-eye"></i>
                  </Link>
                  {/* <div  type="button" className="btn text-dark">
                        <i className="fa fa-edit"></i>
                  </div> */}
               
                  <div  type="button" className="btn text-red" onClick={()=>deleteUser(props.row.original._id)} >
                        <i className="fa fa-trash"></i>
                  </div>
               </div>
           )      
      }
  ]


    const columns = useMemo(()=> COLUMNS,[]);
    const data = useMemo(()=>users,[users]);

    const tableInstance = useTable({
      columns: columns,
      data: data,
      filterTypes,
      defaultColumn
    },useFilters,useGlobalFilter,useSortBy,usePagination)

  const{getTableProps,getTableBodyProps,
    headerGroups,rows,page,nextPage,previousPage,prepareRow,state,setGlobalFilter,setFilter,setAllFilters,
    canPreviousPage,canNextPage,preFilteredRows
  } = tableInstance;

  const {globalFilter,filters,pageIndex} = state;


  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

dateBetweenFilterFn.autoRemove = val => !val;


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val


    const [confirm, setConfirm] = useState(false);
    const [userId, setUserId] = useState();
   
    const deleteUser = (id)=>{
      setConfirm(true);
      setUserId(id);
    }
    const confirmDelete=()=>{
     setConfirm(false);
      dispatch(deleteUserbyId(userId))
    }
    const cancelDelete=()=>{
      setConfirm(false);
      setUserId();
    }

    const activateUser=(userId)=>{
      dispatch(activeUser(userId))
    }

    const inActivateUser=(userId)=>{
      dispatch(in_activeUser(userId))
    }

    useEffect(()=>{
      dispatch(getAdminUserAction());
      dispatch(getMe());
    },[])
    return (
      <>
        <AdminNavbar filter={globalFilter} setFilter={setGlobalFilter} brandText="System User Management" />
        <ToastContainer />
        {/* Page content */}
        <Container className="" fluid>
          {/* Table */}
          <Row>
            <div className="col" style={{marginTop:'110px'}}>
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                    <Col lg={9} >
                        <h3 className="mb-0 comunity_manage">System Users List</h3>
                    </Col>
                    <Col lg="3">
                      <Link to="/admin/admin-management/add-user"  className="float-right">
                        <Button color="info">Add System User</Button>
                      </Link>
                    </Col>
                  </Row>

                </CardHeader>
                  <Modal isOpen={confirm} >
                      <ModalHeader >Confirm delete user ?</ModalHeader>
                      <ModalBody>
                        Are you sure you want to delete this user ?
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={()=>confirmDelete()} >Confirm Delete</Button>{' '}
                        <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                      </ModalFooter>
                </Modal>

                <Table {...getTableProps()} className="align-items-center table-flush" responsive >
                  <thead className="thead-light">
                    {headerGroups.map((headerGroup)=> (
                      <tr {...headerGroup.getHeaderGroupProps()} >
                        {
                          headerGroup.headers.map(column=> (
                            <th {...column.getHeaderProps(column.getSortByToggleProps)} scope="col" className="text-center"  >
                              {column.render('Header')}
                                                                                         
                              <span>
                                {column.isSorted?(column.isSortedDesc?'🔽':'🔼'):null }
                              </span>     
                            </th>
                          ))
                        }
                      </tr>
                    ) )}
                  </thead>
                  {rows.length!==0?
                  <tbody {...getTableBodyProps()} >
                    {
                      page.map(row=>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <td {...cell.getCellProps()} scope="row" className="text-center" >
                                   {cell.render('Cell')}
                                                                                  
                                  </td>
                                )
                              } )}                     
                            </tr>
                        )
                      })
                    }
                  </tbody>:
                  <h2 className="text-center" >No Data Available</h2>}
                </Table>


                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={canPreviousPage?"active":''}>
                        <PaginationLink
                          onClick={() => previousPage()}
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {pageIndex+1}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem className={canNextPage?"active":''}>
                        <PaginationLink
                          onClick={() => nextPage()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          
        </Container>
      </>
    );
  };
  
  export default User;
  

  function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    )
  }