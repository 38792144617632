import React, { useState,useEffect } from "react";

import {
  Container,
  Form,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,Row,Col
} from "reactstrap";

import { useDispatch,useSelector } from "react-redux";
import { Link, useHistory,useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {fetchAdminRoleList} from '../../redux/actions/adminAction';

import UserHeader from "components/Headers/UserHeader";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { getAdminUserById,updateOneAdminuser } from "redux/api";

const ViewSystemUser = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const roleList = useSelector(state=>state.adminUser.role);

  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    roleId:""
  });
  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
    
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    // return
    try {
        
            if (!admin.name) {
              toast.error("Please add Admin name", {
                autoClose: 1500,
                hideProgressBar: true,
              });
              return;
            }
        const {data} = await updateOneAdminuser(params.adminUserId,admin);
        toast.success(data?.message||"Admin user successfully updated", {
          autoClose: 1500,
          hideProgressBar: true,
        });
           
        // history.push('/admin/admin-management')
            // clearFields();
            setIsEdit(false);
    } catch (error) {
        console.log('add admin user error')
        toast.error(error?.response?.data?.message, {
            autoClose: 1500,
            hideProgressBar: true,
          });
    }
   
  };

  const handleCancel =()=>{
    setIsEdit(false);
  }

  const fetchAdminDetail = async()=>{
    try {
      const {data} = await getAdminUserById(params.adminUserId);
      setAdmin({
        ...admin,
        name:data.data.name,
        email:data.data.email,
        roleId:data.data.roleId,
        role:data.data.role
      })
    } catch (error) {
      console.log('fetch admin user error')
    }
  }

  const clearFields = () => {
    setAdmin({ name: "", email: "", password: "", role: "",roleId:'' });
  };

  useEffect(()=>{
    fetchAdminDetail();
    dispatch(fetchAdminRoleList());
  },[])

  return (
    <>
      <AdminNavbar brandText="View System user Detail" />
      <UserHeader />
      <ToastContainer />
      <Container className="mt--7" fluid>
        <Card>
          <CardHeader>
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0 capdtl">View System User </h3>
              </div>
              <div className="col-4 text-right">
                <Link
                  to="/admin/admin-management"
                  className="btn btn-info  px-4 capwhite"
                >
                  Back
                </Link>
              </div>
            </div>
          </CardHeader>

          <div className="col-md-12">
          
          <div className="text-right mt-3" >
   {isEdit&&< Button color="danger" size='sm' 
   onClick={handleCancel}
    >
             <i className="fa fa-times"></i> Cancel
     </Button>
   }
   {isEdit?
     <Button color="success" size='sm' 
     onClick={handleSubmit} 
     >
               <i className="fa fa-check"></i> Done
     </Button>
     :
     <Button color="info" size='sm' 
     onClick={()=>setIsEdit(true)} 
     >
               <i className="fa fa-edit"></i> Edit
     </Button>
       }
</div>


</div>

          <CardBody>
            <Form onSubmit={handleSubmit} onReset={clearFields} >
              <div className="form-body">
                <div className="row p-t-20 text-white">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="control-label">System User Name</label>
                      <input
                        type="text"
                        name="name"
                        value={admin.name}
                        required
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Admin Name"
                        disabled={!isEdit}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="exampleSelect">Admin Role</Label>
                      <Input type="select" 
                      name="roleId"
                      required
                      disabled={!isEdit}
                      placeholder="Admin Role"
                      value={admin.roleId}
                      onChange={handleChange}
                       id="role">
                         <option value={0} >Select Role</option>
                        {roleList.map(role=>(
                          <option value={role._id} >{role.roleName}</option>
                        )) }
                        {/* <option value={"Content Capsule"} >Content Capsule</option>
                        <option value={"Alobha Staff"} >Alobha Staff</option>
                        <option value={"Developer"} >Developer</option>
                        <option value={"Tester"} >Tester</option> */}
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="adminEmail">Email</Label>
                      <Input
                      required
                        type="email"
                        name="email"
                        id="email"
                        value={admin.email}
                        onChange={handleChange}
                        disabled={!isEdit}
                        placeholder="Admin Email"
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="text"
                        name="password"
                        id="password"
                        value={admin.password}
                        required
                        onChange={handleChange}
                        placeholder="Admin Password"
                        disabled={!isEdit}
                      />
                    </FormGroup>
                  </div>

                </div>
                {/* <Row className="justify-content-md-center" >
                    <Col md={3} >
                   
                    <div
                      className="form-actions filter-btn"
                      style={{ marginTop: "30px" }}
                    >
                      <Button
                        color="info"
                        className="btn btn-info w-100 mr-1"
                        onClick={clearFields}
                        type="reset"
                      >
                        Reset
                      </Button>
                      <Button
                        color=""
                        className="w-100 btn difrnt ml-1"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </div>
                 
                    </Col>
                </Row> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ViewSystemUser;
