import React,{useState} from "react";

import {
    Table,
    Modal,ModalBody,ModalFooter,ModalHeader,Button,Card,CardHeader,CardBody,CardFooter,Pagination,PaginationItem,
    PaginationLink
  } from "reactstrap";

  import { Link } from "react-router-dom";
  import moment from "moment";
  import ReactPlayer from "react-player";
  import {toast, ToastContainer} from 'react-toastify';

  import { deleteJournalById } from "redux/api";

const UserJournal = ({journal=[],user,setUser}) => {

  const [confirm, setConfirm] = useState(false);
    const [journalId, setJournalId] = useState();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);


  const deletePopup = (id)=>{
    setConfirm(true);
    setJournalId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setJournalId();
  }

  const handleDelete=async()=>{
    try {
      const {data} = await deleteJournalById(journalId);
      const newJournal = journal.filter(elem=>elem._id!==journalId);
      setUser({...user,journal:newJournal});
      toast.success(data.message,{
        autoClose: 1500,
        hideProgressBar: true
    })
    setConfirm(false);
    } catch (error) {
      toast.error(error.response.data.message,{
        autoClose: 1500,
        hideProgressBar: true
    }) 
    setConfirm(false);
    }

  }

  return (
    <>
    <ToastContainer/>
    <Card>
    <CardBody>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
      
          <tr>
            <th scope="col">sr. No</th>
            <th scope="col">Journal Image</th>
            <th scope="col">Journal Title</th>
            <th scope="col">Journal Story</th>
            <th scope="col">Created on</th>
            <th scope="col" className="text-center">
              Action
            </th>
          </tr>
        
        </thead>
       
        <tbody>
          {journal.length > 0 && 
                  (rowsPerPage > 0
                    ? journal.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : journal
                  ).map((elem,index) => (
            <tr key={elem._id}>
              <td>{page * rowsPerPage + (index + 1)}  </td>
              <td>
                
                {/* {elem.journal.type==='video'&&
                      <ReactPlayer controls width='190px' height='120px'
                      url={`${process.env.REACT_APP_BASE_URL}${elem.journal.content}`} />
                } */}
                
                  <img style={{width:'190px',height:'120px',objectFit:'cover'}} src={`${process.env.REACT_APP_BASE_URL}${elem.journal.image}`} alt="" />
              
                        
                </td>
              <td>{elem.journal.title}</td>
              <td>{elem.journal.story}</td>
              <td>{moment(elem.createdAt).format("MMM Do YY")}</td>
              <td className="">
                             
                <Link
                  to={`/admin/journal/${elem._id}`}
                  type="button"
                  className="btn text-purple"
                >
                  <i className="fa fa-eye"></i>
                </Link>
                <div
                  
                  type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(elem._id)}
                >
                  <i className="fa fa-trash"></i>
                </div>
              </td>
            </tr>
                ))}
        </tbody>
      </Table>
      </CardBody>
        <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem 
                      className={page!==0?"active":''}
                      >
                        <PaginationLink
                         onClick={() =>{
if(page>0){
   setPage(prevPage=>prevPage-1)
}
                         } 
                        }
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {page+1}
                          {/* {pageIndex+1} */}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem 
                      className={Math.ceil(journal.length/rowsPerPage)>(page+1)?"active":''}
                      >
                        <PaginationLink
                          onClick={() => {
                            if(Math.ceil(journal.length/rowsPerPage)>(page+1)){
                              setPage(prevPage=>prevPage+1)

                            }

                          }
                          }
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
                </Card>
        <Modal isOpen={confirm} >
                    <ModalHeader >Confirm delete journal ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this journal ?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm Delete</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>
    </>
  );
};

export default UserJournal;
