import React,{useState} from "react";
import {
  Card,
    Media,
    Table,
    Button,Modal,ModalBody,ModalHeader,ModalFooter
  } from "reactstrap";

  import { useParams } from "react-router-dom";
import { removeUserFromCommunity, approveUser,unApproveUser } from "redux/api";
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

const CommunityMember = ({member,setCommunity,community}) => {
  const params = useParams();

  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const deletePerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/community/:communityId/remove/:userId");
  const unapprovePerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/community/:communityId/approveUser/:userId");

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }
  
  const handleRemove=async()=>{
    try {
      const {data} = await removeUserFromCommunity(params.communityId,confirmId);
      const newMem = member.filter(elem=>elem.user._id!==confirmId);
      setCommunity({...community,
          community:{...community.community,
                    member:newMem  }
              })  
              toast.success(data.message,{
                autoClose: 1500,
                hideProgressBar: true,
              })
              cancelDelete();
            } catch (error) {              
              toast.error(error.response.data.message,{
                autoClose: 1500,
                hideProgressBar: true,
              })
              cancelDelete();
            }
            
  }
  const handleApprove=async(userId)=>{
   try {
     await approveUser(params.communityId,userId)
        const comInd = community.community.member.findIndex(mem=>mem.user._id===userId);
        community.community.member[comInd].isApproved=true;
        setCommunity({...community,
            community:{...community.community, 
                member:[...community.community.member]}   })
                toast.success("Approved user in community",{
                  autoClose: 1500,
                  hideProgressBar: true
                })
   } catch (error) {
    
     toast.error(error.response.data.message,{
        autoClose: 1500,
        hideProgressBar: true,
      })
   }
      
  }

  const handleUnApprove=async(userId)=>{
   try {
     await unApproveUser(params.communityId,userId)
        const comInd = community.community.member.findIndex(mem=>mem.user._id===userId);
        community.community.member[comInd].isApproved=false;
        setCommunity({...community,
            community:{...community.community, 
                member:[...community.community.member]}   })
                toast.success("Un-approved user in community",{
                  autoClose: 1500,
                  hideProgressBar: true
                })
   } catch (error) {
    
     toast.error(error.response.data.message,{
        autoClose: 1500,
        hideProgressBar: true,
      })
   }
      
  }

  return (
    <>
    <ToastContainer />
    <Card>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Image</th>
            <th scope="col">User Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            {
              adminRole=="superAdmin"?
              <th scope="col" className="text-center">
              Action
            </th>:unapprovePerm||deletePerm? <th scope="col" className="text-center">
              Action
            </th>:null
            }
            
          </tr>
        </thead>
        <tbody>
          {member&&member.map((elem) => (
            <tr key={elem._id}>
              <th scope="row">
                <Media
                  className="align-items-center"
                  style={{ height: "80px", width: "80px" }}
                >
                  <img
                    alt="..."
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    className="rounded-circle fluid"
                   src={`${process.env.REACT_APP_BASE_URL}${elem.user.picture}`}
                  />
                </Media>
              </th>
              <td>{elem.user.name}</td>
              <td>{elem.user.email}</td>
              {
                adminRole=="superAdmin"?
              <td>
                      {elem.isApproved?(
                          <Button size='sm' color="danger" onClick={()=>handleUnApprove(elem.user._id)}  >Unapprove</Button>
                      ):
                      (
                        <Button color="success" size='sm' onClick={()=>handleApprove(elem.user._id)}   >Approve</Button>
                    )
                      }
                      </td>:unapprovePerm?<td>
                      {elem.isApproved?(
                          <Button size='sm' color="danger" onClick={()=>handleUnApprove(elem.user._id)}  >Unapprove</Button>
                      ):
                      (
                        <Button color="success" size='sm' onClick={()=>handleApprove(elem.user._id)}   >Approve</Button>
                    )
                      }
                      </td>:null
              }

              {
                adminRole=="superAdmin"?
                <td 
              // className="d-flex justify-content-center"
              >                
                <span
                  type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(elem.user._id)}
                >
                  <i className="fa fa-trash"></i>
                </span>
              </td>:deletePerm?<td 
              // className="d-flex justify-content-center"
              >                
                <span
                  type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(elem.user._id)}
                >
                  <i className="fa fa-trash"></i>
                </span>
              </td>:null
              }

              {/* <td 
              // className="d-flex justify-content-center"
              >                
                <span
                  type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(elem.user._id)}
                >
                  <i className="fa fa-trash"></i>
                </span>
              </td> */}
            </tr>
          ))} 
        </tbody>
          <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to remove this user from community ?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleRemove()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
            </Modal>
      </Table>
    </Card>
    </>
  );
};

export default CommunityMember;
