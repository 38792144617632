import React,{useEffect,useState,useMemo} from 'react';

import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,Modal,ModalHeader,ModalBody,ModalFooter,Button
  } from "reactstrap";

  import moment from "moment";
  import {ToastContainer } from 'react-toastify';
import DiscussionHeader from './DiscussionHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { useTable,useSortBy,useGlobalFilter,useFilters,usePagination } from "react-table";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector} from "react-redux";
import {getAllDiscussions,deleteDiscussion} from '../../redux/actions/discussionsActions';
import { pData } from './discussionData';


const Discussion = () => {

    const dispatch = useDispatch();
    const discussion = useSelector(state=>state.discussion.details);

    const filterTypes = useMemo(
      () => ({
          dateBetween: dateBetweenFilterFn,
          text: (rows, id, filterValue) => {
              return rows.filter(row => {
                  const rowValue = row.values[id];
                  return rowValue !== undefined
                      ? String(rowValue)
                          .toLowerCase()
                          .startsWith(String(filterValue).toLowerCase())
                      : true;
              });
          }
      }),
      []
    );

    const COLUMNS=[
      {
          Header:'Sr. No',
          accessor:'',
          Cell:(props)=>props.row.index+1
          
      },
      {
          Header:'Community name',
          accessor:'community.title',
      },
      {
          Header:'User name',
          accessor:"creator.name"
      },
      {
          Header:'View',
          accessor:"view.length",
      },
        {
            Header:'Reply',
            accessor:'reply.length'
        },
        {
          Header: 'Likes',
          accessor:'likes.length'
        },
        {
          Header: 'Created on',
          accessor:'createdAt',
          filter:'dateBetween',
          Cell: (props)=> {return moment(props.row.original.createdAt).format("MMM Do YY")}

        },
        {
            Header:'Actions',
            Cell: (props)=>(
              <div >
                <Link
                  to={`/admin/discussion/${props.row.original.community._id}/${props.row.original._id}`} 
                    type="button" className="btn text-purple ">
                      <i className="fa fa-eye"></i>
                  </Link>
                  
                  <div type="button"
                    className="btn text-red"
                    onClick={()=>deletePopup(props.row.original.community._id,props.row.original._id)}
                    >
                    <i className="fa fa-trash"></i>
                  </div>
              </div>
            )
  
        },
      
  ]

  const columns = useMemo(()=> COLUMNS,[]);
    const data = useMemo(()=>discussion,[discussion]);
    const tableInstance = useTable({
      columns: columns,
      data: data,
      filterTypes
    },useFilters,useGlobalFilter,useSortBy,usePagination)

  const{getTableProps,getTableBodyProps,
    headerGroups,rows,page,prepareRow,state,setGlobalFilter,setFilter,
    nextPage,previousPage,canPreviousPage,canNextPage
  } = tableInstance;

  const {globalFilter,filters,pageIndex} = state

  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState({id1:'',id2:''});

  const deletePopup = (communityId,postId)=>{
    setConfirm(true);
    setConfirmId({...confirm,id1:communityId,id2:postId});
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }

  const handleDelete=()=>{
    dispatch(deleteDiscussion(confirmId.id1,confirmId.id2));
    cancelDelete();
  }

    useEffect(()=>{
      dispatch(getAllDiscussions());
    },[])

    return (
        <div>
          <ToastContainer  />
            <DiscussionHeader setFilter={setFilter} filter={filters}/>
            <AdminNavbar filter={globalFilter} setFilter={setGlobalFilter} brandText="Post management" />
            <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0 comunity_manage">Post Management</h3>
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Community Name</th>
                      <th scope="col">User Name</th>
                      <th scope="col">Views</th>
                      <th scope="col">Reply</th>
                      <th scope="col">Likes</th>
                      <th scope="col">Created At</th>
                      <th scope="col" className="text-center" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {discussion.map((elem,index)=>(
                    <tr key={index}>
                      <td>{elem.section.title}</td>
                      <td>
                      {elem.creator.name}
                        
                      </td>
                      <td>
                      {elem.view.length}
                        
                      </td>
                      <td>
                        {elem.reply.length}
                      </td>

                      <td>
                      {elem.likes.length}
                      </td>
                      
                      <td>
                      {moment(elem.createdAt).format("MMM Do YY")}
                      </td>
                      <td className="d-flex justify-content-center"> 
                        <Link to={`/admin/discussion/${elem.section._id}/${elem._id}`}
                        type="button" className="btn text-yellow">
                          <i className="fa fa-eye"></i>
                        </Link>
                        <div type="button"
                        className="btn text-red" onClick={()=>removeDiscussion(elem.section._id,elem._id)}  >
                          <i className="fa fa-trash"></i>
                        </div>
                    </td> 
                    </tr>

                    ))}
                  </tbody>
                </Table> */}


          <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this community post?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>

              <Table {...getTableProps()} className="align-items-center table-flush" responsive >
                  <thead className="thead-light text-center">
                    {headerGroups.map((headerGroup)=> (
                      <tr {...headerGroup.getHeaderGroupProps()} >
                        {
                          headerGroup.headers.map(column=> (
                            <th {...column.getHeaderProps(column.getSortByToggleProps)} scope="col"  >
                              {column.render('Header')} 
                              <span>
                                {column.isSorted?(column.isSortedDesc?'🔽':'🔼'):null }
                              </span>     
                            </th>
                          ))
                        }
                      </tr>
                    ) )}
                  </thead>
                  {rows.length!==0?
                  <tbody {...getTableBodyProps()} >
                    {
                      page.map(row=>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <td {...cell.getCellProps()} scope="row" className="text-center" >
                                   {cell.render('Cell')}
                                                                                  
                                  </td>
                                )
                              } )}                     
                            </tr>
                        )
                      })
                    }
                  </tbody>
                   :<h2 className="text-center" >No Data Available</h2>}
                </Table>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                  <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={canPreviousPage?"active":''}>
                        <PaginationLink
                          onClick={() => previousPage()}
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {pageIndex+1}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem className={canNextPage?"active":''}>
                        <PaginationLink
                          onClick={() => nextPage()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          
        </Container>
            
        </div>
    )
}

export default Discussion
