import {GET_ME,UPDATE_PROFILE,UPDATE_PICTURE} from '../constants/userConstants';
import {ADMIN_LOGIN,LOGOUT,ADMIN_LOGIN_ERROR} from '../constants/authConstants';

const initialState={
    isLoading:false,
    info:{
        _id:"",
        email:"",
        role:""
    },
    isError:false,
    message:"",
    login:false,
    errorMessage:'',
    permissions:{
        permissionList:[],
        ids:[]
    }
}

export const authReducer =(state=initialState,action)=>{
    
    switch (action.type) {
      
        case GET_ME:
        return {
            ...state,
            info:{
                ...state.info,
                ...action.payload.data.details
            },
            permissions:{
                ...state.permissions,
                permissionList:action.payload.data.details.roleId.permissionPath,
                ids:action.payload.data.details.roleId.permissions,
            }
        }
        case ADMIN_LOGIN:  
        return {
            ...state,
            info:action.payload.data,
            login:true,
            permissions:{
                ...state.permissions,
                permissionList:action.payload.data.roleId.permissionPath,
                ids:action.payload.data.roleId.permissions,
            }
        }
        case ADMIN_LOGIN_ERROR:
            return {
                state,
                errorMessage:action.payload
            }        
        case UPDATE_PROFILE:
            console.log(action.payload);
            return {
                ...state,
                info:{...state.info,details:action.payload.data}
            }
        case UPDATE_PICTURE:
            console.log(action.payload.data)
            return {
                ...state,
                info:{...state.info,details:action.payload.data}
            }
        case LOGOUT:
            console.log("logout reducer")
            return {
                ...state,
                login:false,
                info:{
                    _id:"",
                    email:""
                },
                permissions:{
                    permissionList:[],
                    ids:[]
                }

            };

        default:
            return state;
    }
}