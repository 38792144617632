import * as api from '../api/index';
import { toast } from 'react-toastify';

import{GET_ALL_COMMUNITY,APPROVE_COMMUNITY,
     ADD_COMMUNITY,DELETE_COMMUNITY,
     APPROVE_USER_COMMUNITY
    } from '../constants/communityConstants';

export const getAllCommunity = ()=> async(dispatch)=>{
    try {

        const {data} = await api.getAllCommunity();

        dispatch({type:GET_ALL_COMMUNITY,payload:data})
                
    } catch (error) {
        console.log(error.message);
    }
}

export const approveCommunity = (id)=> async(dispatch)=>{
    try {

      const {data} = await api.approveCommunity(id);

        dispatch({type:APPROVE_COMMUNITY,payload:id});
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }
}

export const addCommunity=(postData,history)=>async(dispatch)=>{

    try {
        
        const {data} = await api.addCommunity(postData);

        dispatch({type:ADD_COMMUNITY,payload:data.data});
            toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        history.push('/admin/community-management');
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })      
    }
}

export const deleteCommunity=(id)=>async(dispatch)=>{

    try{
      const {data} = await api.deleteCommunity(id);

        dispatch({type:DELETE_COMMUNITY,payload:id});
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    }catch (error){
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    }
}

export const approveUserInCommunity=(communityId,userId)=>async(dispatch)=>{

    try {
       await api.approveUser(communityId,userId);

        dispatch({type:APPROVE_USER_COMMUNITY,payload:{communityId,userId}});
        toast.success('User approved in community',{
            autoClose: 1500,
            hideProgressBar: true
        })
        
    } catch (error) {
        
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    }

}