import React,{useState} from 'react';
import {  Container} from "reactstrap";

const DiscussionHeader = ({setFilter}) => {


    const [value, setValue] = useState({
        fromDate:'',toDate:'',community:'',user:''
      })

    const handleChange=(e)=>{
        e.preventDefault();
        setValue({...value,[e.target.name]:e.target.value});
    }

    const handleSubmit=(e)=>{
    e.preventDefault();
       setFilter("community.title",value.community);
       setFilter('creator.name',value.user) ;
       let dateFilter=[];
       const sd1 = new Date(value.fromDate).setHours(0,0,0);
    const sd = new Date(sd1);
    const ed1 = new Date(value.toDate).setHours(23,59,0);
    const ed = new Date(ed1);
   if(value.fromDate&&value.toDate){
    dateFilter[0]=sd;
    dateFilter[1]=ed;

   }
   setFilter('createdAt',dateFilter)
      }
    
      const handleCancel=(e)=>{
        e.preventDefault();
        setValue({fromDate:'',toDate:'',community:'',user:''})
        setFilter("community.title",'');
        setFilter('createdAt','')
        setFilter('creator.name','') 
      }



    return (
        <>

        <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
                <Container fluid>
                <div className="header-body">

                    <form >
                        <div className="form-body">
                            <div className="row p-t-20 text-white">
                            <div className="col-md-3">
                                <div className="form-group ">
                                <label className="control-label">From Date</label>
                                <input type="date" className="form-control"
                                name='fromDate'
                                max={value.toDate}
                                onChange={handleChange}
                                value={value.fromDate}
                                />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group ">
                                <label className="control-label">To Date</label>
                                <input type="date"
                                className="form-control"
                                name="toDate"
                                min={value.fromDate}
                                value={value.toDate}
                                onChange={handleChange} 
                                />
                                </div>
                            </div>

                                <div className="col-md-3">
                                <div className="form-group ">
                                    <label className="control-label">Community name</label>
                                    <input type="text"
                                    className="form-control" 
                                    placeholder="Enter Community name"
                                    name='community' 
                                    value={value.community}
                                    onChange={handleChange}
                                    />
                                </div>
                                </div>

                                <div className="col-md-3">
                                <div className="form-group ">
                                    <label className="control-label">User name</label>
                                    <input type="text"
                                    className="form-control"
                                    placeholder="Enter user name"
                                    name='user' 
                                    value={value.user}
                                    onChange={handleChange}
                                    />
                                </div>
                                </div>

                                <div className="col-md-3">
                                <div className="form-actions filter-btn">
                                <button type="button" className="btn d-btn btn-info w-100 mr-1" onClick={handleCancel} >Reset</button>
                                <button type="submit" className="btn difrnt w-100 ml-1" onClick={handleSubmit} >Search</button>
                                </div>
                            </div>
                            </div>
                        </div> 
                        </form>


                        </div>
                </Container>
            </div>
            
        </>
    )
}

export default DiscussionHeader
