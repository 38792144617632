import { useState,useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts2.js";

import Header from "components/Headers/Header.js";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { userStatsWeekly,discussionStats,userStatsMonthly} from "../redux/api/index";
import { getAllUser } from "redux/actions/userActions";
import { getAllDiscussions } from "redux/actions/discussionsActions";
import { getAllCommunity } from "redux/actions/communityActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeNav, setActiveNav] = useState(1);
  // const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [weeklyStats, setWeeklyStats] = useState([]);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [discussionData, setDiscussionData] = useState([]);
  const [isWeeklyStat, setIsWeeklyStat] = useState(true);
  const [isMonthlyStat, setIsMonthlyStat] = useState(true);
  const [isDiscussionStat, setIsDiscussionStat] = useState(true);
  const [lastWeekUser, setLastWeekUser] = useState([]);
  const [lastMonthDiscussion, setlastMonthDiscussion] = useState(0);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    
  };
  
  const handleisData=()=>{
    if(Math.min(...weeklyStats)===0&&Math.max(...weeklyStats)===0){
      setIsWeeklyStat(false);
    }
    if(Math.min(...monthlyStats)===0&&Math.max(...monthlyStats)===0){
      setIsMonthlyStat(false)
    }
    if(Math.min(...discussionData)===0&&Math.max(...discussionData)===0){
      setIsDiscussionStat(false)
    }
  }

  const getUserWeekly=async()=>{
      
    try {
      const {data} = await userStatsWeekly();    
      let sortedData = data.data.sort((a,b)=>a._id-b._id);
      let result=[];
      result.length=7;

      let day=[1,2,3,4,5,6,7];

      day.forEach((elem,i)=>{
        
        let sortInd = sortedData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          result[sortedData[sortInd]._id-1]= sortedData[sortInd].count
        }else{
          result[i]=0
        }

      })
      if(Math.min(...result)===0&&Math.max(...result)===0){
        setIsWeeklyStat(false);
      } else setIsWeeklyStat(true);

      console.log(result)
      setWeeklyStats(result);
      setLastWeekUser(result);
      
    } catch (error) {
      console.log('error getting weekly user');
    }
  }

  const getMonthlyStats=async()=>{
   
    try {
      const {data} = await userStatsMonthly();
      let sortedMonthlyData = data.data.sort((a,b)=>a._id-b._id);
      let monthlyResult=[];
      monthlyResult.length=12;
      let month=[1,2,3,4,5,6,7,8,9,10,11,12];

      month.forEach((elem,i)=>{
        let sortInd = sortedMonthlyData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          monthlyResult[sortedMonthlyData[sortInd]._id-1]= sortedMonthlyData[sortInd].count
        }else{
          monthlyResult[i]=0
        }

      })
      setMonthlyStats(monthlyResult);
      if(Math.min(...monthlyResult)===0&&Math.max(...monthlyResult)===0){
        setIsMonthlyStat(false);
      } else setIsMonthlyStat(true);
      
    } catch (error) {
      console.log('error getting monthly user');
    }
  }

  const getDiscussionStat=async()=>{
    
    try {
      const {data} = await discussionStats();

      let sortedData = data.data.sort((a,b)=>a._id-b._id);
      let result=[];
      result.length=7;

      let day=[1,2,3,4,5,6,7];

      day.forEach((elem,i)=>{
        
        let sortInd = sortedData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          result[sortedData[sortInd]._id-1]= sortedData[sortInd].count
        }else{
          result[i]=0
        }
      })

    //  let disVal = sortedData.map(elem=>elem.count);
      setDiscussionData(result);
      if(Math.min(...result)===0&&Math.max(...result)===0){
        setIsDiscussionStat(false);
      } else setIsDiscussionStat(true);
      
    } catch (error) {
      console.log('error getting discussion stats');
    }
  }

  const getMonthlyDiscussion=async()=>{
    let dt1 = new Date();
    let dt2 = dt1.setDate(dt1.getDate()-30);
    let sd = new Date(dt2);
    let ed = new Date();
    try {
      const {data} = await discussionStats(sd,ed);
      let sortedData = data.data.sort((a,b)=>a._id-b._id);
      let result=0;
      sortedData.forEach((elem,i)=>{
        result+=elem.count;
      })
      setlastMonthDiscussion(result);
      
    } catch (error) {
      console.log('error getting monthly discussion stats');
    }
  }

  const weeklyDataUser={
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "New Users",
        data: weeklyStats,
      },
    ],
  };
  
  const monthlyDataUser={
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep","Oct","Nov","Dec"],
        datasets: [
          {
            label: "New Users",
            data: monthlyStats,
          },
        ]
  }

  const weeklyDataDiscussion={
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            label: "Weekly Discussion",
            data: discussionData,
            maxBarThickness: 10,
          },
        ]
  }


  useEffect(()=>{
   getUserWeekly();
   getDiscussionStat();
   getMonthlyStats();
   getMonthlyDiscussion();
   dispatch(getAllUser());
   dispatch(getAllDiscussions());
   dispatch(getAllCommunity());
   handleisData();
  },[])

  return (
    <>
      <AdminNavbar brandText="Dashboard" />
      <Header 
        setIsMonthlyStat={setIsMonthlyStat}
        setIsWeeklyStat={setIsWeeklyStat}
        setIsDiscussionStat={setIsDiscussionStat}
        setWeeklyStats={setWeeklyStats}
        monthlyStats={monthlyStats}
        setMonthlyStats={setMonthlyStats}
        discussionData={discussionData}
        setDiscussionData={setDiscussionData}
        handleisData={handleisData}
        lastWeekUser={lastWeekUser}
        lastMonthDiscussion={lastMonthDiscussion}
           />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className=" shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      New Users
                    </h6>
                    <h2 className="text-white mb-0">New User</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href=""
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href=""
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
               {
                 activeNav===1&&(
                 isMonthlyStat?
                 <Line
                   data={monthlyDataUser}
                   options={chartExample1.options}
                   getDatasetAtEvent={(e) => console.log(e)}
                 />:
                 <h2>No Data Available </h2>)

               }
               {
                 activeNav===2&&(
                 isWeeklyStat?
                 <Line
                   data={weeklyDataUser}
                   options={chartExample1.options}
                   getDatasetAtEvent={(e) => console.log(e)}
                 />:
                 <h2>No Data Available </h2>
                 )

               }

                {/* {
                  activeNav===1&isWeeklyStat===false&&
                  <h2>No Data Available </h2>
                }
                {
                  activeNav===2&isMonthlyStat===false&&
                  <h2>No Data Available </h2>
                } */}
               
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                    Capsules Management
                    </h6>
                    <h2 className="text-white mb-0">Capsules Management</h2>
                    {/* <h6 className="text-uppercase text-muted ls-1 mb-1">
                    Capsules Management
                    </h6> */}
                    {/* <h2 className="mb-0"></h2> */}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart" 
                 onClick={()=>history.push('/admin/capsule-management')}
                >
                  {
                    isDiscussionStat?
                    <Bar
                      data={weeklyDataDiscussion}
                      options={chartExample2.options}
                    />:
                    <h2>No Data Available </h2>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
      </Container>
    </>
  );
};

export default Index;
