import * as api from '../api/index';
import {GET_ALL_USER,
    GET_ALL_USER_ERROR,
    GET_ME,
    UPDATE_PROFILE,
    UPDATE_PICTURE,
    DELETE_USER,
    ACTIVATE_USER,
    IN_ACTIVATE_USER
} from '../constants/userConstants'
import { toast } from 'react-toastify';

export const getAllUser = ()=> async(dispatch)=>{

    try {

        const {data} = await api.getAllUsers();

        dispatch({type:GET_ALL_USER,payload:data})
        
    } catch (error) {
        dispatch({type:GET_ALL_USER_ERROR,payload:error})
    }
}

export const getMe =()=>async(dispatch)=>{
    try {

        const {data} = await api.getMe();

        dispatch({type:GET_ME,payload:data})
        
    } catch (error) {
        console.log(error);
    }
}

export const deleteUserbyId =(id)=>async(dispatch)=>{
    try {

      const {data} = await api.deleteUserById(id);

        dispatch({type:DELETE_USER,payload:id});

        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }
}

export const updateProfile=(detail)=>async(dispatch)=>{

    try {
        const {data} = await api.updateProfileDetails(detail);

        dispatch({type:UPDATE_PROFILE, payload:data});
        
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }
}

export const updatePicture=(postData)=>async(dispatch)=>{

    try {
        const {data} = await api.updateProfilePicture(postData);

        dispatch({type:UPDATE_PICTURE,payload:data});
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }

}

export const activeUser =(userId,gotoPage,pageIndex)=>async(dispatch)=>{

    try {
      const {data} = await api.activateUser(userId);

        dispatch({type:ACTIVATE_USER,payload:userId});
        gotoPage(pageIndex)
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }

}

export const in_activeUser =(userId,gotoPage,pageIndex)=>async(dispatch)=>{

    try {
        const {data} = await api.inactivateUser(userId);

        dispatch({type:IN_ACTIVATE_USER,payload:userId});
        gotoPage(pageIndex)
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }

}