import React,{useState} from 'react';

import {  Container, Form,Card, CardHeader, CardBody, Button} from "reactstrap";

import { useDispatch } from 'react-redux';
import { Link, useParams,useHistory } from 'react-router-dom';
import { ToastContainer,toast } from 'react-toastify';

import { addCapsulePost } from 'redux/actions/capsuleActions';
import {addCapsuleContent} from '../../redux/api';
import UserHeader from 'components/Headers/UserHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';

const AddCapsulePost = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
     

    const [content, setContent] = useState({
        title:"",description:'',media:'',thumbnail:''
    })

    const handleChange=(e)=>{
        setContent({...content,[e.target.name]:e.target.value});
        if(e.target.files){           
            setContent({...content,[e.target.name]:e.target.files[0]});
        }
        
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();

        try {
            if(!content.title||!content.description||!content.media){
                toast.error("Please add content",{
                    autoClose: 1500,
                    hideProgressBar: true
                }) 
                return
            }

            let formdata = new FormData();
            formdata.append('title',content.title);
            formdata.append('description',content.description);
            formdata.append('tool',content.media);
            formdata.append('thumbnail',content.thumbnail);

            await addCapsuleContent(params.capsuleId,params.subCategoryId,formdata);
            clearFields();
            history.push(`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}`)
        } catch (error) {
            
          
                toast.error(error?.response?.data?.message||error?.message||"Something went wrong, please try again later",{
                    autoClose: 1500,
                    hideProgressBar: true
                }) 
                return
            
        }

    

        
       

     }

    const clearFields=()=>{
        setContent({title:"",description:"",media:'',thumbnail:""});
    }

    return (
        <>
        <AdminNavbar brandText="Add Content" />
        <UserHeader/>
        <ToastContainer/>
            <Container className="mt--7" fluid>
                
            <Card>
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0" style={{color:'inherit'}} >Capsule Content Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to={`/admin/capsule-management/${params.capsuleId}/tools/${params.subCategoryId}`} className="btn bg-gradient-danger d-btn text-white px-4">Back</Link>
                </div>
                </div>
            </CardHeader>

            

           <CardBody>

            <Form >
                  <div className="form-body">
                    <div className="row p-t-20">
                        <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Content Title</label>
                            <input 
                                type="text"
                                name="title"
                                value={content.title}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter content title"/>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Content Description</label>
                            <input 
                                type="text"
                                name="description"
                                value={content.description}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter content description"/>
                        </div>
                        </div>

                        <div className="col-md-3">
                            <label>Content Media</label>
                            <div className="custom-file">
                                <input type="file" name="media" accept="video/*"
                                className="custom-file-input" onChange={handleChange}/>
                                <label className="custom-file-label"
                                style={{
                                    fontSize:"14px"
                                }}
                                 >{content?.media.name?content?.media.name:'Choose file...'}</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label>Content Thumbnail</label>
                            <div className="custom-file">
                                <input type="file" name="thumbnail" accept="image/*"
                                className="custom-file-input" onChange={handleChange}/>
                                <label className="custom-file-label" >{content?.thumbnail?.name?content.thumbnail.name:'Choose file...'}</label>
                            </div>
                        </div>
                    
                    </div>
                        <div className="col-md-3">
                        <div className="form-actions filter-btn">
                          <Button color="danger" className="w-100 mr-1" onClick={clearFields} >Reset</Button>
                          <Button color="danger" className="w-100 ml-1" onClick={handleSubmit}>Add</Button>
                        </div>
                       
                       </div>
                  </div> 
                </Form>
            </CardBody> 
        </Card>
                
        </Container>
        
        </>
    )
}

export default AddCapsulePost
