import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {logout} from '../../redux/actions/authActions';

const AdminNavbar = ({brandText,filter, setFilter,name}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user= useSelector(state=>state.auth.info);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {brandText}
          </div>
          {
            setFilter&&
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative" value={filter} onChange={(e)=>setFilter(e.target.value)}>
                <InputGroupAddon addonType="prepend" >
                 
                </InputGroupAddon>
                <Input placeholder="" type="text" />
                <InputGroupText >
                    <i className="fas fa-search" />
                  </InputGroupText  >
              </InputGroup>
            </FormGroup>
          </Form>
          }
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                {/* <i class="fa fa-bell belling notifik" aria-hidden="true"></i> */}
                <h4 className="">
                {user.name&&user.name} &nbsp;
                  </h4>
                  <span className="avatar avatar-sm rounded-circle">
                 
                  {/* <i class="ni ni-single-02"></i> */}
                  <img 
                      src={`http://alobhaitsolution.com:3009${user?.picture}`}
                      alt="" />
                  </span>
                  
                  <Media 
                  className="ml-2 d-none d-lg-block"
                 
                  >
                    <span className="mb-0 text-sm font-weight-bold">
                      {/* <img 
                      src={`http://alobhaitsolution.com:3009${user?.picture}`}
                      alt="" /> */}
                      
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                
                <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem   onClick={()=>dispatch(logout(history))} >
                  {/* <div> */}
                    
                      <i className="ni ni-curved-next" />
                      <span >Logout</span>
                    

                  {/* </div> */}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
