import React,{useState} from 'react';

import {  Container, Form,Card, CardHeader, CardBody, Button} from "reactstrap";

import { useDispatch } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import {toast, ToastContainer } from 'react-toastify';

import { addCapsule } from '../../redux/actions/capsuleActions'
import UserHeader from 'components/Headers/UserHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';

const AddCapsule = () => {
    const history = useHistory();
    const dispatch = useDispatch();
        

    const [capsule, setcapsule] = useState({
        title:"",subTitle:"",description:'',image:''
    })

    const handleChange=(e)=>{
        setcapsule({...capsule,[e.target.name]:e.target.value});
        if(e.target.files){           
            setcapsule({...capsule,image:e.target.files[0]});
        }
        
    }

    const handleSubmit=(e)=>{
        e.preventDefault();

        if(!capsule.image){
            toast.error('Please add an image for capsule',{
                autoClose: 1500,
                hideProgressBar: true,
              })
              return
        }
        let formdata = new FormData();
        formdata.append('title',capsule.title);
        formdata.append('subTitle',capsule.subTitle);
        formdata.append('description',capsule.description);
        formdata.append('capsule',capsule.image);

        dispatch(addCapsule(formdata,history));
        clearFields();
    }

    const clearFields=()=>{
        setcapsule({title:"",description:"",subTitle:"",image:''});
    }

    return (
        <>
        <AdminNavbar brandText="Add capsule" />
        <UserHeader/>
        <ToastContainer/>
            <Container className="mt--7" fluid>
                
            <Card>
            <CardHeader>
            <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0 capdtl">Add Capsule </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/capsule-management" className="btn btn-info  px-4 capwhite">Back</Link>
                </div>
                </div>
            </CardHeader>

           <CardBody>

            <Form >
                  <div className="form-body">
                    <div className="row p-t-20 text-white">
                        <div className="col-md-4">
                        <div className="form-group">
                            <label className="control-label">Capsule Name</label>
                            <input 
                                type="text"
                                name="title"
                                value={capsule.title}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter capsule title"/>
                        </div>
                        </div>

                        {/* <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Capsule Subtitle</label>
                            <input 
                                type="text"
                                name="subTitle"
                                value={capsule.subTitle}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter capsule subtitle"/>
                        </div>
                        </div> */}

                        <div className="col-md-4">
                            <label>Capsule Image</label>
                        <div className="custom-file">
                            <input type="file" name="community" accept="image/*"
                            className="custom-file-input" onChange={handleChange}/>
                            <label className="custom-file-label" >{capsule.image?capsule.image.name:'Choose image...'}</label>
                        </div>
                        </div>
                        
                        {/* <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                name="description"
                                onChange={handleChange}
                                value={capsule.description}
                                placeholder="Capsule Description"/>
                        </div>
                        </div> */}
                        

                        <div className="col-md-3">
                        <div className="form-actions filter-btn" style={{marginTop: '30px'}}>
                          <Button color="info" className="btn btn-info w-100 mr-1" onClick={clearFields} >Reset</Button>
                          <Button color="" className="w-100 btn difrnt ml-1" onClick={handleSubmit}>Add</Button>
                        </div>
                       
                       </div>
                    </div>
                  </div> 
                </Form>
            </CardBody> 
        </Card>
                
        </Container>
        
        </>
    )
}

export default AddCapsule
