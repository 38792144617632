import * as api from '../api/index';

import{GET_STATIC_DATA} from '../constants/staticConstants';

export const getStatic = ()=> async(dispatch)=>{

    try {
        const {data} = await api.getStaticData();

        dispatch({type:GET_STATIC_DATA,payload:data.data[0]})
               
    } catch (error) {
        console.log(error.message);
    }
}