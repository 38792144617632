import React, { useState } from "react";

import {
  Container,
  Form,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,Row,Col
} from "reactstrap";

import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import {getAdminUserAction} from '../../redux/actions/adminAction';

import UserHeader from "components/Headers/UserHeader";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { addAdminUser } from "redux/api";

const AddSystemUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    roleId:""
  });

  const handleChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
    
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
        
            if (!admin.name) {
              toast.error("Please add Admin name", {
                autoClose: 1500,
                hideProgressBar: true,
              });
              return;
            }
        const {data} = await addAdminUser(admin)
           
        history.push('/admin/admin-management')
            // clearFields();
    } catch (error) {
        console.log('add admin user error')
        toast.error(error?.response?.data?.message, {
            autoClose: 1500,
            hideProgressBar: true,
          });
    }
   
  };

  const clearFields = () => {
    setAdmin({ name: "", email: "", password: "", role: "",roleId:'' });
  };

  return (
    <>
      <AdminNavbar brandText="Add capsule" />
      <UserHeader />
      <ToastContainer />
      <Container className="mt--7" fluid>
        <Card>
          <CardHeader>
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0 capdtl">Add System User </h3>
              </div>
              <div className="col-4 text-right">
                <Link
                  to="/admin/admin-management"
                  className="btn btn-info  px-4 capwhite"
                >
                  Back
                </Link>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <Form onSubmit={handleSubmit} onReset={clearFields} >
              <div className="form-body">
                <div className="row p-t-20 text-white">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="control-label">System User Name</label>
                      <input
                        type="text"
                        name="name"
                        value={admin.name}
                        required
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Admin Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="exampleSelect">Admin Role</Label>
                      <Input type="select" 
                      name="role"
                      required
                      placeholder="Admin Role"
                      onChange={handleChange}
                       id="role">
                        <option value={0} >Select Role</option>
                        <option value={"Sub-Admin"} >Sub-Admin</option>
                        <option value={"Content Capsule"} >Content Capsule</option>
                        <option value={"Alobha Staff"} >Alobha Staff</option>
                        <option value={"Developer"} >Developer</option>
                        <option value={"Tester"} >Tester</option>
                      </Input>
                    </FormGroup>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="adminEmail">Email</Label>
                      <Input
                      required
                        type="email"
                        name="email"
                        id="email"
                        value={admin.email}
                        onChange={handleChange}
                        placeholder="Admin Email"
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="text"
                        name="password"
                        id="password"
                        value={admin.password}
                        required
                        onChange={handleChange}
                        placeholder="Admin Password"
                      />
                    </FormGroup>
                  </div>

                </div>
                <Row className="justify-content-md-center" >
                    <Col md={3} >
                   
                    <div
                      className="form-actions filter-btn"
                      style={{ marginTop: "30px" }}
                    >
                      <Button
                        color="info"
                        className="btn btn-info w-100 mr-1"
                        // onClick={clearFields}
                        type="reset"
                      >
                        Reset
                      </Button>
                      <Button
                        color=""
                        className="w-100 btn difrnt ml-1"
                        type="submit"
                        // onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </div>
                  {/* </div> */}
                    </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AddSystemUser;
