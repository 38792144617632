import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import ImogiImage from "../../assets/img/brand/emoji.png";
import sad from "../../assets/img/brand/sad.png";
import smile from "../../assets/img/brand/smile.png";
import { useSelector, useDispatch } from "react-redux";
import { getQuestionaryList } from "../../redux/actions/masterAction";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteQuestionaryById,
  updateQuestionaryStatusById,
} from "../../redux/api";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";

const Questionarymanagement = () => {
  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const deletePopup = (id) => {
    setConfirm(true);
    setConfirmId(id);
  };

  const cancelDelete = () => {
    setConfirm(false);
    setConfirmId();
  };

  const dispatch = useDispatch();
  const questionaryList = useSelector((state) => state.master.questionary.data);
  const permissionPath = useSelector(
    (state) => state.auth.permissions.permissionList
  );
  const adminRole = useSelector((state) => state.auth.info.role);
  const addPerm = permissionPath.find(
    (perm) =>
      perm.method == "POST" && perm.path == "/api/v2.0/static/questionary"
  );
  const viewPerm = permissionPath.find(
    (perm) =>
      perm.method == "GET" &&
      perm.path == "/api/v2.0/static/questionary/:questionId"
  );
  const statusPerm = permissionPath.find(
    (perm) =>
      perm.method == "PUT" &&
      perm.path == "/api/v2.0/static/questionary/:questionId"
  );
  const editPerm = permissionPath.find(
    (perm) =>
      perm.method == "PATCH" &&
      perm.path == "/api/v2.0/static/questionary/:questionId"
  );
  const deletePerm = permissionPath.find(
    (perm) =>
      perm.method == "DELETE" &&
      perm.path == "/api/v2.0/static/questionary/:questionId"
  );

  const handleDelete = async () => {
    try {
      const { data } = await deleteQuestionaryById(confirmId);
      dispatch(getQuestionaryList());
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      cancelDelete();
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log("error deleting capsule post");
    }
  };

  const handleUpdateStatus = async (questId) => {
    try {
      const { data } = await updateQuestionaryStatusById(questId);
      dispatch(getQuestionaryList());
      toast.success(data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      cancelDelete();
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log("error updating status");
    }
  };

  useEffect(() => {
    dispatch(getQuestionaryList());
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="second_header12">
        <AdminNavbar brandText="Questionary Management" />
      </div>

      <div className="mod_box moodmange">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                {" "}
                <h3 className="text-left mod_testi comunity_manage">
                  Questionary List
                </h3>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-actions filter-btn mangrol">
                {adminRole == "superAdmin" ? (
                  <Link to="/admin/questionary-management/add-questionary">
                    <button type="button" class="btn btn-info mr-1 adrol">
                      Add Question
                    </button>
                  </Link>
                ) : addPerm ? (
                  <Link to="/admin/questionary-management/add-questionary">
                    <button type="button" class="btn btn-info mr-1 adrol">
                      Add Question
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <table class="table mood_table">
          <thead class="thead-light">
            <tr>
              <th>S. No</th>
              <th>Question</th>
              <th>Options</th>
              <th>Status</th>
              {adminRole == "superAdmin" ? (
                <th>Action</th>
              ) : viewPerm || statusPerm || editPerm || deletePerm ? (
                <th>Action</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {questionaryList.length > 0 &&
              (rowsPerPage > 0
                ? questionaryList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : questionaryList
              ).map((quest, index) => (
                <tr key={quest._id}>
                  <td> {page * rowsPerPage + (index + 1)}</td>
                  <td>{quest.question} </td>
                  <td style={{ textAlign: "left" }}>
                    <ul>
                      {quest.options.map((opt, optIndex) => (
                        <li key={`option-${opt._id}`}> {opt.option} </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    {quest.isActive ? (
                      <label className="text-green">Active</label>
                    ) : (
                      <label className="text-red">In-Active</label>
                    )}
                  </td>
                  <td>
                    <div>
                      {adminRole == "superAdmin" ? (
                        <Link
                          type="button"
                          className="btn text-purple "
                          to={`/admin/questionary-management/view-questionary/${quest._id}`}
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      ) : viewPerm ? (
                        <Link
                          type="button"
                          className="btn text-purple "
                          to={`/admin/questionary-management/view-questionary/${quest._id}`}
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      ) : null}

                      {adminRole == "superAdmin" ? (
                        <div
                          type="button"
                          className={`btn ${
                            quest.isActive ? "text-red" : "text-green"
                          }`}
                          onClick={() => handleUpdateStatus(quest._id)}
                        >
                          <i className="fa fa-lock"></i>
                        </div>
                      ) : statusPerm ? (
                        <div
                          type="button"
                          className={`btn ${
                            quest.isActive ? "text-red" : "text-green"
                          }`}
                          onClick={() => handleUpdateStatus(quest._id)}
                        >
                          <i className="fa fa-lock"></i>
                        </div>
                      ) : null}

                      {adminRole == "superAdmin" ? (
                        <Link
                          to={`/admin/questionary-management/edit-questionary/${quest._id}`}
                        >
                          <div type="button" className="btn text-red">
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Link>
                      ) : editPerm ? (
                        <Link
                          to={`/admin/questionary-management/edit-questionary/${quest._id}`}
                        >
                          <div type="button" className="btn text-red">
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Link>
                      ) : null}

                      {adminRole == "superAdmin" ? (
                        <div
                          type="button"
                          className="btn text-red"
                          onClick={() => deletePopup(quest._id)}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                      ) : deletePerm ? (
                        <div
                          type="button"
                          className="btn text-red"
                          onClick={() => deletePopup(quest._id)}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Modal isOpen={confirm}>
          <ModalHeader>Confirm delete question ?</ModalHeader>
          <ModalBody>Are you sure you want to delete this question?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => handleDelete()}>
              Confirm
            </Button>{" "}
            <Button color="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <CardFooter className="py-4">
          <nav aria-label="...">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem className={page !== 0 ? "active" : ""}>
                <PaginationLink
                  onClick={() => {
                    if (page > 0) {
                      setPage((prevPage) => prevPage - 1);
                    }
                  }}
                >
                  <i className="fas fa-angle-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>
                  {page + 1}
                  {/* {pageIndex+1} */}
                  <span className="sr-only">(current)</span>
                </PaginationLink>
              </PaginationItem>

              <PaginationItem
                className={
                  Math.ceil(questionaryList.length / rowsPerPage) > page + 1
                    ? "active"
                    : ""
                }
              >
                <PaginationLink
                  onClick={() => {
                    if (
                      Math.ceil(questionaryList.length / rowsPerPage) >
                      page + 1
                    ) {
                      setPage((prevPage) => prevPage + 1);
                    }
                  }}
                >
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardFooter>
      </div>
    </>
  );
};

export default Questionarymanagement;
