import { Container} from "reactstrap";

const UserHeader = () => {
  return (
    <>
      
        {/* <span className="mask bg-gradient-danger opacity-8" /> */}
       
        {/* <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
             
            
            </Col>
          </Row>
        </Container> */}

        <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            

                </div>
        </Container>
      </div>
      
    </>
  );
};

export default UserHeader;
