import React,{useState} from 'react';

import{
     CardHeader, CardBody,Button
} from 'reactstrap'

import { toast,ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { updateCapsuleById } from '../../redux/api/index';
import { useSelector } from 'react-redux';

import moment from "moment";

const CapsuleDetail = ({detail}) => {

  const [edit, setEdit] = useState(false);

  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const editPerm = permissionPath.find(perm=>perm.method=="PATCH"&&perm.path=="/api/v2.0/capsule/:capsuleId");


  const [capsule, setcapsule] = useState({
    title:"",subTitle:"",description:'',image:'',newImage:''
})

const handleChange=(e)=>{
  setcapsule({...capsule,[e.target.name]:e.target.value});
  if(e.target.files){           
      setcapsule({...capsule,newImage:e.target.files[0]});
  } 
}

const handleSubmit =async(e)=>{
  e.preventDefault();
  let formdata = new FormData();
  capsule.title&&formdata.append('title',capsule.title);
  capsule.description&&formdata.append('description',capsule.description);
  capsule.newImage&& formdata.append('capsule',capsule.newImage);
  capsule.subTitle&& formdata.append('subTitle',capsule.subTitle);
  
  try {
    const {data} = await updateCapsuleById(detail._id,formdata);
    setcapsule({...capsule,
          title:data.data.title,
          subTitle:data.data.subTitle,
          image:data.data.image
        })
        toast.success(data.message,{
          autoClose: 1500,
          hideProgressBar: true
      })
    setEdit(false);
  } catch (error) {
    console.log("error updatig capsule")
  }

}

const handleCancel=()=>{
  setEdit(false);
  setcapsule({
    title:"",subTitle:"",description:'',image:'',newImage:''
})
}

    return (
        <>
          <>
           <ToastContainer />
            <CardHeader>
              <div className="row align-items-center">
                <div className="col-8">
                <h3 className="mb-0 text-dark">Capsule Details </h3>
                </div>
                <div className="col-4 text-right"> 
                    <Link to="/admin/capsule-management" className="btn info usrdtls bokwhit px-4">Back</Link>
                </div>
              </div>
            </CardHeader>
      
            
            <CardBody className="edback">

              {
                adminRole=="superAdmin"?
            <div className="text-right mb-3" >
                  {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                  }
                  {edit?
                    <Button color="success" size='sm' onClick={handleSubmit} >
                              <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="info" size='sm' onClick={()=>setEdit(true)} >
                              <i className="fa fa-edit"></i> Edit
                    </Button>
                      }
              </div>:editPerm?<div className="text-right mb-3" >
                  {edit&&< Button color="danger" size='sm' onClick={handleCancel} >
                            <i className="fa fa-times"></i> Cancel
                    </Button>
                  }
                  {edit?
                    <Button color="success" size='sm' onClick={handleSubmit} >
                              <i className="fa fa-check"></i> Done
                    </Button>
                    :
                    <Button color="info" size='sm' onClick={()=>setEdit(true)} >
                              <i className="fa fa-edit"></i> Edit
                    </Button>
                      }
              </div>:null
              }

                <div className="pl-lg-4">
                <div className="row">
                <div className="col-lg-3">
                  <img alt="user" 
                  src={`${process.env.REACT_APP_BASE_URL}${capsule?.image?capsule?.image:detail?.image}`} 
                  width="100%"/>
                  {edit&&
                    <div className="custom-file">
                        <input type="file" name="capsule" accept="image/*"
                        className="custom-file-input" onChange={handleChange}/>
                        <label className="custom-file-label" >{capsule?.newImage?.name?capsule?.newImage?.name:'Choose image...'}</label>
                    </div>
                  }
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">Capsule Name</label>
                        <input type="text" 
                        className="form-control" 
                        name="title"
                        value={capsule.title?capsule?.title:detail?.title}
                        onChange={handleChange}
                         disabled={!edit}/>
                      </div>
                    </div>
                    {/* <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">Sub Capsule Name</label>
                        <input type="text" 
                         className="form-control"
                         name="subTitle"
                         value={capsule.subTitle?capsule?.subTitle:detail?.subTitle}
                         onChange={handleChange}
                         disabled={!edit}/>
                      </div>
                    </div> */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label">Capsule Create on</label>
                        <input type="text" className="form-control" value={moment(detail?.createdAt).format("MMM Do YY")} disabled/>
                       </div>
                      </div>
                        
                        </div>
                        </div>
                    </div>
                    </div>
                    </CardBody>
                </>            
        </>
    )
}

export default CapsuleDetail
