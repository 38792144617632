import React,{useState,useEffect} from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Editrole from './Editrole';
import { Link,useParams,useHistory } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import {getAssigneeList,addRole} from '../../redux/api';
import { toast, ToastContainer } from "react-toastify";
import {Form,Label,Input,FormGroup} from 'reactstrap';
import { fetchPermissionListAction } from "../../redux/actions/adminAction";


const Addrole = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [adminUser, setAdminUser] = useState([]);
  const [role, setRole] = useState({
    name:"",

  })

  const permissionList = useSelector((state) => state.adminUser.permission);

  const fetchAssigmentUser = async()=>{
    try {
      const {data} = await getAssigneeList(params.roleId);
      setAdminUser(data.data);
    } catch (error) {
      console.log('assignee user list fetch error')
    }
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();
    try {
      if(!role.name){
        throw new Error('Please enter role name');
      }
      const {data} = await addRole({roleName:role.name});
      history.push('/admin/role-management')
    } catch (error) {
      toast.error(error?.response?.data?.message||error?.message||"Something went wrong in adding role", {
        autoClose: 1500,
        hideProgressBar: true,
      });
      console.log('error add role')
    }
  }

  useEffect(()=>{
    fetchAssigmentUser();
    dispatch(fetchPermissionListAction());

  },[])

  return (
    <>
     <ToastContainer />
      <div className='second_header12' >
    <AdminNavbar  brandText="Add Role" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>Add Role</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 

                         <div className='try_whole'>
                           <div className="row try_whole">


                                    <div className='col-md-12 labl_infore'>
                                      <div className="first_frm">
                                        <label for="">Role Name</label>
                                        <input type='text' className='form-control'
                                        value={role.name}
                                        onChange={(e)=>{
                                          setRole({
                                            ...role,
                                            name:e.target.value
                                          })
                                        }}
                                         placeholder='Enter Role Name' id='' />
                                      </div>
                                    </div>                             
                                   <div className='col-md-12 labl_infore'>

                                      <div className="first_frm">
                                          <label for="">Assign To</label>
                                          <select name="" className='form-control' id="" >
                                            {
                                              adminUser.map((admin,index)=> (
                                                <option key={admin._id} value={admin._id}>{admin.name} </option>
                                              ) )
                                            }
                                            
                                          </select>
                                          {/* <input type='text' className='form-control' placeholder='Enter Name' id='' ></input> */}
                                      </div>
                                  </div>   
                                  <div className='col-md-12 labl_infore'>
                              <div className="first_frm">
                                <label for="">Assign Permission</label>
                                {/* <input type='text' className='form-control' placeholder='' value="Super Admin" id='text' disabled></input> */}
                                </div>
                            </div> 
                            <div className="col-md-12 labl_infore">
                    <div className="">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Capsule</label>
                      </div>
                    </div>
                    <div className="row">
                      {" "}
                      {permissionList
                        .filter((perm) => perm.module === "CAPSULE")
                        .map((perm) => (
                          <div key={perm._id} className="col-md-3 inbuilt leftsift">
                            <div className="lbl_boxcingin">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                              />{" "}
                              <label for="email" className="sometxt">
                                {perm.name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">User</label>
                      </div>
                    </div>
                    <div className="row">
                   
                        {" "}
                        {permissionList
                          .filter((perm) => perm.module === "USER")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                     
                    </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Community</label>
                      </div>
                    </div>
                    <div className="row">
                        {permissionList
                          .filter((perm) => perm.module === "COMMUNITY")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Journal</label>
                      </div>
                    </div>
                    <div className="row">
                        {permissionList
                          .filter((perm) => perm.module === "JOURNAL")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Mood</label>
                      </div>
                    </div>
                    <div className="row">
                        {permissionList
                          .filter((perm) => perm.module === "MOOD")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Questionary</label>
                      </div>
                    </div>
                    <div className="row">
                        {permissionList
                          .filter((perm) => perm.module === "QUESTIONARY")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                  </div>


                  {/* ----------------------------------- */}
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Notification</label>
                      </div>
                    </div>
                    <div className="row">
                        {permissionList
                          .filter((perm) => perm.module === "NOTIFICATION")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                  </div>
                  <div className="col-md-12 labl_infore">
                    <div className="mt-2">
                      <div className="lbl_boxcingbor seprt_boc ">
                        <input type="checkbox" id="vehicle1" name="vehicle1" />{" "}
                        <label for="email">Admin</label>
                      </div>
                    </div>
                    <div className="row">
                        {permissionList
                          .filter((perm) => perm.module === "ADMIN")
                          .map((perm) => (
                            <div key={perm._id} className="col-md-3 inbuilt leftsift">
                              <div className="lbl_boxcingin">
                                <input
                                  type="checkbox"
                                  id="vehicle1"
                                  name="vehicle1"
                                />{" "}
                                <label for="email" className="sometxt">
                                  {perm.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                  </div>

                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/role-management" type="button" class="btn btn-info">Back</Link>
                                        <button type="button" class="btn difrnt"
                                       onClick={handleSubmit}
                                        >Submit</button>
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default Addrole