import React from 'react'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { Link } from 'react-router-dom';
// <AdminNavbar brandText="Add Role" />
const Editcontent = () => {
  return (
    <>
      <div className='second_header12' >
    <AdminNavbar  brandText="Edit Content" />

    </div>
    
      <div className="mod_box moodmange">  
        <div className="container">

                  <div className="row">  
                        <div className='col-md-12 addrole_outer'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="addrole_top">
                                      <h3 className='mb-0 text-fff py-3'>Edit Content</h3>
                                      </div> 
                                </div>
                                <div className='col-md-6'></div>                    
                            </div> 

                         <div className='try_whole'>
                           <div className="row try_whole">


                                    <div className='col-md-6 labl_infore'>
                                      <div className="first_frm">
                                        <label for="">Select Category</label>
                                        <select className="form-control">
                                            <option value={1}>1</option>
                                            <option value={1}>2</option>
                                            <option value={1}>3</option>
                                            <option value={1}>4</option>
                                        </select>
                                        </div>
                                    </div>                             
                                    <div className='col-md-6 labl_infore'>
                                     <div className="first_frm">
                                      <label for="">Select Tool</label>
                                      <select className="form-control">
                                          <option value={1}>1</option>
                                          <option value={1}>2</option>
                                          <option value={1}>3</option>
                                          <option value={1}>4</option>
                                      </select>
                                     </div>
                                    </div>                            
                                    <div className='col-md-6 labl_infore'>
                                     <div className="first_frm">
                                      <label for="">Content title</label>
                                      <input type='text' className='form-control' value="loremad sfs sfsf" placeholder='' id='' />
                                     </div>
                                    </div>          
                                    <div className='col-md-6 labl_infore'>
                                     <div className="first_frm">
                                      <label for="">Add Banner Image</label>
                                      <input type='file' className='form-control' placeholder='' id='' />
                                     </div>
                                    </div>          
                                <div className='brn-topwrp'></div>
                                    <div className="bdn_cover">
                                      <div className="m-auto col-lg-6">
                                        <Link to="/admin/content-management" type="button" class="btn btn-info">Back</Link>
                                        <button type="button" class="btn difrnt">Update</button>
                                      </div>
                                    </div>
                                     <div className='brn-botwrp'></div>
                             </div>   
                       </div> 
                     </div>                           
            </div>

        </div>        
   </div>
    </>
  )
}

export default Editcontent