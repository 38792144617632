import {useState,useEffect} from 'react';
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { userStatsWeekly,userStatsMonthly,discussionStats } from '../../redux/api/index';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Header = ({
  setIsMonthlyStat,
  setWeeklyStats,
  setIsWeeklyStat,
  setMonthlyStats,
  setIsDiscussionStat,
  setDiscussionData,
  lastWeekUser,
  lastMonthDiscussion
}) => {

  const user = useSelector(state=>state.user.users);
  const community = useSelector(state=>state.community.detail);
  const discussion = useSelector(state=>state.discussion.details);
  const history = useHistory();
  const [date, setDate] = useState({
    sd:'',ed:''
  })
  const [weeklyUser, setWeeklyUser] = useState(0);

  const handleChange=(e)=>{
    setDate({...date,[e.target.name]:e.target.value});
  }

  const handleSubmit =(e)=>{
    e.preventDefault();
    getUserWeekly(date.sd,date.ed);
    getMonthlyStats(date.sd,date.ed);
    getDiscussionStat(date.sd,date.ed)
  }

  const handleCancel =(e)=>{
    e.preventDefault();
    setDate({sd:'',ed:''});
    getUserWeekly();
    getMonthlyStats();
    getDiscussionStat();
  }

  const getUserWeekly=async(sd,ed)=>{
    
    try {
      const {data} = await userStatsWeekly(sd,ed);    
      let sortedData = data.data.sort((a,b)=>a._id-b._id);
      let result=[];
      result.length=7;

      let day=[1,2,3,4,5,6,7];

      day.forEach((elem,i)=>{
        
        let sortInd = sortedData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          result[sortedData[sortInd]._id-1]= sortedData[sortInd].count
        }else{
          result[i]=0
        }

      })
      setWeeklyStats(result);
      if(Math.min(...result)===0&&Math.max(...result)===0){
        setIsWeeklyStat(false);
      } else setIsWeeklyStat(true);
    } catch (error) {
      console.log('error getting weekly user');
    }
  }

  const getMonthlyStats=async(sd,ed)=>{

    try {
      const {data} = await userStatsMonthly(sd,ed);
      let sortedMonthlyData = data.data.sort((a,b)=>a._id-b._id);
      let monthlyResult=[];
      monthlyResult.length=12;
      let month=[1,2,3,4,5,6,7,8,9,10,11,12];

      month.forEach((elem,i)=>{
        let sortInd = sortedMonthlyData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          monthlyResult[sortedMonthlyData[sortInd]._id-1]= sortedMonthlyData[sortInd].count
        }else{
          monthlyResult[i]=0
        }

      })
      setMonthlyStats(monthlyResult);
      if(Math.min(...monthlyResult)===0&&Math.max(...monthlyResult)===0){
        setIsMonthlyStat(false);
      } else setIsMonthlyStat(true);
    } catch (error) {
      console.log('error getting monthly user');
    }
  }

  const getDiscussionStat=async(sd,ed)=>{
    try {
      const {data} = await discussionStats(sd,ed);

      let sortedData = data.data.sort((a,b)=>a._id-b._id);
      let result=[];
      result.length=7;

      let day=[1,2,3,4,5,6,7];

      day.forEach((elem,i)=>{
        
        let sortInd = sortedData.findIndex(sElem=>sElem._id===elem);
        if(sortInd!==-1){
          result[sortedData[sortInd]._id-1]= sortedData[sortInd].count
        }else{
          result[i]=0
        }
      })

      setDiscussionData(result);
      if(Math.min(...result)===0&&Math.max(...result)===0){
        setIsDiscussionStat(false);
      } else setIsDiscussionStat(true)
    } catch (error) {
      console.log('error getting discussion stats');
    }
  }

const countUser=()=>{
  let user=0;
lastWeekUser.forEach(elem=>user+=elem);
setWeeklyUser(user);
}


useEffect(()=>{
  countUser();
},[lastWeekUser])


  return (
    <>
      <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          
          <form >
                  <div className="form-body">
                    <div className="row p-t-20 text-white" style={{alignItems:'center'}} >
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">From Date</label>
                          <input type="date" name='sd' value={date.sd}
                          max={date.ed}
                          
                           onChange={handleChange} className="form-control  "/>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">To Date</label>
                          <input type="date" name='ed'
                          min={date.sd}
                           value={date.ed} onChange={handleChange} className="form-control  "/>
                        </div>
                      </div>
                        <div className="col-md-3">
                        <div className="form-actions filter-btn" style={{marginTop:'5px'}} >
                          <button type="button" className="btn btn-info w-100 mr-1" onClick={handleCancel}>Reset</button>
                          <button className="btn difrnt w-100 ml-1" onClick={handleSubmit} >Search</button>
                        </div>
                       </div>
                   
                    </div>
                   
                  </div>
                  
                </form>




            {/* Card stats */}
            <Row>
              
              <Col lg="4" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody
                   onClick={()=>history.push('/admin/customer-management')}
                  >
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Users
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{user.length} </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fas fa fa-user" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-successing mr-2">
                        <i className="fa fa-arrow-up" /> 
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                           Total Post
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{discussion.length}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa fa-image" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-successing mr-2">
                        <i className="fas fa-arrow-up" /> 
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody
                  onClick={()=>history.push('/admin/community-management')}
                    >
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Community
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{community.length} </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-successing mr-2">
                        <i className="fas fa-arrow-up" /> 
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
