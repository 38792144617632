import React,{useState} from 'react'
import {  Container} from "reactstrap";


const CapsuleHeader = ({setFilter}) => {

  const [value, setValue] = useState({
    fromDate:'',toDate:'',capsule:'',subTitle:''
  })

  const handleChange=(e)=>{
    e.preventDefault();
    setValue({...value,[e.target.name]:e.target.value});
   }

const handleSubmit=(e)=>{
    e.preventDefault();
   setFilter("title",value.capsule);
   let dateFilter=[];
   const sd1 = new Date(value.fromDate).setHours(0,0,0);
    const sd = new Date(sd1);
    const ed1 = new Date(value.toDate).setHours(23,59,0);
    const ed = new Date(ed1);
   if(value.fromDate&&value.toDate){
    dateFilter[0]=sd;
    dateFilter[1]=ed;

   }
   setFilter('createdAt',dateFilter)
  }

  const handleCancel=(e)=>{
    e.preventDefault();
    setValue({fromDate:'',toDate:'',capsule:'',subTitle:''})
    setFilter("title",'');
   setFilter('createdAt','')
  }


    return (
        <>

    <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">


            <form >
                  <div className="form-body">
                    <div className="row p-t-20 text-white">
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">From Date</label>
                          <input 
                          type="date"
                          max={value.toDate}
                          className="form-control"
                          name='fromDate' 
                           onChange={handleChange}
                           value={value.fromDate}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group ">
                          <label className="control-label">To Date</label>
                          <input 
                          type="date" 
                          className="form-control"
                          name="toDate"
                          min={value.fromDate}
                          value={value.toDate}
                          onChange={handleChange} 
                          />
                        </div>
                      </div>
                        {/* <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Status</label>
                            <select className="form-control " required="">
                            <option value="">Select Status</option>
                            <option>Active</option>
                            <option>Inactive</option>
                            </select>
                            </div>
                        </div> */}

                        <div className="col-md-3">
                        <div className="form-group">
                            <label className="control-label">Capsule Name</label>
                            <input type="text" className="form-control"
                            name='capsule' 
                            value={value.capsule}
                            onChange={handleChange}
                            placeholder="Enter capsule Name"/>
                        </div>
                        </div>

                        {/* <div className="col-md-3">
                        <div className="form-group ">
                            <label className="control-label">Sub-Title</label>
                            <input type="text" className="form-control"
                            name='subTitle' 
                            value={value.subTitle}
                            onChange={handleChange}
                            placeholder="Enter capsule subtitle"/>
                        </div>
                        </div> */}

                        <div className="col-md-3 mt-2">
                        <div className="form-actions filter-btn">
                          <button type="button" className="btn btn-info w-100 mr-1" onClick={handleCancel} >Reset</button>
                          <button type="submit" className="btn difrnt w-100 ml-1" onClick={handleSubmit} >Search</button>
                        </div>
                       </div>
                    </div>
                  </div> 
                </form>


                </div>
        </Container>
      </div>
        </>
    )
}

export default CapsuleHeader
