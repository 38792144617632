import * as api from '../api/index';

import{GET_ALL_CAPSULES, ADD_CAPSULE,DELETE_CAPSULE} from '../constants/capsulesConstants';
import { toast } from 'react-toastify';

export const getAllCapsules = ()=> async(dispatch)=>{

    try {

        const {data} = await api.getAllCapsules();

        dispatch({type:GET_ALL_CAPSULES,payload:data})
        
    } catch (error) {
        console.log(error.message);
    }
}

export const addCapsule = (postData,history)=>async(dispatch)=>{

    try {
        
        const {data} = await api.addCapsule(postData);

        dispatch({type:ADD_CAPSULE, payload:data});

        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        
        history.push(`/admin/capsule-management/${data.data._id}`);
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }
}

export const addCapsulePost = (id,postData,history)=>async(dispatch)=>{
    try {
        const {data} = await api.addCapsulePost(id,postData);

        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
        history.push(`/admin/capsule/${id}`);
        
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }
}

export const deleteCapsule=(capsuleId)=>async(dispatch)=>{
    try {
       const {data} = await api.deleteCapsule(capsuleId);
        toast.success(data.message,{
            autoClose: 1500,
            hideProgressBar: true
        })
    dispatch({type:DELETE_CAPSULE,payload:capsuleId});
            
    } catch (error) {
        toast.error(error.response.data.message,{
            autoClose: 1500,
            hideProgressBar: true
        }) 
    }
}