import React,{useState} from 'react';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
  } from "reactstrap";

  import { useDispatch, useSelector } from 'react-redux';
  import { useHistory } from 'react-router-dom';
  import {adminLogin} from '../../redux/actions/authActions';

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginInfo = useSelector(state=>state.auth.errorMessage);

    const [loginData, setLoginData] = useState({email:'',password:''})

    const handleChange =(e)=>{
        setLoginData({...loginData,[e.target.name]:e.target.value})
        
    }

    const handleLogin=(e)=>{
        e.preventDefault();
        dispatch(adminLogin(loginData,history));

    }

    return (
        <>

    <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-3">
          <h1 class="text-center text-white mt-2">Reset Password</h1>
          <div className="w-50 m-auto collapse-brand">
                <img className="w-100 px-4" src={require('../../assets/img/brand/TurleLogin.png').default} alt='' />
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            
            <Form role="form">
            <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    <i class="ni ni-email-83 tobylogn"/>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="email"
                    type="email"
                    autoComplete="email"
                    name="email"
                    value={loginData.email}
                    onChange={(e)=>handleChange(e)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open tobylogn" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="New Password"
                    type="password"
                    autoComplete="email"
                    name="email"
                    value={loginData.email}
                    onChange={(e)=>handleChange(e)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open tobylogn" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder=" Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    value={loginData.password}
                    onChange={(e)=>handleChange(e)}
                  />
                </InputGroup>
                {loginInfo&&<div className='text-red text-center mt-2 p-2' >{loginInfo}</div>}
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                    <Row>
                        <Col md="6">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                </label>
                            <span className="text-muted text-left">Remember me</span>
                        </Col>
                        <Col md="6" >
                          <Link to="/auth/forgot-password">
                             <small className="text-right">Forgot password?</small>
                          </Link>
                        </Col>
                    </Row>
                  
              </div> */}
              <div className="text-center">
                <Button className="my-4 px-5" color="info" type="button" onClick={handleLogin}>
                Submit 
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        
      </Col>

        </>
    )
}

export default Login
