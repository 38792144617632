import React from 'react';
import { useEffect,useState,useMemo } from "react";
import { useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import '../../assets/css/new_style.css'

import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,Col,Button,Modal,ModalBody,ModalFooter,ModalHeader
  } from "reactstrap";

  import {Link} from 'react-router-dom';
  import {capDate2} from './capsuleData2';

import CapsuleHeader from './CapsuleHeader';
import { useTable,useSortBy,useGlobalFilter,useFilters,usePagination } from "react-table";

import {getAllCapsules,deleteCapsule} from '../../redux/actions/capsuleActions'

const Capsule = () => {

  const dispatch = useDispatch();
  const capsules = useSelector(state=>state.capsule.detail);

  const permissionPath = useSelector(state=>state.auth.permissions.permissionList);
  const adminRole = useSelector(state=>state.auth.info.role);
  const addPerm = permissionPath.find(perm=>perm.method=="POST"&&perm.path=="/api/v2.0/capsule");
  const viewPerm = permissionPath.find(perm=>perm.method=="GET"&&perm.path=="/api/v2.0/capsule/:capsuleId");
  const deletePerm = permissionPath.find(perm=>perm.method=="DELETE"&&perm.path=="/api/v2.0/capsule/:capsuleId");

  const filterTypes = useMemo(
    () => ({
        dateBetween: dateBetweenFilterFn,
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id];
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true;
            });
        }
    }),
    []
  );

  const COLUMNS=[
    {
      Header:'Sr. No',
      accessor:'',
      Cell:(props)=>props.row.index+1
  },
    {
        Header:'Capsule Icon',
        accessor:'image',
        Cell: (props)=>(
          <div style={{height:'80px', width:'80px', margin:'0 auto'}}>
              <img style={{width:'100%',height:'100%', objectFit:"cover"}} className="rounded-circle fluid"
              src={`${process.env.REACT_APP_BASE_URL}${props.value}`} alt='' />
          </div>
      ),
    },
    {
      Header:'Name',
      accessor:'title',
  },
    
    {
        Header:'Created on',
        accessor:"createdAt",
        filter:'dateBetween',
        Cell: (props)=> {return moment(props.row.original.createdAt).format("MMM Do YY")}
    },
      // {
      //     Header:'Creator',
      //     accessor:'creator',
      //     Cell: (props)=> {
      //       return props.row.original.creator?props.row.original.creator.name:'Admin'
      //     }
      // },
      {
          Header:'Actions',
          Cell: (props)=>(
            <div >
              
              {
                adminRole=="superAdmin"?
                <Link
                to={{pathname:`/admin/capsule-management/${props.row.original._id}`
                }} 
                  type="button" className="btn text-purple ">
                    <i className="fa fa-eye"></i>
                </Link>:viewPerm?<Link
                to={{pathname:`/admin/capsule-management/${props.row.original._id}`
                }} 
                  type="button" className="btn text-purple ">
                    <i className="fa fa-eye"></i>
                </Link>:null
              }

             
                {
                  adminRole=="superAdmin"?
                <div type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(props.row.original._id)}
                  >
                  <i className="fa fa-trash"></i>
                </div>:deletePerm?<div type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(props.row.original._id)}
                  >
                  <i className="fa fa-trash"></i>
                </div>:null
                }
            </div>
          )

      },
    
]

  const columns = useMemo(()=> COLUMNS,[]);
    const data = useMemo(()=>capsules,[capsules]);
    const tableInstance = useTable({
      columns: columns,
      data: data,
      filterTypes
    },useFilters,useGlobalFilter,useSortBy,usePagination)

  const{getTableProps,getTableBodyProps,
    headerGroups,page,prepareRow,state,setGlobalFilter,setFilter,
    nextPage,previousPage,canPreviousPage,canNextPage,rows
  } = tableInstance;

  const {globalFilter,filters,pageIndex} = state



  function dateBetweenFilterFn(rows, id, filterValues) {
    let sd = new Date(filterValues[0]);
    let ed = new Date(filterValues[1]);
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}


  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }

  const handleDelete=()=>{
    dispatch(deleteCapsule(confirmId));
    cancelDelete();
  }

  useEffect(()=>{
    dispatch(getAllCapsules());
  },[dispatch])

    return (
        <>
          
            <CapsuleHeader setFilter={setFilter} filter={filters} />
            <ToastContainer/>
            <AdminNavbar filter={globalFilter} setFilter={setGlobalFilter} brandText="Capsule management" />
            <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Row>
                    <Col lg={9} >
                        <h3 className="mb-0 comunity_manage">Capsule Management</h3>
                    </Col>
                    <Col lg="3">
                      {
                        adminRole=="superAdmin"?
                      <Link to="/admin/add-capsule"  className="float-right">
                        <Button color="info">Add Capsule</Button>
                      </Link>:addPerm?<Link to="/admin/add-capsule"  className="float-right">
                        <Button color="info">Add Capsule</Button>
                      </Link>:null
                      }
                    </Col>
                  </Row>
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Capsule Name</th>
                      <th scope="col">Capsule Sub-Category</th>
                      <th scope="col">Created on</th>
                      <th scope="col">Creator</th>
                      <th scope="col" className="text-center" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {capsules.map((elem,index)=>(
                    <tr key={index}>
                      <td>{elem.title}</td>
                      <td>
                        {elem.subTitle}
                      </td>
                      <td>
                      {moment(elem.createdAt).format("MMM Do YY")}
                      </td>

                      <td>
                      {elem.creator?.name?elem.creator.name:"Admin User name"}
                      </td>
                      <td className="d-flex justify-content-center"> 
                        <Link to={{pathname:`/admin/capsule/${elem._id}`,
                        capsuleId:elem._id
                        }}  
                          type="button" className="btn text-purple ">
                            <i className="fa fa-eye"></i>
                          </Link>
                        
                        <div type="button" className="btn text-red" onClick={()=>deletePopup(elem._id)} >
                          <i className="fa fa-trash"></i>
                        </div>
                    </td> 
                    </tr>

                    ))}
                  </tbody>
                </Table> */}

              <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this capsule?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleDelete()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
              </Modal>

              <Table {...getTableProps()} className="align-items-center table-flush" responsive >
                  <thead className="thead-light text-center">
                    {headerGroups.map((headerGroup)=> (
                      <tr {...headerGroup.getHeaderGroupProps()} >
                        {
                          headerGroup.headers.map(column=> (
                            <th {...column.getHeaderProps(column.getSortByToggleProps)} scope="col"  >
                              {column.render('Header')} 
                              <span>
                                {column.isSorted?(column.isSortedDesc?'🔽':'🔼'):null }
                              </span>     
                            </th>
                          ))
                        }
                      </tr>
                    ) )}
                  </thead>
                  {rows.length!==0?
                  <tbody {...getTableBodyProps()} >
                    {
                      page.map(row=>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <td {...cell.getCellProps()} scope="row" className="text-center" >
                                   {cell.render('Cell')}
                                                                                  
                                  </td>
                                )
                              } )}                     
                            </tr>
                        )
                      })
                    }
                  </tbody>
                  :<h2 className="text-center">Data Available</h2>}
                </Table>


                <CardFooter className="py-4">
                  <nav aria-label="...">
                  <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className={canPreviousPage?"active":''}>
                        <PaginationLink
                          onClick={() => previousPage()}
                        >
                          <i className="fas fa-angle-left" />
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                        >
                          {pageIndex+1}
                        <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      
              
                      <PaginationItem className={canNextPage?"active":''}>
                        <PaginationLink
                          onClick={() => nextPage()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          
        </Container>

        </>
    )
}

export default Capsule
