import React,{useState,useEffect} from 'react';

import{
    Button, ButtonGroup, Card, Container
} from 'reactstrap'

import { getDiscussionById } from '../../redux/api/index';

import { useParams } from 'react-router';
import UserHeader from 'components/Headers/UserHeader';
import DiscussionDetail from './DiscussionDetail';
import DiscussionReply from './DiscussionReply';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import {disData} from './discussionDetailData';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ViewDiscussion = () => {
    const params = useParams();
   
  const [post, setpost] = useState({});
  const [activeView, setActiveView] = useState(1);

const getDiscussion=async()=>{
    try{
      const {data} = await getDiscussionById(params.communityId,params.postId);
      setpost(data.data);
      
    }catch(error){
        console.log("error loading discussion")
    }
}

useEffect(()=>{
    getDiscussion();

},[])

    return (
        <>
        <AdminNavbar brandText="Post Detail" />
        <UserHeader/>

       
       
{
    !post?.title?
                <Box sx={{ display: 'flex',justifyContent:"center",alignItems:"center",minHeight:"300px" }}>
                    <CircularProgress />
                </Box>:
             <Container className="mt--7" fluid>

    
                <Card>
                    <DiscussionDetail detail={post} setpostData={setpost} />



                </Card>
                <ButtonGroup className="mt-3" >
                <Button color="danger" active={activeView===1?true:false} onClick={()=>setActiveView(1)} >
                    <i className="ni ni-bullet-list-67 text-white tobylogn" ></i>
                    &ensp;Reply {post?.reply?.length}
                </Button>
                
            </ButtonGroup>
            
            <Card>
            <DiscussionReply 
                    reply={post?.reply} 
                    post={post}
                    setpost={setpost}/>
                    </Card>
            </Container>
}
                      
        </>
    )
}

export default ViewDiscussion
