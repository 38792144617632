import React,{useEffect} from 'react';
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row
  } from "reactstrap";
  import { Link } from 'react-router-dom';
  import moment from "moment";

  import { useDispatch, useSelector } from 'react-redux';
  
import { getStatic } from 'redux/actions/staticActions';
import StaticHeader from './StaticHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';

import { staticData } from './staticData';

const Static = () => {
  const dispatch = useDispatch();
  const staticDetail = useSelector(state=>state.static.detail);

  // console.log(staticDetail)

  
  useEffect(()=>{
    dispatch(getStatic());

  },[])

    return (
        <>
        <AdminNavbar brandText="static content management" />
            <StaticHeader/>

            <Container className="mt--7" fluid>
              <Row>
          fhgfhf
          jhgjhg

              </Row>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Static Content Management</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Updated At</th>
                      {/* <th scope="col">Status</th> */}
                      <th scope="col" className="text-center" >Action</th>
                    </tr>
                  </thead>
                
                  <tbody  >
                    <tr >
                      <td>
                        About
                        </td>
                      <td>
                        {staticDetail.about.updatedOn&&moment(staticDetail.about.updatedOn).format("MMM Do YY")}
                      </td>
                      {/* <td>
                        {staticDetail.status}
                      </td> */}
                      <td className="d-flex justify-content-center"> 
                        <Link 
                        to={`/admin/static/about`} 
                        type="button"
                        className="btn text-yellow">
                          <i className="fa fa-edit"></i>
                      </Link>
                    </td> 
                    </tr>
                   
                    <tr >
                      <td>
                      Welcome Slider
                        </td>
                      <td>
                        {staticDetail.updatedAt&&moment(staticDetail.updatedAt).format("MMM Do YY")}
                      </td>
                      {/* <td>
                        {staticDetail.status}
                      </td> */}
                      <td className="d-flex justify-content-center"> 
                        <Link 
                        to={`/admin/static/welcome`} 
                        type="button"
                        className="btn text-yellow">
                          <i className="fa fa-edit"></i>
                      </Link>
                    </td> 
                    </tr>
                  
                    <tr >
                      <td>
                      Privacy Policy
                        </td>
                      <td>
                      {staticDetail.privacy.updatedOn&&moment(staticDetail.privacy.updatedOn).format("MMM Do YY")}
                      </td>
                      {/* <td>
                        {staticDetail.status}
                      </td> */}
                      <td className="d-flex justify-content-center"> 
                        <Link 
                        to={`/admin/static/privacy`} 
                        type="button"
                        className="btn text-yellow">
                          <i className="fa fa-edit"></i>
                      </Link>
                    </td> 
                    </tr>
                    
                    <tr >
                      <td>
                     Terms
                        </td>
                      <td>
                      {staticDetail.terms.updatedOn&&moment(staticDetail.terms.updatedOn).format("MMM Do YY")}
                      </td>
                      {/* <td>
                        {staticDetail.status}
                      </td> */}
                      <td className="d-flex justify-content-center"> 
                        <Link 
                        to={`/admin/static/terms`} 
                        type="button"
                        className="btn text-yellow">
                          <i className="fa fa-edit"></i>
                      </Link>
                    </td> 
                    </tr>
                   
                  </tbody>
                
                </Table>
                
              </Card>
            </div>
          </Row>
          
        </Container>

            
        </>
    )
}

export default Static
