
export const GET_ME = "GET_ME";
export const UPDATE_PROFILE= "UPDATE_PROFILE";
export const UPDATE_PICTURE = 'UPDATE_PICTURE';

export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR";
export const DELETE_USER="DELETE_USER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const IN_ACTIVATE_USER = "IN_ACTIVATE_USER";
export const FILTER_USER = "FILTER_USER";