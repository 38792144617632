import React,{useEffect} from 'react';
import ReactDOM from 'react-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Reports from './Reports';
import Analytics from './Analytics';
import { Container,Row, Col} from 'reactstrap';

//<AdminNavbar  brandText="Analytics and reports" />
export const Analyticsreports = () => {

  useEffect(()=>{

  },[])

  return (
<>
<AdminNavbar brandText="Analytics and Reports" />
<Container fluid>
  <Row >
    <Col className='mt-7'
    style={{height:"400px"}}
    >
        <Analytics />
    </Col>
  </Row>
  <Row>
    <Col>
    <Reports />
    </Col>
  </Row>

</Container>


</>
    
  )
}
export default Analyticsreports