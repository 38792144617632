import React,{useState} from "react";

import {
    Media,
    Table,
    Button,Modal,ModalBody,ModalHeader,ModalFooter
  } from "reactstrap";

  import { useParams } from "react-router-dom";
import { deleteDiscussionReply } from "redux/api";
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DiscussionReply = ({reply,post,setpost}) => {
  const params = useParams();


  const [confirm, setConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState();

  const deletePopup = (id)=>{
    setConfirm(true);
    setConfirmId(id);
  }

  const cancelDelete=()=>{
    setConfirm(false);
    setConfirmId();
  }
  
  const handleRemove=async()=>{
    try {
      const {data} = await deleteDiscussionReply(params.communityId,params.postId,confirmId);
      const newRep = reply.filter(elem=>elem._id!==confirmId);

         setpost({...post,reply:newRep});
       
              toast.success(data.message,{
                autoClose: 1500,
                hideProgressBar: true,
              })
              cancelDelete();
            } catch (error) {              
              toast.error(error.response.data.message,{
                autoClose: 1500,
                hideProgressBar: true,
              })
              cancelDelete();
            }
            
  }

  return (
    <>
    <ToastContainer />
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">User Image</th>
            <th scope="col">User Name</th>
            <th scope="col">Reply</th>
            <th scope="col" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {reply&&reply.map((elem) => (
            <tr key={elem._id}>
              <th scope="row">
                <Media
                  className="align-items-center"
                  style={{ height: "80px", width: "80px" }}
                >
                  <img
                    alt="..."
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    className="rounded-circle fluid"
                   src={`${process.env.REACT_APP_BASE_URL}${elem.user.picture}`}
                  />
                </Media>
              </th>
              <td>{elem.user.name}</td>
              <td>
                      {elem.comment}
                </td>

              <td className="d-flex justify-content-center">                
                <div
                  type="button"
                  className="btn text-red"
                  onClick={()=>deletePopup(elem._id)}
                >
                  <i className="fa fa-trash"></i>
                </div>
              </td>
            </tr>
          ))} 
        </tbody>
          <Modal isOpen={confirm} >
                    <ModalHeader >Confirm remove member ?</ModalHeader>
                    <ModalBody>
                      Are you sure you want to remove this discussion from community ?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={()=>handleRemove()} >Confirm</Button>{' '}
                      <Button color="secondary" onClick={cancelDelete} >Cancel</Button>
                    </ModalFooter>
            </Modal>
      </Table>
    </>
  );
};

export default DiscussionReply;
